import React, { useEffect, useState, useRef } from "react";
import "./ResultsNeighborhood.css";
import NavBar from "../../components/nav-bar/NavBar";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import MapComponent from "../../components/map/Map";
import FrequentQuestions from "../../components/frequent-questions/FrequentQuestions";
import axios from "axios";
import { Link } from "react-router-dom";
import ContactUs from "../../components/contact-us/ContactUs";
import AboutEstimates from "../../components/about-estimates/AboutEstimates";
import { RootState } from "../../store";
import PlaceComponent from "../../components/location-input/LocationInput";
import BarChart from "../../components/charts/BarChart";
import Footer from "../../components/footer/Footer";

function ResultsNeighborhood() {
  const price = useSelector((state) => state.price);
  const [data, setData] = React.useState({});
  const [neighborhood, setNeighborhood] = useState("");
  const [dataRent, setDataRent] = React.useState({});
  const [priceDataPoints, setPriceDataPoints] = React.useState([]);
  const [priceSqmDataPoints, setPriceSqmDataPoints] = React.useState([]);
  const [sqmDataPoints, setSqmDataPoints] = React.useState([]);
  const [googleCity, setGoogleCity] = useState("");
  const [googleNeighborhood, setGoogleNeighborhood] = useState("");
  const hasFetchedData = useRef(false);

  const contactRef = useRef(null);

  const scrollToElement = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  useEffect(() => {
    console.log(price.marker);
    price.marker.address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        setGoogleCity(component.long_name);
      } else if (component.types.includes("administrative_area_level_3")) {
        setGoogleNeighborhood(component.long_name);
      }
    });
  }, [price]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, [data]);
  React.useEffect(() => {
    if (data.price) {
      let tmp_price = [["Price", "Count", { role: "style" }]];
      let slice = parseInt((data.price.max - data.price.min) / 10 / 2);
      data.stat_price.forEach((stat, i) => {
        let tmp = [(slice * (i + 1)).toString() + " €", stat, "#bb7ab2"];

        tmp_price.push(tmp);
      });
      let tmp_price_sqm = [["Price Per Sqm", "Count", { role: "style" }]];
      let slice_price_sqm = parseInt(
        (data.price_per_sqm.max - data.price_per_sqm.min) / 10 / 2
      );
      data.stat_price_sqm.forEach((stat, i) => {
        let tmp = [(slice_price_sqm * (i + 1)).toString(), stat, "#77bd86"];
        tmp_price_sqm.push(tmp);
      });
      let tmp_sqm = [["Sqm", "Count", { role: "style" }]];
      let slice_sqm = parseInt((data.sqm.max - data.sqm.min) / 10 / 2);
      data.stat_sqm.forEach((stat, i) => {
        let tmp = [(slice_sqm * (i + 1)).toString(), stat, "#e8bb05"];
        tmp_sqm.push(tmp);
      });
      setPriceDataPoints(tmp_price);
      setPriceSqmDataPoints(tmp_price_sqm);
      setSqmDataPoints(tmp_sqm);
    }
  }, [data]);
  useEffect(() => {
    if (!hasFetchedData.current) {
      getNeighborhoodDataBuy();
      getNeighborhoodDataRent();
      hasFetchedData.current = true;
    }
  }, []);

  const getNeighborhoodDataBuy = () => {
   
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/get_neighborhood_results_buy`,
        price.request
      )
      .then((res) => {
        if (res.data.foundNeighborhood) {
          setNeighborhood(res.data.neighborhood.split(",")[0]);
        }
        setData(res.data);
      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });
  };
  const getNeighborhoodDataRent = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/get_neighborhood_results_rent`,
        price.request
      )
      .then((res) => {
        // alert.success("The property was updated");
        setDataRent(res.data);
      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });
  };

  return (
    <div className="na-main">
      <NavBar />
      {data.price && dataRent.price ? (
        <div className="rn-column-start-start">
          <span className="font-gothic-b28 font-blue margin-b40">
            {" "}
            {googleCity}
            {googleNeighborhood !== "" && ","}{" "}
            {neighborhood !== "" ? neighborhood : googleNeighborhood}
          </span>
          {/* <div className="line-grey">
            <span className="font-gothic-b18 font-blue">
              Πληροφορίες περιοχής
            </span>
            <KeyboardArrowDownRoundedIcon className="blue" />
          </div> */}

          <div style={{ width: "100%" }}>
            {console.log(price.marker)}
            <MapComponent
              style={{ border: "1px solid black", width: "100%" }}
              marker={price.marker}
              height={"233px"}
            />
          </div>

          <div className="rp-green-img">
            <div className="green-column">
              <span className="font-gothic-b28 white">
                Πολλοί Διαθέσιμοι αγοραστές
              </span>
              <span className="font-inter16 white">
                ζητούν ακίνητα στην περιοχή
              </span>
            </div>
            <button
              onClick={scrollToElement}
              className="special-button-outline-white  font-gothic-b15"
            >
              Θέλω να το πουλήσω!
            </button>
          </div>

          <span className="font-gothic-b28 font-blue margin-t5p">
            Έρευνα αγοράς για περιοχή {googleCity}{" "}
            {googleNeighborhood !== "" && ","}{" "}
            {neighborhood !== "" ? neighborhood : googleNeighborhood}
          </span>

          <span className="font-inter18 font-blue margin-b20">
            Τα παρακάτω αποτελέσματα αφορούν ακίνητα με τα ίδια χαρακτηριστικά
            με ακίνητο της αναζήτησης σας.
          </span>

          <div className="rn-properties margin-b20">
            <div className="rn-card-top">
              <div className="mob-rn-row-start-center">
                <img
                  className="rn-card-icon1"
                  src={require("../../assets/house-simple.png")}
                  alt="developing vector"
                />
                <span className="font-gothic-b22 font-blue">
                  Αριθμός ομοειδών ακινήτων στην περιοχή
                </span>
              </div>
              <span className="font-inter-m40 font-blue">
                {data.count + dataRent.count} ακίνητα
              </span>
            </div>
          </div>

          <div className="rn-properties margin-b20">
            {window.innerWidth >= 550 ? (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Τιμές Πώλησης
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={data.price.min}
                    stat={data.stat_price}
                    slice={data.price.slice}
                    max={data.price.max}
                    color={"#bb7ab2"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή πώλησης σε αυτήν την
                    περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseFloat(
                        parseFloat(data.price.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </span>
                </div>

                <div className="rn-card-b width100p">
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(data.price.min).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </div>

                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span className="font-gothic-b34 font-blue">
                      {parseFloat(
                        parseFloat(data.price.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </div>

                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(data.price.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </div>
                </div>
                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {data.count} ακίνητα
                  </span>
                </div>
              </div>
            ) : (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Τιμές ομοειδών ακινήτων
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={data.price.min}
                    stat={data.stat_price}
                    slice={data.price.slice}
                    max={data.price.max}
                    color={"#bb7ab2"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή ανά τετραγωνικό μέτρο για
                    παρόμοια ακίνητα σε αυτήν την περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseInt(data.price.price)} € / τ.μ.
                    </span>
                  </span>
                </div>

                <div className="mob-rn-card-b width100p">
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span className="font-inter-b18 font-blue">
                      {parseInt(data.price.min)} €
                    </span>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span className="font-gothic-b27 font-blue">
                      {parseInt(data.price.price)} €
                    </span>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span className="font-inter-b18 font-blue">
                      {parseInt(data.price.max)} €
                    </span>
                  </div>
                </div>
                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {data.count} ακίνητα
                  </span>
                </div>
              </div>
            )}

            {window.innerWidth >= 550 ? (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Τιμές Ενοικίασης
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={dataRent.price.min}
                    stat={dataRent.stat_price}
                    slice={dataRent.price.slice}
                    max={dataRent.price.max}
                    color={"#bb7ab2"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή ενοικίασης σε αυτήν την
                    περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </span>
                </div>
                <div className="rn-card-b width100p">
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price.min).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </div>

                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span className="font-gothic-b34 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </div>

                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </div>
                </div>
                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {dataRent.count} ακίνητα
                  </span>
                </div>{" "}
              </div>
            ) : (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Τιμές Ενοικίασης
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={dataRent.price.min}
                    stat={dataRent.stat_price}
                    slice={dataRent.price.slice}
                    max={dataRent.price.max}
                    color={"#bb7ab2"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή ενοικίασης σε αυτήν την
                    περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </span>
                </div>
                <div className="mob-rn-card-b width100p">
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price.min).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span className="font-gothic-b27 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      €
                    </span>
                  </div>
                </div>
                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {dataRent.count} ακίνητα
                  </span>
                </div>{" "}
              </div>
            )}
          </div>
          <div className="rn-properties margin-b20">
            {window.innerWidth >= 550 ? (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Τιμές ανά τ.μ. πώλησης
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={data.price_per_sqm.min}
                    stat={data.stat_price_sqm}
                    slice={data.price_per_sqm.slice}
                    max={data.price_per_sqm.max}
                    color={"#77bd86"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή πώλησης ανά τετραγωνικό
                    μέτρο σε αυτήν την περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseFloat(
                        parseFloat(data.price_per_sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </span>
                </div>
                <div className="rn-card-b width100p">
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(data.price_per_sqm.min).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>

                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span className="font-gothic-b34 font-blue">
                      {parseFloat(
                        parseFloat(data.price_per_sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>

                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(data.price_per_sqm.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>
                </div>
                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {data.count} ακίνητα
                  </span>
                </div>
              </div>
            ) : (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Τιμές ανά τ.μ. πώλησης
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={data.price_per_sqm.min}
                    stat={data.stat_price_sqm}
                    slice={data.price_per_sqm.slice}
                    max={data.price_per_sqm.max}
                    color={"#77bd86"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή πώλησης ανά τετραγωνικό
                    μέτρο σε αυτήν την περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseFloat(
                        parseFloat(data.price_per_sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </span>
                </div>
                <div className="mob-rn-card-b width100p">
                  <div className="rn-column-sp-around-sqmeter">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span
                      className="font-inter-b18 font-blue"
                      style={{
                        whiteSpace: "break-spaces",
                        textAlign: "center",
                      }}
                    >
                      {parseFloat(
                        parseFloat(data.price_per_sqm.min).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>

                  <div className="rn-column-sp-around-sqmeter">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span
                      className="font-gothic-b27 font-blue"
                      style={{
                        whiteSpace: "break-spaces",
                        textAlign: "center",
                      }}
                    >
                      {parseFloat(
                        parseFloat(data.price_per_sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>

                  <div className="rn-column-sp-around-sqmeter">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span
                      className="font-inter-b18 font-blue"
                      style={{
                        whiteSpace: "break-spaces",
                        textAlign: "center",
                      }}
                    >
                      {parseFloat(
                        parseFloat(data.price_per_sqm.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>
                </div>
                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {data.count} ακίνητα
                  </span>
                </div>
              </div>
            )}

            {window.innerWidth >= 550 ? (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Τιμές ανά τ.μ.ενοικίασης
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={dataRent.price_per_sqm.min}
                    stat={dataRent.stat_price_sqm}
                    slice={dataRent.price_per_sqm.slice}
                    max={dataRent.price_per_sqm.max}
                    color={"#77bd86"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή ενοικίασης ανά τετραγωνικό
                    μέτρο σε αυτήν την περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price_per_sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </span>
                </div>
                <div className="rn-card-b width100p">
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price_per_sqm.min).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>
                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span className="font-gothic-b34 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price_per_sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>
                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price_per_sqm.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>
                </div>
                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {dataRent.count} ακίνητα
                  </span>
                </div>{" "}
              </div>
            ) : (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Τιμές ανά τ.μ.ενοικίασης
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={dataRent.price_per_sqm.min}
                    stat={dataRent.stat_price_sqm}
                    slice={dataRent.price_per_sqm.slice}
                    max={dataRent.price_per_sqm.max}
                    color={"#77bd86"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή ενοικίασης ανά τετραγωνικό
                    μέτρο σε αυτήν την περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.price_per_sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </span>
                </div>
                <div className="mob-rn-card-b width100p">
                  <div className="rn-column-sp-around-sqmeter">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span
                      className="font-inter-b18 font-blue"
                      style={{
                        whiteSpace: "break-spaces",
                        textAlign: "center",
                      }}
                    >
                      {parseFloat(
                        parseFloat(dataRent.price_per_sqm.min).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>

                  <div className="rn-column-sp-around-sqmeter">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span
                      className="font-gothic-b27 font-blue"
                      style={{
                        whiteSpace: "break-spaces",
                        textAlign: "center",
                      }}
                    >
                      {parseFloat(
                        parseFloat(dataRent.price_per_sqm.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>

                  <div className="rn-column-sp-around-sqmeter">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span
                      className="font-inter-b18 font-blue"
                      style={{
                        whiteSpace: "break-spaces",
                        textAlign: "center",
                      }}
                    >
                      {parseFloat(
                        parseFloat(dataRent.price_per_sqm.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      € / τ.μ.
                    </span>
                  </div>
                </div>
                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {dataRent.count} ακίνητα
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="rn-properties margin-b20">
            {window.innerWidth >= 550 ? (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Επιφάνεια τ.μ. ακινήτων
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={data.sqm.min}
                    stat={data.stat_sqm}
                    slice={data.sqm.slice}
                    max={data.sqm.max}
                    color={"#e8bb05"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή τετραγωνικών μέτρων για
                    παρόμοια ακίνητα σε αυτήν την περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseFloat(
                        parseFloat(data.sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </span>
                </div>

                <div className="rn-card-b width100p">
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(data.sqm.min).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>

                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span className="font-gothic-b34 font-blue">
                      {parseFloat(
                        parseFloat(data.sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>
                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(data.sqm.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>
                </div>
                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {dataRent.count} ακίνητα
                  </span>
                </div>
              </div>
            ) : (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Επιφάνεια τ.μ. ακινήτων
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={data.sqm.min}
                    stat={data.stat_sqm}
                    slice={data.sqm.slice}
                    max={data.sqm.max}
                    color={"#e8bb05"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή τετραγωνικών μέτρων για
                    παρόμοια ακίνητα σε αυτήν την περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseFloat(
                        parseFloat(data.sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </span>
                </div>

                <div className="mob-rn-card-b width100p">
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(data.sqm.min).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span className="font-gothic-b27 font-blue">
                      {parseFloat(
                        parseFloat(data.sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(data.sqm.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>
                </div>
                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {dataRent.count} ακίνητα
                  </span>
                </div>
              </div>
            )}

            {window.innerWidth >= 550 ? (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Επιφάνεια τ.μ. ακινήτων
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={dataRent.sqm.min}
                    stat={dataRent.stat_sqm}
                    slice={dataRent.sqm.slice}
                    max={dataRent.sqm.max}
                    color={"#e8bb05"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή τετραγωνικών μέτρων για
                    ακίνητα προς ενοικίαση σε αυτήν την περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </span>
                </div>

                <div className="rn-card-b width100p">
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.sqm.min).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>
                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span className="font-gothic-b34 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>

                  <div className="rn-card-column-end" style={{ width: "70%" }}>
                    <div className="rn-stats-bottom-line1"></div>
                  </div>
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.sqm.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>
                </div>

                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {dataRent.count} ακίνητα
                  </span>
                </div>
              </div>
            ) : (
              <div className="stats-card">
                <div className="rn-stats-card-top">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Επιφάνεια τ.μ. ακινήτων
                  </span>
                  <HelpRoundedIcon
                    style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                  />
                </div>
                <div className="rn-stats-card-middle">
                  <BarChart
                    min={dataRent.sqm.min}
                    stat={dataRent.stat_sqm}
                    slice={dataRent.sqm.slice}
                    max={dataRent.sqm.max}
                    color={"#e8bb05"}
                  />
                  <span className="font-inter16 font-blue">
                    Με βάση τα δεδομένα, η μέση τιμή τετραγωνικών μέτρων για
                    ακίνητα προς ενοικίαση σε αυτήν την περιοχή είναι{" "}
                    <span className="font-inter-b16 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </span>
                </div>

                <div className="mob-rn-card-b width100p">
                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">
                      Ελάχιστη τιμή
                    </span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.sqm.min).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter-b12 red">Μέση τιμή</span>
                    <span className="font-gothic-b27 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.sqm.price).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>

                  <div className="rn-column-sp-around">
                    <span className="font-inter12 font-blue">Μέγιστη</span>
                    <span className="font-inter-b18 font-blue">
                      {parseFloat(
                        parseFloat(dataRent.sqm.max).toFixed(0)
                      ).toLocaleString()}{" "}
                      τ.μ.
                    </span>
                  </div>
                </div>

                <div className="card-end-span">
                  <span className="font-inter-b14 deep-grey">
                    Αποτέλεσμα με δείγμα {dataRent.count} ακίνητα
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="rn-img-blue margin-b40">
            <span className="font-gothic-b28 white">
              Δες γενικά στατιστικά για περιοχή {googleCity}
              {googleNeighborhood !== "" && ","}{" "}
              {neighborhood !== "" ? neighborhood : googleNeighborhood}
            </span>
            <Link to="/neighborhoodanalysis">
              {" "}
              <button className="pointer button-outline-white  font-gothic-b15">
                Δες τα δεδομένα
              </button>
            </Link>
          </div>

          {/* <div className="nearby-back">
            <div className="row-start-center margin-b40">
              <img
                style={{ marginRight: 15, width: 37 }}
                src={require("../../assets/stats-icon-buy.png")}
                alt="developing vector"
              />
              <span className="font-gothic-b24 red">
                Ακίνητα που πωλούνται κοντά
              </span>
              <img
                style={{ marginRight: 5, width: 18, marginLeft: 30 }}
                src={require("../../assets/map-icon.png")}
                alt="developing vector"
              />
              <span className="font-gothic-b16 font-blue underline-thin">
                Δες στον χάρτη
              </span>
            </div>

            <div className="na-exclusive-row">
              <div className="na-exclusive-card">
                <div className="na-exclusive-img">
                  <div className="na-icon-row">
                    <div className="vip-icon">
                      <span className="font-inter-m14 white">VIP</span>
                    </div>
                    <div className="rental-icon">
                      <span className="font-inter-m14 white">Ενοɩκίαση</span>
                    </div>
                  </div>
                  <div className="na-moy-row">
                    <button className="moy-button font-inter-m14 font-blue">
                      ΜΟΥ5674
                    </button>

                    <button className="more-pictures-button">
                      <img
                        style={{ marginRight: 5, width: 28 }}
                        src={require("../../assets/more-pictures.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b14 font-blue">18</span>
                    </button>
                  </div>
                </div>

                <div className="na-exclusive-bottom">
                  <span className="font-inter-semi18 dark-grey">
                    Μονοκατοɩκία, 850 τ.μ.
                  </span>
                  <span className="font-inter-m14 dark-grey">
                    Μεγάλη Μαντίνεɩα, Αβία, ΜεσσηνίαΜεγάλη Μαντίνεɩα, Αβία,
                    ΜεσσηνίαΜεγάλη Μαντίνεɩα, Αβία, Μεσσηνία Με 2 αποθήκες | Με
                    θέσεɩς πάρκɩνγκ
                  </span>
                  <div className="row-start-center">
                    <img
                      style={{ width: 21, marginRight: 10 }}
                      src={require("../../assets/business.png")}
                      alt="developing vector"
                    />
                    <span className="font-inter-semi14 dark-grey">
                      2ος Όρoφος
                    </span>
                    <img
                      style={{ width: 22, marginRight: 10 }}
                      src={require("../../assets/couch.png")}
                      alt="developing vector"
                    />
                    <span className="font-inter-semi14 dark-grey">6</span>

                    <img
                      style={{ width: 24, marginRight: 10 }}
                      src={require("../../assets/bathtub.png")}
                      alt="developing vector"
                    />
                    <span className="font-inter-semi14 dark-grey">8</span>
                  </div>

                  <div className="exclusive-card-border" />
                  <div className="row-space-between-center width100p">
                    <div className="row-start-center">
                      <span className="font-inter-semi24 font-blue">
                        1.590.000 €
                      </span>
                      <span
                        className="font-inter-semi24 font-blue"
                        style={{ opacity: 0.6, marginLeft: 10 }}
                      >
                        5.800 €/τμ
                      </span>
                    </div>
                    <img
                      style={{ width: 22 }}
                      src={require("../../assets/heart-filled.png")}
                      alt="developing vector"
                    />
                  </div>
                </div>
              </div>

              <div className="na-exclusive-card">
                <div className="na-exclusive-img-land">
                  <div className="na-icon-row">
                    <div className="vip-icon">
                      <span className="font-inter-m14 white">VIP</span>
                    </div>
                    <div className="rental-icon">
                      <span className=" font-inter-m14 white">Πώληση</span>
                    </div>
                  </div>
                  <div className="na-moy-row">
                    <button className="moy-button font-inter-m14 font-blue">
                      ΜΟΥ5674
                    </button>

                    <button className="more-pictures-button">
                      <img
                        style={{ marginRight: 5, width: 28 }}
                        src={require("../../assets/more-pictures.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b14 font-blue">18</span>
                    </button>
                  </div>
                </div>

                <div className="na-exclusive-bottom">
                  <span className="font-inter-semi18 dark-grey">
                    Οɩκόπεδο, 2500 τ.μ.
                  </span>
                  <span className="font-inter-m14 dark-grey">
                    Μεγάλη Μαντίνεɩα, Αβία, Μεσσηνία
                  </span>
                  <div className="row-start-center">
                    <img
                      style={{ width: 23, marginRight: 10 }}
                      src={require("../../assets/land-icon1.png")}
                      alt="developing vector"
                    />
                    <span>Εντός Σχεδίου</span>
                    <img
                      style={{ width: 22, marginRight: 10 }}
                      src={require("../../assets/land-icon2.png")}
                      alt="developing vector"
                    />
                    <span className="font-inter-semi14 dark-grey">
                      Σ.Δ. 0,80
                    </span>
                  </div>

                  <div className="exclusive-card-border" />
                  <div className="row-space-between-center width100p">
                    <div className="row-start-center">
                      <span className="font-inter-semi24 font-blue">
                        1.590.000 €
                      </span>
                      <span
                        className="font-inter-semi24 font-blue"
                        style={{ opacity: 0.6, marginLeft: 10 }}
                      >
                        5.800 €/τμ
                      </span>
                    </div>
                    <img
                      style={{ width: 22 }}
                      src={require("../../assets/heart-filled.png")}
                      alt="developing vector"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span
              className="font-inter16 deep-grey underline-2 margin-t20"
              style={{ alignSelf: "flex-end" }}
            >
              Δες όλα τα ακίνητα που πωλούνται κοντά στην περιοχή
            </span>
          </div>

          <div className="nearby-back">
            <div className="row-start-center margin-b40">
              <img
                style={{ marginRight: 15, width: 37 }}
                src={require("../../assets/stats-icon-buy.png")}
                alt="developing vector"
              />
              <span className="font-gothic-b24 red">
                Ακίνητα που πωλούνται κοντά
              </span>
              <img
                style={{ marginRight: 5, width: 18, marginLeft: 30 }}
                src={require("../../assets/map-icon.png")}
                alt="developing vector"
              />
              <span className="font-gothic-b16 font-blue underline-thin">
                Δες στον χάρτη
              </span>
            </div>

            <div className="na-exclusive-row">
              <div className="na-exclusive-card">
                <div className="na-exclusive-img">
                  <div className="na-icon-row">
                    <div className="vip-icon">
                      <span className="font-inter-m14 white">VIP</span>
                    </div>
                    <div className="rental-icon">
                      <span className="font-inter-m14 white">Ενοɩκίαση</span>
                    </div>
                  </div>
                  <div className="na-moy-row">
                    <button className="moy-button font-inter-m14 font-blue">
                      ΜΟΥ5674
                    </button>

                    <button className="more-pictures-button">
                      <img
                        style={{ marginRight: 5, width: 28 }}
                        src={require("../../assets/more-pictures.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b14 font-blue">18</span>
                    </button>
                  </div>
                </div>

                <div className="na-exclusive-bottom">
                  <span className="font-inter-semi18 dark-grey">
                    Μονοκατοɩκία, 850 τ.μ.
                  </span>
                  <span className="font-inter-m14 dark-grey">
                    Μεγάλη Μαντίνεɩα, Αβία, ΜεσσηνίαΜεγάλη Μαντίνεɩα, Αβία,
                    ΜεσσηνίαΜεγάλη Μαντίνεɩα, Αβία, Μεσσηνία Με 2 αποθήκες | Με
                    θέσεɩς πάρκɩνγκ
                  </span>
                  <div className="row-start-center">
                    <img
                      style={{ width: 21, marginRight: 10 }}
                      src={require("../../assets/business.png")}
                      alt="developing vector"
                    />
                    <span className="font-inter-semi14 dark-grey">
                      2ος Όρoφος
                    </span>
                    <img
                      style={{ width: 22, marginRight: 10 }}
                      src={require("../../assets/couch.png")}
                      alt="developing vector"
                    />
                    <span className="font-inter-semi14 dark-grey">6</span>

                    <img
                      style={{ width: 24, marginRight: 10 }}
                      src={require("../../assets/bathtub.png")}
                      alt="developing vector"
                    />
                    <span className="font-inter-semi14 dark-grey">8</span>
                  </div>

                  <div className="exclusive-card-border" />
                  <div className="row-space-between-center width100p">
                    <div className="row-start-center">
                      <span className="font-inter-semi24 font-blue">
                        1.590.000 €
                      </span>
                      <span
                        className="font-inter-semi24 font-blue"
                        style={{ opacity: 0.6, marginLeft: 10 }}
                      >
                        5.800 €/τμ
                      </span>
                    </div>
                    <img
                      style={{ width: 22 }}
                      src={require("../../assets/heart-filled.png")}
                      alt="developing vector"
                    />
                  </div>
                </div>
              </div>

              <div className="na-exclusive-card">
                <div className="na-exclusive-img-land">
                  <div className="na-icon-row">
                    <div className="vip-icon">
                      <span className="font-inter-m14 white">VIP</span>
                    </div>
                    <div className="rental-icon">
                      <span className=" font-inter-m14 white">Πώληση</span>
                    </div>
                  </div>
                  <div className="na-moy-row">
                    <button className="moy-button font-inter-m14 font-blue">
                      ΜΟΥ5674
                    </button>

                    <button className="more-pictures-button">
                      <img
                        style={{ marginRight: 5, width: 28 }}
                        src={require("../../assets/more-pictures.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b14 font-blue">18</span>
                    </button>
                  </div>
                </div>

                <div className="na-exclusive-bottom">
                  <span className="font-inter-semi18 dark-grey">
                    Οɩκόπεδο, 2500 τ.μ.
                  </span>
                  <span className="font-inter-m14 dark-grey">
                    Μεγάλη Μαντίνεɩα, Αβία, Μεσσηνία
                  </span>
                  <div className="row-start-center">
                    <img
                      style={{ width: 23, marginRight: 10 }}
                      src={require("../../assets/land-icon1.png")}
                      alt="developing vector"
                    />
                    <span>Εντός Σχεδίου</span>
                    <img
                      style={{ width: 22, marginRight: 10 }}
                      src={require("../../assets/land-icon2.png")}
                      alt="developing vector"
                    />
                    <span className="font-inter-semi14 dark-grey">
                      Σ.Δ. 0,80
                    </span>
                  </div>

                  <div className="exclusive-card-border" />
                  <div className="row-space-between-center width100p">
                    <div className="row-start-center">
                      <span className="font-inter-semi24 font-blue">
                        1.590.000 €
                      </span>
                      <span
                        className="font-inter-semi24 font-blue"
                        style={{ opacity: 0.6, marginLeft: 10 }}
                      >
                        5.800 €/τμ
                      </span>
                    </div>
                    <img
                      style={{ width: 22 }}
                      src={require("../../assets/heart-filled.png")}
                      alt="developing vector"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span
              className="font-inter16 deep-grey underline-2 margin-t20"
              style={{ alignSelf: "flex-end" }}
            >
              Δες όλα τα ακίνητα που πωλούνται κοντά στην περιοχή
            </span>
          </div> */}

          <div className="na-row-icon-pad">
            <Link
              to="https://newdeal.gr/el/buy/residential"
              style={{ textDecoration: "none" }}
            >
              <div className="row-space-between-center pointer">
                <img
                  style={{ width: 62, marginRight: 10, marginLeft: 20 }}
                  src={require("../../assets/house.png")}
                  alt="developing vector"
                />
                <span className="font-gothic-b18 font-blue">
                  Κατοικίες για πώληση
                </span>
              </div>
            </Link>
            <Link
              to="https://newdeal.gr/el/buy/commercial"
              style={{ textDecoration: "none" }}
            >
              <div className="row-space-between-center pointer">
                <img
                  style={{ width: 62, marginRight: 10, marginLeft: 20 }}
                  src={require("../../assets/skyscraper.png")}
                  alt="developing vector"
                />
                <span className="font-gothic-b18 font-blue">
                  Επαγγελματικές στέγες για πώληση
                </span>
              </div>
            </Link>
            <Link
              to="https://newdeal.gr/el/buy/land"
              style={{ textDecoration: "none" }}
            >
              <div className="row-space-between-center pointer">
                <img
                  style={{ width: 62, marginRight: 10, marginLeft: 20 }}
                  src={require("../../assets/blueprint.png")}
                  alt="developing vector"
                />
                <span className="font-gothic-b18 font-blue">Γη για πώληση</span>
              </div>
            </Link>
          </div>

          {/* <div className="na-frequent-questions">
            <FrequentQuestions />
          </div> */}
        </div>
      ) : (
        <div className="column-start-center width100p" style={{backgroundColor:"#f7f8fd"}}>
          <img
            style={{ height: 300, width: 300, padding:window.innerWidth>1000? 150:0 }}
            src={require("../../assets/N white.gif")}
            alt="loading gif"
          />
        </div>
      )}
      <div className="na-frequent-questions">
        <FrequentQuestions />
      </div>
      <div ref={contactRef}>
        <ContactUs />
      </div>
      <AboutEstimates />
    </div>
  );
}

export default ResultsNeighborhood;
