import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = { location: null };

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation(state, action) {
      state.location = action.payload.path;
    },
  },
});

export default locationSlice;
