import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const PriceChart = (props) => {
  const [chartData, setChartData] = useState([]);
  const [transactionType, setTransactionType] = useState("");
  const [percentageChange, setPercentageChange] = useState(null);
  useEffect(() => {
   
    const groupedData = groupDataByMonthYear(props.data);
    // Calculate average number of entries per month
  const avgEntriesPerMonth = groupedData.reduce(
    (acc, item) => acc + item.data.length, 0) / groupedData.length;

  // Define a threshold for determining similar data amount, for example within 20% of average
  const threshold = avgEntriesPerMonth * 0.5;

  // Filter months with data count within the threshold of the average
  const similarDataMonths = groupedData.filter(monthData =>
    Math.abs(monthData.data.length - avgEntriesPerMonth) <= threshold
  );

  // Prepare chartData
  const newChartData = [["Date", "Μέση Τιμή"]];
  similarDataMonths.forEach((item) => {
    const formattedDate = new Date(item.date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
    });
    const averagePrice = calculateAveragePrice(item.data);
    newChartData.push([formattedDate, averagePrice]);
  });

  // Calculate percentage change if applicable
  if (similarDataMonths.length > 1) {
    const firstMonthData = similarDataMonths[0].data;
    const lastMonthData = similarDataMonths[similarDataMonths.length - 1].data;
    const percentageChangeValue = calculatePercentageChange(
      calculateAveragePrice(firstMonthData),
      calculateAveragePrice(lastMonthData)
    );
    setPercentageChange(percentageChangeValue);
  }

  setChartData(newChartData);
  setTransactionType(props.transactionType);
    
    
  }, [props]);

  const filterOutliers = (values) => {
    values.sort((a, b) => a - b);
    
    const q1 = values[Math.floor(values.length / 4)];
    const q3 = values[Math.ceil(3 * values.length / 4)];
    const iqr = q3 - q1;
    
    const lowerBound = q1 - 1.5 * iqr;
    const upperBound = q3 + 1.5 * iqr;
  
    return values.filter(value => value >= lowerBound && value <= upperBound);
  };
  
  const groupDataByMonthYear = (data) => {
    const groupedData = {};
  
    data.forEach((item) => {
      const yearMonth = new Date(item.date).toISOString().substr(0, 7); // Format: "YYYY-MM"
      if (!groupedData[yearMonth]) {
        groupedData[yearMonth] = [];
      }
      groupedData[yearMonth].push(item.price);
    });
  
    return Object.keys(groupedData)
      .sort()
      .map((date) => ({
        date,
        data: filterOutliers(groupedData[date]),
      }))
      .filter(monthData => monthData.data.length > 0); // Skip months with no data after filtering
  };
  

  
  const calculateAveragePrice = (prices) => {
    const sum = prices.reduce((total, price) => total + price, 0);
    return sum / prices.length;
  };
  const calculatePercentageChange = (initialPrice, finalPrice) => {
    return ((finalPrice - initialPrice) / initialPrice) * 100;
  };
  return (
    <div  style={{
      width: "100%",
      height: "100%",
     
    }}>
      {(props.data.length>5&&chartData.length > 2)?
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Chart
        chartType="AreaChart"
        chartWrapperParams={{ width: "100%" }}
        width="100%"
        height="400px"
        data={chartData}
        options={{
          hAxis: { format: "MMM ''yy" },
          vAxis: { viewWindow: { min: 0 } },
          legend: { position: "none" },
          series: {
            0: { color: transactionType === "RENT" ? "#e8bb05" : "#4285F4" },
          },
        }}
      />
      {percentageChange !== null && (
        <span className="font-inter16 font-blue">
          {" Η αξία ακινήτων όπως αυτό έχει" +
            (percentageChange > 0 ? " αυξηθεί" : " μειωθεί") +
            " κατά περίπου "}
          <span className="font-inter-b16 font-blue">
            {" "}
            {Math.abs(percentageChange).toFixed(2)}%
          </span>{" "}
          στο διάστημα αυτό.
        </span>
      )}
    </div>
    :  <span className="font-inter16 font-blue">
    Συγγνώμη, αυτή τη στιγμή δεν είμαστε σε θέση να προσφέρουμε το διάγραμμα για τη συγκεκριμένη αναζήτηση.
      </span>
}
    </div>
  );
};

export default PriceChart;
