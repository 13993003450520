import { useEffect, useState } from "react";
import "./ReportPrice.css";
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";

import FormControl from "@mui/material/FormControl";

import { TextField } from "@mui/material";

import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ReportPrice = (props) => {
  const price = useSelector((state) => state.price);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const closeHandler = () => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    setShow(props.show);
    console.log(props.show);
  }, [props]);

  useEffect(() => {
    setFrom();
    setTo();
  }, []);
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const send = () => {
    let body = {
      from_price: from,
      to_price: to,
      from_filler: localStorage.getItem("formId")
        ? localStorage.getItem("formId")
        : null,
      request: price.request ? price.request : null,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/add_price_report`, body)
      .then((res) => {
        toast.success("Η αναφορά αποθηκεύτηκε. Ευχαριστούμε!");
      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });

    props.onClose();
  };

  return (
    <Modal
      open={show}
      sx={{ zIndex: 1500 }}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-property"}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          closeOnClick
          pauseOnHover
        />
        <div className={"property-scroll"}>
        <IconButton className="close-button" onClick={closeHandler}>
  <Close />
</IconButton>
          <div className="column-start-center width100p ">
            {/* <div className="row-end-center width100p">
              <span className={"close"} onClick={closeHandler}>
                &times;
              </span>
            </div> */}
            <div className="row-center-center width100p address ">
              <span className="font-gothic-b20  font-blue">
                Αναφορά Τιμής Εκτίμησης
              </span>
            </div>
          </div>

          <div className="popup-container-report padding10">
            <div className="row-start">
              <span className="font-inter15 margin-t2p font-blue">
                Η εκτίμηση μας για την συγκεκριμένη αναζήτηση βλέπετε να έχει
                μεγάλη απόκλιση τιμής ή είναι ανακριβής;
              </span>
              <b className="font-inter-b15 font-blue">
                {" "}
                Στείλτε μας για να το ελέγξουμε!
              </b>
            </div>
            <b className="font-inter-b15 margin-t5p font-blue">
              {" "}
              Ποιό πιστεύετε είναι το σωστό εύρος τιμής;
            </b>

            <div className="box-row width80p ">
              <Box className="add-property-half-width margin-r10">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Από"
                    value={from}
                    onChange={(event) => {
                      if (event.target.value !== "") {
                        setFrom(parseInt(event.target.value));
                      } else {
                        setFrom(null);
                      }
                    }}
                  />
                </FormControl>
              </Box>
              <Box className="add-property-half-width ">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Εώς"
                    value={to}
                    onChange={(event) => {
                      if (event.target.value !== "") {
                        setTo(parseInt(event.target.value));
                      } else {
                        setTo(null);
                      }
                    }}
                  />
                </FormControl>
              </Box>
            </div>
          </div>
          <div className="row-center-center  send-button-report margin-t2p ">
            <button className="footer-button-right" onClick={send}>
              Αποστολή
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportPrice;
