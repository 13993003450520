import { useEffect, useState } from "react";
import "./ContactPopup.css";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ContactPopup = (props) => {
  const [show, setShow] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();

    const formData = {
      name: name,
      surname: surname,
      email: email,
      phone: phone,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send_email`,
        formData
      );
      if (response.data.status === "success") {
        toast.success(
          "Το μήνυμα στάλθηκε, θα επικοινωνήσουμε μαζί σας σύντομα!"
        );
      } else {
        toast.error(
          "Υπήρξε ένα πρόβλημα με την αποστολή, παρακαλώ δοκιμάστε πάλι σε λίγο."
        );
      }
    } catch (error) {
      toast.error(
        "Υπήρξε ένα πρόβλημα με την αποστολή, παρακαλώ δοκιμάστε πάλι σε λίγο."
      );
      // Show an error message
    }
  };

  const closeHandler = () => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    setShow(props.show);
  }, [props]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <Modal
      open={show}
      sx={{ zIndex: 1500 }}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-contact"}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          closeOnClick
          pauseOnHover
        />
        <div className={"property-scroll"}>
        <IconButton className="close-button" onClick={closeHandler}>
  <Close />
</IconButton>

          <div className="na-column-start-start-contact">
            <div className="row-contact width100p">
              <div className="column-start-center">
                <span className="font-gothic-b40 red">
                  Επικοινώνησε μαζί μας!
                </span>
                <img
                  className="contact-us-img"
                  src={require("../../assets/get-in-touch-img.png")}
                  alt="developing vector"
                />
              </div>

              <div className="column-start-center">
                <span className="font-inter18 font-light-grey margin-b10">
                  Συμπλήρωσε αυτή την φόρμα καɩ ένας Σύμβουλος της newdeal group
                  θα επɩκοɩνωνήσεɩ μαζί σου γɩα να συζητήσετε σχετɩκά με το
                  ακίνητό σου!
                </span>
                <form onSubmit={submitForm}>
                  <TextField
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    id="outlined-basic"
                    label="Όνομα *"
                    variant="outlined"
                    style={{ width: "100%", marginTop: 10 }}
                  />
                  <TextField
                    value={surname}
                    onChange={(event) => setSurname(event.target.value)}
                    id="outlined-basic"
                    label="Επώνυμο *"
                    variant="outlined"
                    style={{ width: "100%", marginTop: 10 }}
                  />
                  <TextField
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    id="outlined-basic"
                    label="Email *"
                    variant="outlined"
                    style={{ width: "100%", marginTop: 10 }}
                  />
                  <TextField
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    id="outlined-basic"
                    label="Τηλέφωνο *"
                    variant="outlined"
                    style={{ width: "100%", marginTop: 10 }}
                  />
                  <div className="row-start-start margin-t20">
                    <Checkbox
                      style={{ color: "#e52628" }}
                      checked={accepted}
                      onChange={() => setAccepted(!accepted)}
                    />
                    <span className="font-inter16 font-light-grey ">
                      Δɩάβασα καɩ σύμφωνώ με την{" "}
                      <span className="red">πολɩτɩκή απορρήτου</span> καɩ τους{" "}
                      <span className="red">
                        {" "}
                        όρους Προστασίας Προσωπɩκών Δεδομένων{" "}
                      </span>{" "}
                      της newdeal group. Επίσης δίνω την συγκατάθεση μου γɩα
                      αποστολή ενημερωτɩκών newsletter προς το email που δήλωσα
                      παραπάνω.
                    </span>
                  </div>

                  <button
                    type="submit"
                    disabled={!accepted}
                    className="send-button font-inter-b18 white margin-t10"
                  >
                    Αποστολή
                  </button>
                </form>
                <ToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactPopup;
