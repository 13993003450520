import "./Neighborhoods.css";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import priceSlice from "../../store/slices/prices";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const kentro = [
  { title: "Άνω Κυψέλη", lat: "38.0059144", lng: "23.7469201" },
  { title: "Άνω Πατήσια", lat: "38.0209212", lng: "23.7296286" },
  { title: "Άνω Πετράλωνα", lat: "37.9682605", lng: "23.711503" },
  { title: "Αθήνα", lat: "37.9923004", lng: "23.7208034" },
  { title: "Ακαδημία Πλάτωνος", lat: "37.9925458", lng: "23.7058682" },
  { title: "Ακρόπολη", lat: "37.9716736", lng: "23.7260558" },
  { title: "Αμπελόκηποι", lat: "37.98867449999999", lng: "23.7637517" },
  { title: "Βύρωνας", lat: "37.9592596", lng: "23.7563687" },
  { title: "Γαλάτσι", lat: "38.0185778", lng: "23.746034" },
  { title: "Γηροκομείο", lat: "37.997896", lng: "23.7661033" },
  { title: "Γκύζη", lat: "37.9909165", lng: "23.7459493" },
  { title: "Γούβα", lat: "37.9591224", lng: "23.7359473" },
  { title: "Γουδή", lat: "37.9860376", lng: "23.7676309" },
  { title: "Δάφνη", lat: "37.9498129", lng: "23.734784" },
  { title: "Ελληνορώσων", lat: "37.9967282", lng: "23.774901" },
  { title: "Εξάρχεια-Νεάπολη", lat: "37.9878442", lng: "23.73566" },
  { title: "Ευελπίδων", lat: "37.9957353", lng: "23.7391564" },
  { title: "Ζάππειο", lat: "37.9697264", lng: "23.7355585" },
  { title: "Ζωγράφος", lat: "38.019483", lng: "23.717793" },
  { title: "Ιλίσια", lat: "37.9838096", lng: "23.7275388" },
  { title: "Κάτω Πετράλωνα", lat: "37.9684569", lng: "23.7092974" },
  { title: "Καισαριανή", lat: "37.9838096", lng: "23.7275388" },
  { title: "Κολωνάκι", lat: "37.9782361", lng: "23.7449263" },
  { title: "Κολωνός", lat: "37.9944764", lng: "23.7129839" },
  { title: "Κουκάκι-Φιλοπάππου", lat: "37.9647951", lng: "23.7184798" },
  { title: "Κυψέλη", lat: "38.0027173", lng: "23.7423148" },
  { title: "Λυκαβηττός", lat: "37.9817858", lng: "23.7430565" },
  { title: "Μετς", lat: "37.9667893", lng: "23.7360924" },
  { title: "Μοναστηράκι-Πλάκα", lat: "37.9725529", lng: "23.7303364" },
  { title: "Νέος Κόσμος", lat: "37.9568965", lng: "23.7246066" },
  { title: "Παγκράτι", lat: "37.96775969999999", lng: "23.7469741" },
  { title: "Πατήσια", lat: "38.0209212", lng: "23.7296286" },
  { title: "Πλ. Αμερικής", lat: "38.00198719999999", lng: "23.7296637" },
  { title: "Πλ. Αττικής", lat: "37.9838096", lng: "23.7275388" },
  { title: "Πολύγωνο", lat: "37.9974566", lng: "23.7457984" },
  { title: "Ριζούπολη", lat: "38.0270543", lng: "23.7403394" },
  { title: "Σεπόλια", lat: "38.0045679", lng: "23.7160906" },
  { title: "Ταύρος", lat: "37.9788792", lng: "23.7237731" },
  { title: "Υμηττός", lat: "37.9655119", lng: "23.7475243" },
];

const voreia = [
  { title: "Αγία Παρασκευή", lat: "38.0128173", lng: "23.7311236" },
  { title: "Βριλήσσια", lat: "38.0373632", lng: "23.8374167" },
  { title: "Δροσιά", lat: "38.119756", lng: "23.8661305" },
  { title: "Εκάλη", lat: "37.96459", lng: "23.743594" },
  { title: "Ηράκλειο", lat: "37.9838096", lng: "23.7275388" },
  { title: "Κηφισιά", lat: "38.0066624", lng: "23.776921" },
  { title: "Λυκόβρυση", lat: "38.067613", lng: "23.7804059" },
  { title: "Μαρούσι", lat: "38.0549562", lng: "23.807655" },
  { title: "Μελίσσια", lat: "38.0572295", lng: "23.8336016" },
  { title: "Μεταμόρφωση", lat: "38.0145936", lng: "23.7354596" },
  { title: "Νέα Ερυθραία", lat: "38.0926493", lng: "23.8200477" },
  { title: "Νέα Ιωνία", lat: "38.0426062", lng: "23.7536323" },
  { title: "Νέα Πεντέλη", lat: "38.0606091", lng: "23.8590907" },
  { title: "Νέο Ψυχικό", lat: "38.0047591", lng: "23.7797255" },
  { title: "Παπάγος", lat: "37.9780828", lng: "23.7693546" },
  { title: "Πεντέλη", lat: "37.9750748", lng: "23.7319" },
  { title: "Πεύκη", lat: "38.06251719999999", lng: "23.7946578" },
  { title: "Φιλοθέη", lat: "38.0227334", lng: "23.7765697" },
  { title: "Χαλάνδρι", lat: "38.0221334", lng: "23.7998646" },
  { title: "Χολαργός", lat: "37.9975628", lng: "23.8027203" },
  { title: "Ψυχικό", lat: "37.9838096", lng: "23.7275388" },
];

const notia = [
  { title: "Άγιος Δημήτριος", lat: "37.9141396", lng: "23.7253624" },
  { title: "Άλιμος", lat: "37.9184156", lng: "23.7274751" },
  { title: "Αργυρούπολη", lat: "37.9059505", lng: "23.7536675" },
  { title: "Βάρη", lat: "37.9838096", lng: "23.7275388" },
  { title: "Βούλα", lat: "37.8448947", lng: "23.7703425" },
  { title: "Βουλιαγμένη", lat: "37.9838096", lng: "23.7275388" },
  { title: "Γλυφάδα", lat: "37.879787", lng: "23.7692328" },
  { title: "Ελληνικό", lat: "37.8973965", lng: "23.7202997" },
  { title: "Ηλιούπολη", lat: "37.9322307", lng: "23.7554815" },
  { title: "Καλλιθέα", lat: "37.955893", lng: "23.7020975" },
  { title: "Νέα Σμύρνη", lat: "37.9444167", lng: "23.7127056" },
  { title: "Παλαιό Φάληρο", lat: "37.9369666", lng: "23.6976423" },
];

const ditika = [
  { title: "Αιγάλεω", lat: "37.9787037", lng: "23.7069646" },
  { title: "Μοσχάτο", lat: "37.94412850000001", lng: "23.6780658" },
  { title: "Νέα Φιλαδέλφεια", lat: "38.036717", lng: "23.7400645" },
  { title: "Νέα Χαλκηδόνα", lat: "38.0279965", lng: "23.7328371" },
  { title: "Περιστέρι", lat: "38.0050107", lng: "23.7113657" },
  { title: "Πετρούπολη", lat: "38.0397922", lng: "23.6886104" },
  { title: "Χαϊδάρι", lat: "38.0119445", lng: "23.6539907" },
];

const anatolika = [
  { title: "Ανθούσα", lat: "38.0230869", lng: "23.8724272" },
  { title: "Γέρακας", lat: "38.0172746", lng: "23.854135" },
  { title: "Γλυκά Νερά", lat: "37.9896687", lng: "23.8473496" },
  { title: "Νέα Μάκρη", lat: "38.0879", lng: "23.9761" },
  { title: "Ραφήνα", lat: "38.0234", lng: "24.0059" },
  { title: "Αρτέμιδα", lat: "37.9704", lng: "24.0077" },
];

const peiraias = [
  { title: "Δραπετσώνα", lat: "37.9499825", lng: "23.6239953" },
  { title: "Κερατσίνι", lat: "37.9643568", lng: "23.6215313" },
  { title: "Κορυδαλλός", lat: "37.9886446", lng: "23.7449902" },
  { title: "Νίκαια", lat: "37.9954597", lng: "23.7299404" },
  { title: "Πειραιάς", lat: "37.9429857", lng: "23.6469832" },
];

function Neighborhoods() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [extendNeighborhood1, setExtendNeighborhood1] = useState(true);
  const [extendNeighborhood2, setExtendNeighborhood2] = useState(true);
  const [extendNeighborhood3, setExtendNeighborhood3] = useState(true);
  const [extendNeighborhood4, setExtendNeighborhood4] = useState(true);
  const [extendNeighborhood5, setExtendNeighborhood5] = useState(true);
  const [extendNeighborhood6, setExtendNeighborhood6] = useState(true);

  const handleSetArea = (area) => {
    const center = {
      lat: Number(area.lat),
      lng: Number(area.lng),
    };
    let marker = {};
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: center }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          marker = results[0];
          let google_city = "";
          let google_neighborhood = "";

          marker.address_components.forEach((component) => {
            if (component.types.includes("locality")) {
              google_city = component.long_name;
            } else if (
              component.types.includes("administrative_area_level_3")
            ) {
              google_neighborhood = component.long_name;
            }
          });

          let body = {
            geo_lat: Number(area.lat),
            geo_lng: Number(area.lng),
            google_city: google_city,
            google_neighborhood: google_neighborhood,
            type: "RESIDENCE",
            area_min: 0,
            area_max: 100000,
            floor_min: -3,
            floor_max: 20,
            construction_year_min: 1900,
            construction_year_max: 2023,
            bedrooms_min: 0,
            bedrooms_max: 100,
            price_min: 0,
            price_max: 100000000,
            price_per_unit_min: 0,
            price_per_unit_max: 1000000,
            level: null,
            corner: null,
            square: null,
            internal: false,
            is_keep: null,
            sea_view: null,
            mountain_view: null,
            fireplace: null,
            pool: null,
            parking: null,
            storage: null,
            no_heating: null,
            no_elevator: null,
            owners: null,
            is_destroy: null,
            is_damaged: null,
            material: 1,

            finished_level: 1,
          };

          dispatch(priceSlice.actions.setRequest(body));

          dispatch(priceSlice.actions.setMarker(marker));

          navigate("/resultsneighborhood");
        } else {
          console.error("No results found");
        }
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };
  return (
    <div className="neighborhoods-back">
      {window.innerWidth >= 1750 ? (
        <div className="column-start-center width100p">
          <span className="font-gothic-b40 red margin-t5p  margin-b2p">
            Δείκτες τιμών στην Αττική
          </span>
          <div className="row-space-between-start width100p">
            <div className="n-normal-column-start-sb">
              <div
                className="row-start-start pointer"
                onClick={() => setExtendNeighborhood1(!extendNeighborhood1)}
              >
                <span className="font-gothic-b20 font-blue">
                  Αθήνα - Κέντρο
                </span>
                <img
                  style={{ rotate: extendNeighborhood1 ? "none" : "180deg" }}
                  className="normal-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood1 ? "none" : "flex" }}
              >
                {kentro.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>

            <div className="n-normal-column-start-sb">
              <div
                className="row-start-start pointer"
                onClick={() => setExtendNeighborhood2(!extendNeighborhood2)}
              >
                <span className="font-gothic-b20 font-blue">
                  Βόρεια Προάστια
                </span>
                <img
                  style={{ rotate: extendNeighborhood2 ? "none" : "180deg" }}
                  className="normal-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood2 ? "none" : "flex" }}
              >
                {voreia.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>

            <div className="n-normal-column-start-sb">
              <div
                className="row-start-start pointer"
                onClick={() => setExtendNeighborhood3(!extendNeighborhood3)}
              >
                <span className="font-gothic-b20 font-blue">
                  Νότια Προάστια
                </span>
                <img
                  style={{ rotate: extendNeighborhood3 ? "none" : "180deg" }}
                  className="normal-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood3 ? "none" : "flex" }}
              >
                {notia.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>

            <div className="n-normal-column-start-sb">
              <div
                className="row-start-start pointer"
                onClick={() => setExtendNeighborhood4(!extendNeighborhood4)}
              >
                <span className="font-gothic-b20 font-blue">
                  Δυτικά Προάστια
                </span>
                <img
                  style={{ rotate: extendNeighborhood4 ? "none" : "180deg" }}
                  className="normal-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood4 ? "none" : "flex" }}
              >
                {ditika.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>

            <div className="n-normal-column-start-sb">
              <div
                className="row-start-start pointer"
                onClick={() => setExtendNeighborhood5(!extendNeighborhood5)}
              >
                <span className="font-gothic-b20 font-blue">
                  Ανατολικά Προάστια
                </span>
                <img
                  style={{ rotate: extendNeighborhood5 ? "none" : "180deg" }}
                  className="normal-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood5 ? "none" : "flex" }}
              >
                {anatolika.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>

            <div className="n-normal-column-start-sb">
              <div
                className="row-start-start pointer"
                onClick={() => setExtendNeighborhood6(!extendNeighborhood6)}
              >
                <span className="font-gothic-b20 font-blue">Πειραιάς</span>
                <img
                  style={{ rotate: extendNeighborhood6 ? "none" : "180deg" }}
                  className="normal-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood6 ? "none" : "flex" }}
              >
                {peiraias.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : window.innerWidth >= 1200 ? (
        <div className="column-space-around width100p">
          <span className="font-gothic-b40 red margin-t5p  margin-b2p">
            Δείκτες τιμών στην Αττική
          </span>
          <div className="row-space-between-start width100p">
            <div className="n-column-start-sb">
              <div
                className="row-space-between-center pointer"
                onClick={() => setExtendNeighborhood1(!extendNeighborhood1)}
              >
                <span className="font-gothic-b20 font-blue">
                  Αθήνα - Κέντρο
                </span>
                <img
                  className="arrow-down"
                  style={{ rotate: extendNeighborhood1 ? "none" : "180deg" }}
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood1 ? "none" : "flex" }}
              >
                {kentro.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>
            <div className="n-column-start-sb">
              <div
                className="row-space-between-center pointer"
                onClick={() => setExtendNeighborhood2(!extendNeighborhood2)}
              >
                <span className="font-gothic-b20 font-blue">
                  Βόρεια Προάστια
                </span>
                <img
                  style={{ rotate: extendNeighborhood2 ? "none" : "180deg" }}
                  className="arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood2 ? "none" : "flex" }}
              >
                {voreia.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>

            <div className="n-column-start-sb">
              <div
                className="row-space-between-center pointer"
                onClick={() => setExtendNeighborhood3(!extendNeighborhood3)}
              >
                <span className="font-gothic-b20 font-blue">
                  Νότια Προάστια
                </span>
                <img
                  className="arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                  style={{ rotate: extendNeighborhood3 ? "none" : "180deg" }}
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood3 ? "none" : "flex" }}
              >
                {notia.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="row-space-between-start width100p">
            <div className="n-column-start-sb">
              <div
                className="row-space-between-center pointer"
                onClick={() => setExtendNeighborhood4(!extendNeighborhood4)}
              >
                <span className="font-gothic-b20 font-blue">
                  Δυτικά Προάστια
                </span>
                <img
                  style={{ rotate: extendNeighborhood4 ? "none" : "180deg" }}
                  className="arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood4 ? "none" : "flex" }}
              >
                {ditika.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>

            <div className="n-column-start-sb">
              <div
                className="row-space-between-center pointer"
                onClick={() => setExtendNeighborhood5(!extendNeighborhood5)}
              >
                <span className="font-gothic-b20 font-blue">
                  Ανατολικά Προάστια
                </span>
                <img
                  style={{ rotate: extendNeighborhood5 ? "none" : "180deg" }}
                  className="arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood5 ? "none" : "flex" }}
              >
                {anatolika.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>

            <div className="n-column-start-sb">
              <div
                className="row-space-between-center pointer"
                onClick={() => setExtendNeighborhood6(!extendNeighborhood6)}
              >
                <span className="font-gothic-b20 font-blue">Πειραιάς</span>
                <img
                  style={{ rotate: extendNeighborhood6 ? "none" : "180deg" }}
                  className="arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood6 ? "none" : "flex" }}
              >
                {peiraias.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mob-end-selection">
          <span className="font-gothic-b25 red margin-t10p  margin-b5p">
            Δείκτες τιμών στην Αττική
          </span>
          <div className="end-sel">
            <div className="n-normal-column-start-sb mob-width47p">
              <div
                className="row-space-between-center  pointer"
                onClick={() => setExtendNeighborhood1(!extendNeighborhood1)}
              >
                <span className="font-gothic-b20 font-blue">
                  Αθήνα - Κέντρο
                </span>
                <img
                  style={{ rotate: extendNeighborhood1 ? "none" : "180deg" }}
                  className="mob-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood1 ? "none" : "flex" }}
              >
                {kentro.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>

            <div className="n-normal-column-start-sb mob-width47p">
              <div
                className="row-space-between-center pointer"
                onClick={() => setExtendNeighborhood4(!extendNeighborhood4)}
              >
                <span className="font-gothic-b20 font-blue">
                  Δυτικά Προάστια
                </span>
                <img
                  style={{ rotate: extendNeighborhood4 ? "none" : "180deg" }}
                  className="mob-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood4 ? "none" : "flex" }}
              >
                {ditika.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="end-sel">
            <div className="n-normal-column-start-sb mob-width47p">
              <div
                className="row-space-between-center pointer"
                onClick={() => setExtendNeighborhood2(!extendNeighborhood2)}
              >
                <span className="font-gothic-b20 font-blue">
                  Βόρεια Προάστια
                </span>
                <img
                  style={{ rotate: extendNeighborhood2 ? "none" : "180deg" }}
                  className="mob-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood2 ? "none" : "flex" }}
              >
                {voreia.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>

            <div className="n-normal-column-start-sb mob-width47p">
              <div
                className="row-space-between-center pointer"
                onClick={() => setExtendNeighborhood5(!extendNeighborhood5)}
              >
                <span className="font-gothic-b20 font-blue">
                  Ανατολικά Προάστια
                </span>
                <img
                  style={{ rotate: extendNeighborhood5 ? "none" : "180deg" }}
                  className="mob-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood5 ? "none" : "flex" }}
              >
                {anatolika.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="end-sel">
            <div className="n-normal-column-start-sb mob-width47p">
              <div
                className="row-space-between-center pointer"
                onClick={() => setExtendNeighborhood3(!extendNeighborhood3)}
              >
                <span className="font-gothic-b20 font-blue">
                  Νότια Προάστια
                </span>
                <img
                  style={{ rotate: extendNeighborhood3 ? "none" : "180deg" }}
                  className="mob-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood3 ? "none" : "flex" }}
              >
                {notia.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>

            <div className="n-normal-column-start-sb mob-width47p">
              <div
                className="row-space-between-center pointer"
                onClick={() => setExtendNeighborhood6(!extendNeighborhood6)}
              >
                <span className="font-gothic-b20 font-blue">Πειραιάς</span>
                <img
                  style={{ rotate: extendNeighborhood6 ? "none" : "180deg" }}
                  className="mob-arrow-down"
                  src={require("../../assets/arrow-down.png")}
                  alt="developing vector"
                />
              </div>
              <div
                className="column-start-start width100p"
                style={{ display: extendNeighborhood6 ? "none" : "flex" }}
              >
                {peiraias.map((area) => (
                  <span
                    key={area.title}
                    className="font-inter16 deep-grey pointer"
                    onClick={() => handleSetArea(area)}
                  >
                    {area.title}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Neighborhoods;
