import "./NavBar.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import img1 from "../../assets/menu-icon-mobile.png";
import img2 from "../../assets/menu-icon-open.png";
import img3 from "../../assets/logo-mobile-blue.png";
import img4 from "../../assets/logo-mobile-white.png";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import DropDownServices from "../../components/drop-down-services/DropDownServices";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

function NavBar() {
  const [navBarChange, setNavBarChange] = useState(false);
  const navigate = useNavigate();
  const [openServices, setOpenServices] = useState(false);
  const [extendKeyword1, setExtendKeyword1] = useState(true);
  const [extendKeyword2, setExtendKeyword2] = useState(true);
  const [extendKeyword3, setExtendKeyword3] = useState(true);
  const [extendKeyword4, setExtendKeyword4] = useState(true);
  const [extendKeyword5, setExtendKeyword5] = useState(true);

  function handleClick() {
    navigate("/");
  }

  return (
    <div>
      {window.innerWidth >= 1200 ? (
        <div className="navbar-back">
          <div className="nav-row-sb-left">
            <Link to="/">
              {" "}
              <img
                style={{ marginLeft: 20, width: 191 }}
                src={require("../../assets/logo-mobile-blue.png")}
                alt="developing vector"
              />
            </Link>

            <Link to="https://newdeal.gr/el/buy/residential">
              <span className="font-gothic-b16 white margin-r20">Πωλήσεις</span>
            </Link>

            <Link to="https://newdeal.gr/el/rent/residential">
              <span className="font-gothic-b16 white margin-r20">
                Ενοικιάσεις
              </span>
            </Link>
            {/* <Link to="/">
              <button className="navbar-button-blur font-gothic-b16 white">
                Online Εκτίμηση Ακινήτου
              </button>
            </Link> */}
          </div>

          <div className="nav-row-sb-right">
            <div
              className="row-start-center pointer"
              onClick={() => setOpenServices(true)}
            >
              <span className="font-gothic-b16 white ">Υπηρεσίες</span>
              <KeyboardArrowDownRoundedIcon
                style={{
                  color: "white",
                  fontSize: 20,
                  marginTop: 4,
                  marginLeft: 5,
                }}
              />
            </div>

            <Modal
              sx={{ zIndex: 1500 }}
              hideBackdrop={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              open={openServices}
              onClick={() => setOpenServices(false)}
              disableScrollLock="true"
            >
              <DropDownServices show={openServices} />
            </Modal>

            {/* <LanguageRoundedIcon className="white" style={{ width: 25 }} /> */}
            {/* <span className="font-gothic-b18 white margin-r20">GR</span> */}
            <Link to="https://newdeal.gr/el/add-listing">
              <button className="navbar-button font-gothic-b16 white">
                Καταχώρηση ακινήτου
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="mob-navbar-back"
            style={{ backgroundColor: navBarChange ? "white" : "#242854" }}
          >
            <div
              style={{
                backgroundImage: navBarChange ? `url(${img4})` : `url(${img3})`,
              }}
              className="mob-nav-logo pointer"
              onClick={handleClick}
            ></div>
            <div
              onClick={() => setNavBarChange(!navBarChange)}
              className="mob-menu-button"
              style={{
                backgroundImage: navBarChange ? `url(${img2})` : `url(${img1})`,
                height: 50,
              }}
            ></div>
          </div>
          <Modal
            open={navBarChange}
            hideBackdrop={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ height: "100vh" }}
          >
            <div className="mob-modal-open">
              <Link
                to="https://newdeal.gr/el/buy/residential"
                className="width100p"
              >
                <div className="mob-nav-line">
                  <span className="font-gothic-b20 nav-blue">Πωλήσεις</span>
                  <KeyboardArrowDownRoundedIcon
                    className="nav-blue"
                    style={{ fontSize: 30 }}
                  />
                </div>
              </Link>

              <Link
                to="https://newdeal.gr/el/rent/residential"
                className="width100p"
              >
                <div className="mob-nav-line">
                  <span className="font-gothic-b20 nav-blue">Ενοικιάσεις</span>
                  <KeyboardArrowDownRoundedIcon
                    className="nav-blue"
                    style={{ fontSize: 30 }}
                  />
                </div>
              </Link>

              <Link to="https://newdeal.gr/el" className="width100p">
                <div className="mob-nav-line">
                  <span className="font-gothic-b20 nav-blue">Σύνδεση</span>
                  <KeyboardArrowDownRoundedIcon
                    className="nav-blue"
                    style={{ fontSize: 30 }}
                  />
                </div>
              </Link>

              <Link to="https://newdeal.gr/el" className="width100p">
                <div className="mob-nav-line">
                  <span className="font-gothic-b20 nav-blue">Υπηρεσίες</span>
                  <KeyboardArrowDownRoundedIcon
                    className="nav-blue"
                    style={{ fontSize: 30 }}
                  />
                </div>
              </Link>

              <Link
                to="https://newdeal.gr/el/add-listing"
                className="width100p"
              >
                <div className="mob-nav-line mob-nav-line">
                  <span className="font-gothic-b20 nav-blue">
                    {" "}
                    Καταχώρηση ακινήτου
                  </span>
                  <KeyboardArrowDownRoundedIcon
                    className="nav-blue"
                    style={{ fontSize: 30 }}
                  />
                </div>
              </Link>

              <div
                className="mob-nav-line pointer"
                onClick={() => setExtendKeyword1(!extendKeyword1)}
              >
                <span
                  className="font-gothic-b20"
                  style={{ color: extendKeyword1 ? "#354aa2" : "#e52628" }}
                >
                  Ιδιοκτήτες
                </span>
                <KeyboardArrowDownRoundedIcon
                  style={{
                    fontSize: 30,
                    rotate: extendKeyword1 ? "none" : "180deg",
                    color: extendKeyword1 ? "#354aa2" : "#e52628",
                  }}
                />
              </div>
              <div
                className="column-start-start margin-b20"
                style={{ display: extendKeyword1 ? "none" : "flex" }}
              >
                <Link to="ttps://newdeal.gr/el/add-listing">
                  <div className="row-start-center margin-b10">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15571.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">Ανάθεση</span>
                  </div>
                </Link>
                <Link to="https://newdeal.gr/el/request">
                  <div className="row-start-center">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15570.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">Ζήτηση</span>
                  </div>
                </Link>
              </div>

              <div
                className="mob-nav-line pointer"
                onClick={() => setExtendKeyword2(!extendKeyword2)}
              >
                <span
                  className="font-gothic-b20 "
                  style={{
                    color: extendKeyword2 ? "#354aa2" : "#e52628",
                  }}
                >
                  Αγοραστές - Ενοικιαστές
                </span>
                <KeyboardArrowDownRoundedIcon
                  style={{
                    fontSize: 30,
                    rotate: extendKeyword2 ? "none" : "180deg",
                    color: extendKeyword2 ? "#354aa2" : "#e52628",
                  }}
                />
              </div>
              <div
                className="column-start-start margin-b20"
                style={{ display: extendKeyword2 ? "none" : "flex" }}
              >
                <Link to="https://newdeal.gr/el/buy/residential">
                  <div className="row-start-center margin-b10">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15559.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">
                      Αγόρασε ακίνητο
                    </span>
                  </div>
                </Link>
                <Link to="https://newdeal.gr/el/rent/residential">
                  <div className="row-start-center">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15558.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">
                      Ενοικίασε ακίνητο
                    </span>
                  </div>
                </Link>
              </div>

              <div
                className="mob-nav-line pointer"
                onClick={() => setExtendKeyword3(!extendKeyword3)}
              >
                <span
                  className="font-gothic-b20"
                  style={{ color: extendKeyword3 ? "#354aa2" : "#e52628" }}
                >
                  Για Επαγγελματίες
                </span>
                <KeyboardArrowDownRoundedIcon
                  style={{
                    fontSize: 30,
                    rotate: extendKeyword3 ? "none" : "180deg",
                    color: extendKeyword3 ? "#354aa2" : "#e52628",
                  }}
                />
              </div>
              <div
                className="column-start-start margin-b20"
                style={{ display: extendKeyword3 ? "none" : "flex" }}
              >
                <Link to="https://realty.newdeal.gr/kariera/">
                  <div className="row-start-center margin-b10">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15560.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">
                      Γίνε συνεργάτης μας
                    </span>
                  </div>
                </Link>

                <Link to="https://realty.newdeal.gr/franchise/">
                  <div className="row-start-center margin-b20">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15561.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">
                      Άνοιξε δικό σου γραφείο
                    </span>
                  </div>
                </Link>

                <Link to="https://realty.newdeal.gr/virtualoffice">
                  <div className="row-start-center margin-b20">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15562.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">
                      Δούλεψε με το virtual office
                    </span>
                  </div>
                </Link>

                <Link to="ttps://realty.newdeal.gr/virtualoffice/ypostiriksi__ekpaideusi_real_estate/">
                  <div className="row-start-center margin-b20">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15569.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">
                      Υποστήριξη & Εκπαίδευση
                    </span>
                  </div>
                </Link>

                <Link to="https://realty.newdeal.gr/virtualoffice/marketing">
                  <div className="row-start-center margin-b20">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15568.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">Marketing</span>
                  </div>
                </Link>

                <Link to="https://realty.newdeal.gr/virtualoffice/systaseis/">
                  <div className="row-start-center margin-b20">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15567.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">
                      Δίκτυο Συνεργατών
                    </span>
                  </div>
                </Link>
              </div>

              <div
                className="mob-nav-line pointer"
                onClick={() => setExtendKeyword4(!extendKeyword4)}
              >
                <span
                  className="font-gothic-b20 "
                  style={{ color: extendKeyword4 ? "#354aa2" : "#e52628" }}
                >
                  Εκπαίδευση στο Real Estate
                </span>
                <KeyboardArrowDownRoundedIcon
                  style={{
                    fontSize: 30,
                    rotate: extendKeyword4 ? "none" : "180deg",
                    color: extendKeyword4 ? "#354aa2" : "#e52628",
                  }}
                />
              </div>

              <div
                className="column-start-start margin-b20"
                style={{ display: extendKeyword4 ? "none" : "flex" }}
              >
                <Link to="https://rean.gr">
                  <div className="row-start-center margin-b10">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15563.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">
                      Συνεργασία με ΕΚΠΑ
                    </span>
                  </div>
                </Link>
                <Link to="https://academy.newdeal.gr">
                  <div className="row-start-center margin-b10">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15563.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">
                      Ακαδημία Real Estate
                    </span>
                  </div>
                </Link>
              </div>

              <div
                className="mob-nav-line  pointer"
                onClick={() => setExtendKeyword5(!extendKeyword5)}
                style={{
                  borderBottom: extendKeyword5
                    ? "1px solid rgba(39, 54, 116, 0.31)"
                    : "unset",
                }}
              >
                <span
                  className="font-gothic-b20"
                  style={{ color: extendKeyword5 ? "#354aa2" : "#e52628" }}
                >
                  Ποιοί είμαστε
                </span>
                <KeyboardArrowDownRoundedIcon
                  style={{
                    fontSize: 30,
                    rotate: extendKeyword5 ? "none" : "180deg",
                    color: extendKeyword5 ? "#354aa2" : "#e52628",
                  }}
                />
              </div>

              <div
                className="column-start-start margin-b20 width100p mob-nav-line-bottom"
                style={{ display: extendKeyword5 ? "none" : "flex" }}
              >
                <Link to="https://realty.newdeal.gr">
                  <div className="row-start-center margin-b10">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15566.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">
                      Νέα στο Real Estate
                    </span>
                  </div>
                </Link>

                <Link to="https://realty.newdeal.gr/news/one-stop-shop/">
                  <div className="row-start-center margin-b20">
                    <img
                      className="pointer"
                      src={require("../../assets/Group 15565.png")}
                      alt="developing vector"
                      style={{ marginRight: 20 }}
                    />
                    <span className="font-inter18 nav-blue">
                      Τι σου προσφέρουμε
                    </span>
                  </div>
                </Link>
              </div>

              <div className="margin-t40">
                <img
                  style={{ width: 19, marginRight: 10, marginBottom: -3 }}
                  src={require("../../assets/nav-mob1.png")}
                  alt="developing vector"
                />

                <span className="nav-blue font-inter-m14">
                  Κανάρɩ 24, Κολωνάκɩ 10 674
                </span>
              </div>
              <div className="margin-t20">
                <img
                  style={{ width: 19, marginRight: 10 }}
                  src={require("../../assets/nav-mob2.png")}
                  alt="developing vector"
                />
                <span className="nav-blue font-inter-m14">
                  (+30) 210 33 90 500
                </span>
              </div>
              <div className="margin-t20">
                <img
                  style={{ width: 19, marginRight: 10 }}
                  src={require("../../assets/nav-mob3.png")}
                  alt="developing vector"
                />
                <span className="nav-blue font-inter-m14">info@newdeal.gr</span>
              </div>

              <button className="mob-navbar-button">
                <img
                  className="pointer"
                  src={require("../../assets/Group 15571-white.png")}
                  alt="developing vector"
                  style={{ marginRight: 10, marginTop: 9 }}
                />
                <span className="font-gothic-b16 white">
                  Καταχώρηση ακινήτου
                </span>
              </button>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default NavBar;
