import { useEffect, useState } from "react";
import "./SearchProperty.css";
import { ReactComponent as HouseIcon } from "../../assets/house.svg";
import { ReactComponent as ProfIcon } from "../../assets/prof.svg";
import { ReactComponent as LandIcon } from "../../assets/land.svg";
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import axios from "axios";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import priceSlice from "../../store/slices/prices";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Button,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@mui/material";
// import Checkbox from "@mui/material/Checkbox";
// import moment from "moment";
// import { useSelector, useDispatch } from "react-redux";
// import { RootState } from "../../store";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import "moment/locale/en-gb";
import MapComponent from "../map/Map";

// import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SearchProperty = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const [type, setType] = useState("RESIDENCE");
  const [propertyType, setPropertyType] = useState("APARTMENT");
  const [area, setArea] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bedrooms, setBedrooms] = useState();
  const [floor, setFloor] = useState();
  const [areaError, setAreaError] = useState(false);
  const [bedroomsError, setBedroomsError] = useState(false);
  const [floorError, setFloorError] = useState(false);
  const [constructionYearError, setConstructionYearError] = useState(false);
  const [renovationYearError, setRenovationYearError] = useState(false);
  const [energyClassError, setEnergyClassError] = useState(false);
  const [coverageRatioError, setCoverageRatioError] = useState(false);
  const [buildingRatioError, setBuildingRatioError] = useState(false);

  const [facadeLengthError, setFacadeLengthError] = useState(false);
  const [zoningError, setZoningError] = useState(false);
  const [conditionError, setConditionError] = useState(false);
  const [cityPlanError, setCityPlanError] = useState(false);

  const [hasNoElevator, setHasNoElevator] = useState(false);
  const [contactType, setContactType] = useState("");
  const [hasViewSea, setHasViewSea] = useState(false);
  const [hasViewScene, setHasViewScene] = useState(false);
  const [hasViewMountain, setHasViewMountain] = useState(false);
  const [hasStorage, setHasStorage] = useState(false);
  const [hasFirePlace, setHasFirePlace] = useState(false);
  const [hasPool, setHasPool] = useState(false);
  const [constructionYear, setConstructionYear] = useState();
  const [renovationYear, setRenovationYear] = useState();
  const [coverageRatio, setCoverageRatio] = useState();
  const [buildingRatio, setBuildingRatio] = useState();
  const [condition, setCondition] = useState("GOOD");
  const [cityPlan, setCityPlan] = useState("INSIDE");
  const [zoning, setZoning] = useState("RESIDENTIAL");
  const [facadeLength, setFacadeLength] = useState();
  const [energyClass, setEnergyClass] = useState();
  const [hasParking, setHasParking] = useState(false);
  const [hasAutonomousHeating, setHasAutonomousHeating] = useState(false);
  const [isBeingBuilt, setIsBeingBuilt] = useState(false);
  const [isCorner, setIsCorner] = useState(false);
  const [isKeep, setIsKeep] = useState(false);
  const [isInternal, setInternal] = useState(false);
  const [isDestroy, setIsDestroy] = useState(false);
  const [isDamaged, setIsDamaged] = useState(false);
  const [hasOwners, setHasOwners] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [isStreetSquare, setIsStreetSquare] = useState(false);

  const messages = [
    "Εφαρμόζουμε καινοτόμους αλγόριθμους για τις  εκτιμήσεις τιμών... Παρακαλώ περιμένετε.",
    "Επεξεργαζόμαστε τα χαρακτηριστικά του ακινήτου σας... Σύντομα θα έχετε τα αποτελέσματα.",
    "Συγκεντρώνουμε τα τελευταία δεδομένα της αγοράς... Μείνετε συντονισμένοι.",
    "Υπολογίζοντας την τιμή του ακινήτου σας ανάμεσα σε χιλιάδες ακίνητα... Παρακαλώ περιμένετε.",
    "Συγκρίνουμε τις τιμές ακινήτων στην περιοχή σας... Παρακαλώ περιμένετε.",
    "Τα αποτελέσματα θα είναι διαθέσιμα σε λίγα δευτερόλεπτα.",
  ];

  const [currentMessage, setCurrentMessage] = useState(messages[0]);

  useEffect(() => {
    if (
      localStorage.getItem("formId") &&
      localStorage.getItem("formId") !== "null" &&
      localStorage.getItem("formId") !== "undefined"
    ) {
      console.log(localStorage.getItem("formId"));
      setShowForm(false);
    }
  }, []);
  useEffect(() => {
    let intervalId;

    if (isLoading) {
      intervalId = setInterval(() => {
        setCurrentMessage((prevMessage) => {
          const currentIndex = messages.indexOf(prevMessage);
          const nextIndex = (currentIndex + 1) % messages.length;
          return messages[nextIndex];
        });
      }, 5240);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isLoading]);
  const closeHandler = () => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    setShow(props.show);
    console.log(props.show);
  }, [props]);

  const isCurrentStepValid = () => {
    let isValid = true;
    setAreaError(false);
    setBedroomsError(false);
    setConstructionYearError(false);
    setRenovationYearError(false);
    setEnergyClassError(false);
    setCoverageRatioError(false);
    setFacadeLengthError(false);
    setZoningError(false);
    setConditionError(false);
    setCityPlanError(false);
    switch (step) {
      case 1:
        if (!type) return false;
        break;
      case 2:
        if (
          type == "RESIDENCE" &&
          (!area || !bedrooms || !floor || !constructionYear)
        ) {
          if (!area) {
            setAreaError(true);
          }
          if (!bedrooms) {
            setBedroomsError(true);
          }
          if (!floor) {
            setFloorError(true);
          }
          if (!constructionYear) {
            setConstructionYearError(true);
          }
          isValid = false;
        }

        if (
          type == "PROFESSIONAL" &&
          (!area ||
            !renovationYear ||
            !floor ||
            !constructionYear ||
            !energyClass)
        ) {
          if (!area) {
            setAreaError(true);
          }
          if (!renovationYear) {
            setRenovationYearError(true);
          }
          if (!floor) {
            setFloorError(true);
          }
          if (!constructionYear) {
            setConstructionYearError(true);
          }
          if (!energyClass) {
            setEnergyClassError(true);
          }
          isValid = false;
        }
        if (
          type == "LAND" &&
          (!area ||
            !bedrooms ||
            !coverageRatio ||
            !buildingRatio ||
            !facadeLength)
        ) {
          if (!area) {
            setAreaError(true);
          }
          if (!bedrooms) {
            setBedroomsError(true);
          }
          if (!coverageRatio) {
            setCoverageRatioError(true);
          }
          if (!buildingRatio) {
            setBuildingRatioError(true);
          }
          if (!facadeLength) {
            setFacadeLengthError(true);
          }
          isValid = false;
        }
        break;
      case 3:
        if (type === "PROFESSIONAL" && (!zoning || !condition)) return false;
        if (type === "LAND" && (!cityPlan || !zoning)) return false;
        break;
      case 4:
        break;
      case 5:
        if (!accepted) return false;
        break;
      default:
        isValid = true;
    }
    return isValid;
  };

  useEffect(() => {
    setArea();
    setBedrooms();
    setStep(1);
    setConstructionYear();
    setFloor();
    setIsCorner(false);
    setFirstName("");
    setLastName("");
    setIsLoading(false);
    setEmail("");
    setPhoneNumber("");
    setHasAutonomousHeating(false);
    setHasNoElevator(false);
    setHasFirePlace(false);
    setHasParking(false);
    setHasPool(false);
    setHasStorage(false);
    setHasViewMountain(false);
    setHasViewScene(false);
    setHasViewSea(false);
    setIsBeingBuilt(false);
    setIsStreetSquare(false);
  }, []);
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  // useEffect(() => {
  //   if (isFirst) {
  //     setIsFirst(false);
  //   }
  //   if (props.property_id) {
  //     props.propertys.forEach((property) => {
  //       if (props.property_id === property.id) {
  //         setNameOfproperty(property.name);
  //         setStartDate(property.start_date);
  //         setEndDate(property.end_date);
  //         setDescription(property.description);
  //         setStatus(property.status);
  //         setCompletion(property.completion);
  //         setPredecessors(property.predecessors);
  //       }
  //     });
  //   } else {
  //     setNameOfproperty("");
  //     setStartDate("");
  //     setEndDate("");
  //     setDescription("");
  //     setStatus("");
  //     setCompletion(0);
  //     setPredecessors([]);
  //   }
  // }, []);

  const send = () => {
    if (!showForm) {
      let bodyFiller = {
        id: localStorage.getItem("formId"),
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/update_form_filler`, bodyFiller)
        .then((res) => {
          console.log(res);
          localStorage.setItem("formId", res.data.id);
          setShowForm(false);
          search();
        })

        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              toast.error(key + ":" + err.response.data[key]);
            });
          } else {
            toast.error(err.message);
          }
          setShowForm(true);
          localStorage.setItem("formId", null);
          console.log(err);
        });
    } else {
      let bodyFiller = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phoneNumber,
        type: contactType,
        search_type:type,
        address:props.marker.formatted_address
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/add_form_filler`, bodyFiller)
        .then((res) => {
          localStorage.setItem("formId", res.data.id);
          setShowForm(false);
          search();
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                toast.error(key + ":" + err.response.data[key]);
              });
            } else {
              toast.error(err.message);
            }

            console.log(err);
          }
        });
    }
  };

  const search = () => {
    setIsLoading(true);
    let google_city = "";
    let google_neighborhood = "";
    props.marker.address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        google_city = component.long_name;
      } else if (component.types.includes("administrative_area_level_3")) {
        google_neighborhood = component.long_name;
      }
    });

    let body = {
      address: props.marker.formatted_address,
      corner: isCorner,
      geo_lat:
        typeof props.marker.geometry.location.lat === "function"
          ? props.marker.geometry.location.lat()
          : props.marker.geometry.location.lat,
      geo_lng:
        typeof props.marker.geometry.location.lng === "function"
          ? props.marker.geometry.location.lng()
          : props.marker.geometry.location.lng,
      square: isStreetSquare,
      internal: false,
      renovation_year: renovationYear,
      zoning: zoning,
      city_plan: cityPlan,
      facade_length: facadeLength,
      property_type: propertyType,
      coverage_ratio: coverageRatio,
      energy_class: energyClass,
      building_ratio: buildingRatio,
      condition: condition,
      is_keep: isKeep,
      sea_view: hasViewSea,
      mountain_view: hasViewMountain,
      fireplace: hasFirePlace,
      pool: hasPool,
      parking: hasParking,
      storage: hasStorage,
      no_heating: !hasAutonomousHeating,
      no_elevator: hasNoElevator,
      owners: hasOwners,
      is_destroy: isDestroy,
      is_damaged: isDamaged,
      material: 1,
      google_city: google_city,
      google_neighborhood: google_neighborhood,
      area: area,
      floor: floor,
      finished_level: isBeingBuilt ? 1 : 5,
      construction_year: constructionYear,
      type: type,
      bedrooms: bedrooms,
    };
    if (type !== "LAND") {
      axios
        .post(`${process.env.REACT_APP_API_URL}/get_antikeimeniki`, body)
        .then((res) => {
          dispatch(priceSlice.actions.setRequest(body));
          dispatch(priceSlice.actions.setAntikeimeniki(res.data.antikeimeniki));
          dispatch(priceSlice.actions.setZonis(res.data.zonis));
          dispatch(priceSlice.actions.setMarker(props.marker));

          setIsLoading(false);
          navigate("/resultspage");
          props.onClose(true);
        })

        .catch((err) => {
          // if (err.response?.status !== 500) {
          //   if (err.response) {
          //     Object.keys(err.response.data).forEach(function (key, index) {
          //       alert.error(key + ":" + err.response.data[key]);
          //     });
          //   } else {
          //     alert.error(err.message);
          //   }
          // }

          console.log(err);
        });
    } else {
      dispatch(priceSlice.actions.setRequest(body));
      dispatch(priceSlice.actions.setAntikeimeniki(0));
      dispatch(priceSlice.actions.setZonis(0));
      dispatch(priceSlice.actions.setMarker(props.marker));

      setIsLoading(false);
      navigate("/resultspage");
      props.onClose(true);
    }
  };
  // else {
  //     let body = {
  //       name: nameOfproperty,
  //       description: description,
  //       status: status,
  //       predecessors: predecessors,
  //       completion: completion,
  //       start_date: moment(startDate).format("YYYY-MM-DD").toString(),
  //       end_date: moment(endDate).format("YYYY-MM-DD").toString(),
  //       challenges: challenges,
  //       project_id: props.project_id,
  //     };
  //     axiosService
  //       .post(`${process.env.REACT_APP_API_URL}/add_property`, body)
  //       .then((res) => {
  //         alert.success("The property was added");
  //         props.onClose(true);
  //       })

  //       .catch((err) => {
  //         if (err.response?.status !== 500) {
  //           if (err.response) {
  //             Object.keys(err.response.data).forEach(function (key, index) {
  //               alert.error(key + ":" + err.response.data[key]);
  //             });
  //           } else {
  //             alert.error(err.message);
  //           }
  //         }

  //         console.log(err);
  //       });
  //   }
  // };

  // useEffect(() => {
  //   setShow(props.show);
  // }, [props.show]);

  // const handleChangePredeccesors = (event) => {
  //   const {
  //     target: { value },
  //   } = event;

  //   let tmp = [];
  //   if (typeof value === "string") {
  //     value.split(",").forEach((val) => {
  //       tmp.push(parseInt(val));
  //     });
  //   } else {
  //     tmp = value;
  //   }
  //   setPredecessors(tmp);
  // };

  return (
    <Modal
      sx={{ zIndex: 1500 }}
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-property"}>
      <IconButton className="close-button" onClick={closeHandler}>
  <Close />
</IconButton>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          closeOnClick
          pauseOnHover
        />
        {!isLoading ? (
          <div className="property-scroll">
            <div className="column-start-center width100p ">
              {/* <div className="row-end-center width100p">
              <span className={"close"} onClick={closeHandler}>
                &times;
              </span>
            </div> */}
              <div className="row-center-center width100p address ">
                {props.marker && (
                  <span className="font-gothic-b20 bluish">
                    {props.marker.formatted_address}
                  </span>
                )}
              </div>
              <div className="row-start-start width100p">
                <div
                  style={{
                    width:
                      step === 1
                        ? "20%"
                        : step === 2
                        ? "40%"
                        : step === 3
                        ? "60%"
                        : step === 4
                        ? "80%"
                        : "100%",
                  }}
                  className="progress-line"
                />
              </div>
              <div className="row-center-center width100p">
                <span className="font-inter15 red">Βήμα {step}/5</span>
              </div>
            </div>
            {step < 4 && (
              <MapComponent
                style={{ border: "1px solid black" }}
                marker={props.marker}
                height={"150px"}
              />
            )}

            {step === 1 && (
              <div className="popup-container">
                <span className="font-inter15 margin-t2p bluish">
                  Επιλέξτε το είδος του ακινήτου
                </span>
                <div className="box-row">
                  <Box
                    className="box-select search-mob-margin-r20 pointer"
                    onClick={() => setType("RESIDENCE")}
                    sx={{
                      width: 100,
                      height: 100,
                      backgroundColor:
                        type !== "RESIDENCE" ? "white" : "#263573",
                      color: type !== "RESIDENCE" ? "#263573" : "white",
                      // opacity: type !== "RESIDENCE" ? [0.9, 0.8, 0.7] : 1,
                      // "&:hover": {
                      //   backgroundColor: "white",
                      //   opacity: 1,
                      // },
                    }}
                  >
                    <HouseIcon
                      fill="currentColor"
                      stroke="currentColor"
                      className="prop-icon"
                    />
                    <span className="font-inter15 margin-t2p">Κατοικία</span>
                  </Box>
                  <Box
                    className="box-select search-mob-margin-r20 pointer"
                    onClick={() => setType("PROFESSIONAL")}
                    sx={{
                      width: 100,
                      height: 100,
                      backgroundColor:
                        type !== "PROFESSIONAL" ? "white" : "#263573",
                      color: type !== "PROFESSIONAL" ? "#263573" : "white",
                      // opacity: type !== "PROFESSIONAL" ? [0.9, 0.8, 0.7] : 1,
                      // "&:hover": {
                      //   backgroundColor: "white",
                      //   opacity: 1,
                      // },
                    }}
                  >
                    <ProfIcon
                      fill="currentColor"
                      stroke="currentColor"
                      className="prop-icon"
                    />
                    <span className="font-inter15 margin-t2p">
                      Επαγγ. Στέγη
                    </span>
                  </Box>
                  <Box
                    className="box-select pointer"
                    onClick={() => setType("LAND")}
                    sx={{
                      width: 100,
                      height: 100,
                      backgroundColor: type !== "LAND" ? "white" : "#263573",
                      color: type !== "LAND" ? "#263573" : "white",
                      // opacity: type !== "LAND" ? [0.9, 0.8, 0.7] : 1,
                      // "&:hover": {
                      //   backgroundColor: "white",
                      //   opacity: 1,
                      // },
                    }}
                  >
                    <LandIcon
                      fill="currentColor"
                      stroke="currentColor"
                      className="prop-icon"
                    />
                    <span className="font-inter15 margin-t2p">Γη</span>
                  </Box>
                </div>
                <Box className="add-property-full-width padding-up-down pointer">
                  <FormControl fullWidth>
                    {type === "RESIDENCE" && (
                      <Select
                        required
                        MenuProps={{
                          style: { zIndex: 3000 },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={propertyType}
                        onChange={(event) =>
                          setPropertyType(event.target.value)
                        }
                      >
                        <MenuItem value={"APARTMENT"}>Διαμέρισμα</MenuItem>
                        <MenuItem value={"MAISONETTE"}>Μεζονέτα</MenuItem>
                        <MenuItem value={"DETACHED"}>Μονοκατοικία</MenuItem>
                        <MenuItem value={"BUILDING"}>Κτίριο</MenuItem>
                      </Select>
                    )}
                    {type === "PROFESSIONAL" && (
                      <Select
                        required
                        MenuProps={{
                          style: { zIndex: 3000 },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={propertyType}
                        onChange={(event) =>
                          setPropertyType(event.target.value)
                        }
                      >
                        <MenuItem value={"BUILDING"}>Κτίριο</MenuItem>
                        <MenuItem value={"ROOM"}>Αίθουσα</MenuItem>
                        <MenuItem value={"OFFICE"}>Γραφείο</MenuItem>
                        <MenuItem value={"STORE"}>Κατάστημα</MenuItem>
                        <MenuItem value={"WAREHOUSE"}>
                          Αποθηκευτικός Χώρος
                        </MenuItem>
                        <MenuItem value={"INDUSTRIAL_SPACE"}>
                          Βιομηχανικός χώρος
                        </MenuItem>
                      </Select>
                    )}
                    {type === "LAND" && (
                      <Select
                        required
                        MenuProps={{
                          style: { zIndex: 3000 },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={propertyType}
                        onChange={(event) =>
                          setPropertyType(event.target.value)
                        }
                      >
                        <MenuItem value={"PLOT"}>Οικόπεδο</MenuItem>
                        <MenuItem value={"PARCEL"}>Αγροτεμάχιο</MenuItem>
                        <MenuItem value={"PARCEL"}>Έκταση</MenuItem>
                      </Select>
                    )}
                  </FormControl>
                </Box>
              </div>
            )}
            {step === 2 && (
              <div className="popup-container">
                <span className="font-inter15 margin-t1p margin-b1p  bluish">
                  Επιλέξτε τα χαρακτηριστικά του ακινήτου
                </span>
                {type === "RESIDENCE" && (
                  <div className="box-row width80p ">
                    <Box className="add-property-half-width margin-r10">
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={areaError}
                          id="outlined-basic"
                          InputLabelProps={{ shrink: true }}
                          label="Τετραγωνικά"
                          placeholder="π.χ. 75"
                          value={area}
                          onChange={(event) => {
                            const valueAsNumber = parseInt(event.target.value, 10);
                            console.log(valueAsNumber)
                            if (!isNaN(valueAsNumber)) {
                              setArea(valueAsNumber);
                            } else {
                              setArea('');
                            }
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box className="add-property-half-width ">
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={bedroomsError}
                          id="outlined-basic"
                          InputLabelProps={{ shrink: true }}
                          label="Υπνοδωμάτια"
                          placeholder="π.χ. 4"
                          value={bedrooms}
                          onChange={(event) => {
                            const valueAsNumber = parseInt(event.target.value, 10);
                            console.log(valueAsNumber)
                            if (!isNaN(valueAsNumber)) {
                              setBedrooms(valueAsNumber);
                            } else {
                              setBedrooms('');
                            }
                          }}
                        />
                      </FormControl>
                    </Box>
                  </div>
                )}
                {type === "PROFESSIONAL" && (
                  <div className="box-row width80p ">
                    <Box className="add-property-half-width margin-r10">
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={areaError}
                          id="outlined-basic"
                          InputLabelProps={{ shrink: true }}
                          label="Εμβαδόν"
                          placeholder="π.χ. 75"
                          value={area}
                          onChange={(event) => {
                            const valueAsNumber = parseInt(event.target.value, 10);
                            console.log(valueAsNumber)
                            if (!isNaN(valueAsNumber)) {
                              setArea(valueAsNumber);
                            } else {
                              setArea('');
                            }
                          }}
                          
                        />
                      </FormControl>
                    </Box>
                    <Box className="add-property-half-width ">
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Όροφος
                        </InputLabel>
                        <Select
                          required
                          error={floorError}
                          MenuProps={{
                            style: { zIndex: 3000 },
                          }}
                          labelId="demo-multiple-checkbox-label"
                          id="demo-simple-select"
                          label="Όροφος"
                          style={{ color: floor ? "black" : "grey" }}
                          displayEmpty={true}
                          renderValue={(value) => (value ? value : "π.χ. 1ος")}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Όροφος"
                              notched
                            />
                          }
                          value={floor}
                          onChange={(event) => setFloor(event.target.value)}
                        >
                          <MenuItem value={-1}>Υπόγειο</MenuItem>
                          <MenuItem value={"0"}>Ισόγειο</MenuItem>
                          <MenuItem value={1}>1ος</MenuItem>
                          <MenuItem value={2}>2ος</MenuItem>
                          <MenuItem value={3}>3ος</MenuItem>
                          <MenuItem value={4}>4ος</MenuItem>
                          <MenuItem value={5}>5ος</MenuItem>
                          <MenuItem value={6}>6ος και άνω</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                )}
                {type === "LAND" && (
                  <div className="box-row width80p ">
                    <Box className="add-property-half-width margin-r10">
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={areaError}
                          id="outlined-basic"
                          InputLabelProps={{ shrink: true }}
                          label="Εμβαδόν σε τ.μ."
                          placeholder="π.χ. 75"
                          value={area}
                          onChange={(event) => {
                            const valueAsNumber = parseInt(event.target.value, 10);
                            console.log(valueAsNumber)
                            if (!isNaN(valueAsNumber)) {
                              setArea(valueAsNumber);
                            } else {
                              setArea('');
                            }
                          }}
                          
                        />
                      </FormControl>
                    </Box>
                    <Box className="add-property-half-width ">
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={bedroomsError}
                          id="outlined-basic"
                          InputLabelProps={{ shrink: true }}
                          label="Αριθμός όψεων"
                          placeholder="π.χ. 4"
                          value={bedrooms}
                          onChange={(event) => {
                            const valueAsNumber = parseInt(event.target.value, 10);
                            console.log(valueAsNumber)
                            if (!isNaN(valueAsNumber)) {
                              setBedrooms(valueAsNumber);
                            } else {
                              setBedrooms('');
                            }
                          }}
                          
                        />
                      </FormControl>
                    </Box>
                  </div>
                )}
                {type === "RESIDENCE" && (
                  <div className="box-row width80p">
                    <Box className="add-property-half-width margin-r10">
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Όροφος
                        </InputLabel>
                        <Select
                          required
                          error={floorError}
                          MenuProps={{
                            style: { zIndex: 3000 },
                          }}
                          labelId="demo-multiple-checkbox-label"
                          id="demo-simple-select"
                          label="Όροφος"
                          style={{ color: floor ? "black" : "grey" }}
                          displayEmpty={true}
                          renderValue={(value) => (value ? value : "π.χ. 1ος")}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Όροφος"
                              notched
                            />
                          }
                          value={floor}
                          onChange={(event) => setFloor(event.target.value)}
                        >
                          <MenuItem value={-1}>Υπόγειο</MenuItem>
                          <MenuItem value={"0"}>Ισόγειο</MenuItem>
                          <MenuItem value={1}>1ος</MenuItem>
                          <MenuItem value={2}>2ος</MenuItem>
                          <MenuItem value={3}>3ος</MenuItem>
                          <MenuItem value={4}>4ος</MenuItem>
                          <MenuItem value={5}>5ος</MenuItem>
                          <MenuItem value={6}>6ος και άνω</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box className="add-property-half-width ">
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={constructionYearError}
                          id="outlined-basic"
                          InputLabelProps={{ shrink: true }}
                          label="Έτος Κατασκευής"
                          placeholder="π.χ. 1990"
                          value={constructionYear}
                          onChange={(event) => {
                            const valueAsNumber = parseInt(event.target.value, 10);
                            console.log(valueAsNumber)
                            if (!isNaN(valueAsNumber)) {
                              setConstructionYear(valueAsNumber);
                            } else {
                              setConstructionYear('');
                            }
                          }}
                         
                        />
                      </FormControl>
                    </Box>
                  </div>
                )}
                {type === "PROFESSIONAL" && (
                  <div className="box-row width80p">
                    <Box className="add-property-half-width  margin-r10 ">
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={constructionYearError}
                          id="outlined-basic"
                          InputLabelProps={{ shrink: true }}
                          label="Έτος Κατασκευής"
                          placeholder="π.χ. 1990"
                          value={constructionYear}
                          onChange={(event) => {
                            const valueAsNumber = parseInt(event.target.value, 10);
                            console.log(valueAsNumber)
                            if (!isNaN(valueAsNumber)) {
                              setConstructionYear(valueAsNumber);
                            } else {
                              setConstructionYear('');
                            }
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box className="add-property-half-width ">
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={renovationYearError}
                          id="outlined-basic"
                          InputLabelProps={{ shrink: true }}
                          label="Έτος Ανακαίνησης"
                          placeholder="π.χ. 2000"
                          value={renovationYear}
                          onChange={(event) => {
                            const valueAsNumber = parseInt(event.target.value, 10);
                            console.log(valueAsNumber)
                            if (!isNaN(valueAsNumber)) {
                              setRenovationYear(valueAsNumber);
                            } else {
                              setRenovationYear('');
                            }
                          }}
                          
                        />
                      </FormControl>
                    </Box>
                  </div>
                )}
                {type === "LAND" && (
                  <div className="box-row width80p">
                    <Box className="add-property-half-width   margin-r10">
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={coverageRatioError}
                          id="outlined-basic"
                          InputLabelProps={{ shrink: true }}
                          label="Συντελεστής Κάληψης"
                          placeholder="π.χ. 0.2"
                          value={coverageRatio}
                          onChange={(event) => {
                            if (
                              event.target.value[
                                event.target.value.length - 1
                              ] === "."
                            ) {
                              setCoverageRatio(event.target.value);
                            } else if (event.target.value !== "") {
                              setCoverageRatio(parseFloat(event.target.value));
                            } else {
                              setCoverageRatio('');
                            }
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box className="add-property-half-width ">
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={buildingRatioError}
                          id="outlined-basic"
                          InputLabelProps={{ shrink: true }}
                          label="Συντελεστής Δόμησης"
                          placeholder="π.χ. 0.2"
                          value={buildingRatio}
                          onChange={(event) => {
                            if (
                              event.target.value[
                                event.target.value.length - 1
                              ] === "."
                            ) {
                              setBuildingRatio(event.target.value);
                            } else if (event.target.value !== "") {
                              setBuildingRatio(parseFloat(event.target.value));
                            } else {
                              setBuildingRatio('');
                            }
                          }}
                        />
                      </FormControl>
                    </Box>
                  </div>
                )}
                {type === "PROFESSIONAL" && (
                  <div className="box-start width80p margin-l6">
                    <Box className="add-property-half-width margin-r10 start-self">
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Ενεργειακή Κλάση
                        </InputLabel>
                        <Select
                          error={energyClassError}
                          required
                          MenuProps={{
                            style: { zIndex: 3000 },
                          }}
                          labelId="demo-multiple-checkbox-label"
                          id="demo-simple-select"
                          label="Ενεργειακή Κλάση"
                          style={{ color: energyClass ? "black" : "grey" }}
                          displayEmpty={true}
                          renderValue={(value) => (value ? value : "π.χ. A+")}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Ενεργειακή Κλάση"
                              notched
                            />
                          }
                          value={energyClass}
                          onChange={(event) =>
                            setEnergyClass(event.target.value)
                          }
                        >
                          <MenuItem value={"A_PLUS"}>A+</MenuItem>
                          <MenuItem value={"A"}>A</MenuItem>
                          <MenuItem value={"B_PLUS"}>B+</MenuItem>
                          <MenuItem value={"B"}>B</MenuItem>
                          <MenuItem value={"C"}>Γ</MenuItem>
                          <MenuItem value={"D"}>Δ</MenuItem>
                          <MenuItem value={"E"}>Ε</MenuItem>
                          <MenuItem value={"Z"}>Ζ</MenuItem>
                          <MenuItem value={"H"}>Η</MenuItem>
                          <MenuItem value={"PENDING"}>Υπό Έκδοση</MenuItem>
                          <MenuItem value={"EXCLUDED"}>Εξαιρείται</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                )}
                {type === "LAND" && (
                  <div className="box-start width80p margin-l6">
                    <Box className="add-property-half-width start-self ">
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={facadeLengthError}
                          id="outlined-basic"
                          InputLabelProps={{ shrink: true }}
                          label="Πρόσοψη (σε μέτρα)"
                          placeholder="π.χ. 10"
                          value={facadeLength}
                          onChange={(event) => {
                            const valueAsNumber = parseInt(event.target.value, 10);
                            console.log(valueAsNumber)
                            if (!isNaN(valueAsNumber)) {
                              setFacadeLength(valueAsNumber);
                            } else {
                              setFacadeLength('');
                            }
                          }}
                         
                        />
                      </FormControl>
                    </Box>
                  </div>
                )}
              </div>
            )}
            {step === 3 && (
              <>
                {type === "RESIDENCE" && (
                  <div className="popup-container">
                    <span className="font-inter15 margin-t2p bluish">
                      Επιλέξτε επιπλέον χαρακτηριστικά του ακινήτου
                    </span>
                    <div className="box-row width80p flex-wrap ">
                      <Button
                        className="margin-r10"
                        variant={hasViewSea ? "contained" : "outlined"}
                        onClick={() => setHasViewSea(!hasViewSea)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Θέα σε Θάλασσα
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={hasViewMountain ? "contained" : "outlined"}
                        onClick={() => setHasViewMountain(!hasViewMountain)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Θέα σε Βουνό
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={hasViewScene ? "contained" : "outlined"}
                        onClick={() => setHasViewScene(!hasViewScene)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Θέα σε Μνημείο
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={hasStorage ? "contained" : "outlined"}
                        onClick={() => setHasStorage(!hasStorage)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Αποθήκη
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={hasFirePlace ? "contained" : "outlined"}
                        onClick={() => setHasFirePlace(!hasFirePlace)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Τζάκι
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={hasPool ? "contained" : "outlined"}
                        onClick={() => setHasPool(!hasPool)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Πισίνα
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={hasParking ? "contained" : "outlined"}
                        onClick={() => setHasParking(!hasParking)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Πάρκινγκ
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={
                          hasAutonomousHeating ? "contained" : "outlined"
                        }
                        onClick={() =>
                          setHasAutonomousHeating(!hasAutonomousHeating)
                        }
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Αυτόνομη Θέρμανση
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={isCorner ? "contained" : "outlined"}
                        onClick={() => setIsCorner(!isCorner)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Γωνιακό
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={isKeep ? "contained" : "outlined"}
                        onClick={() => setIsKeep(!isKeep)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Διατηρητέο
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={isBeingBuilt ? "contained" : "outlined"}
                        onClick={() => setIsBeingBuilt(!isBeingBuilt)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Υπό Κατασκευή
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={isStreetSquare ? "contained" : "outlined"}
                        onClick={() => setIsStreetSquare(!isStreetSquare)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Σε Δρόμο με πλατεία
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={hasNoElevator ? "contained" : "outlined"}
                        onClick={() => setHasNoElevator(!hasNoElevator)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Χωρίς ασανσέρ
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={isDamaged ? "contained" : "outlined"}
                        onClick={() => setIsDamaged(!isDamaged)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Έχει Ζημιά
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={isDestroy ? "contained" : "outlined"}
                        onClick={() => setIsDestroy(!isDestroy)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Απαλλοτριωτέο
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={isInternal ? "contained" : "outlined"}
                        onClick={() => setInternal(!isInternal)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Εσωτερικό
                      </Button>
                      <Button
                        className="margin-r10"
                        variant={hasOwners ? "contained" : "outlined"}
                        onClick={() => setHasOwners(!hasOwners)}
                        sx={{
                          textTransform: "none",
                          margin: "0px 10px 10px 0px",
                        }}
                      >
                        <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                        Έχει πάνω από 1 ιδιοκτήτη
                      </Button>
                    </div>
                  </div>
                )}
                {type === "PROFESSIONAL" && (
                  <div className="box-space-start width80p">
                    <FormControl>
                      <span className="font-inter-b16 font-yellow">
                        {" "}
                        Κατάσταση
                      </span>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={condition}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="GOOD"
                          onClick={() => setCondition("GOOD")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Καλή Κατάσταση"
                        />
                        <FormControlLabel
                          value="NOT_FINISHED"
                          onClick={() => setCondition("NOT_FINISHED")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Ημιτελές"
                        />
                        <FormControlLabel
                          value="BEING_BUILT"
                          onClick={() => setCondition("BEING_BUILT")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Υπό κατασκευή"
                        />
                        <FormControlLabel
                          value="IN_NEED_REFURBISHMENT"
                          onClick={() => setCondition("IN_NEED_REFURBISHMENT")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Χρήζει ανακαίνισης"
                        />
                        <FormControlLabel
                          value="REFURBISHED"
                          onClick={() => setCondition("REFURBISHED")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Ανακαινισμένο"
                        />
                        <FormControlLabel
                          value="NEW"
                          onClick={() => setCondition("NEW")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Νεόδμητο"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <span className="font-inter-b16 font-yellow"> Ζώνη</span>

                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={zoning}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="RESIDENCIAL"
                          onClick={() => setZoning("RESIDENCIAL")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Οικιστική"
                        />
                        <FormControlLabel
                          value="COMMERCIAL"
                          onClick={() => setZoning("COMMERCIAL")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Εμπορική"
                        />
                        <FormControlLabel
                          value="INDUSTRIAL"
                          onClick={() => setZoning("INDUSTRIAL")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Βιομηχανική"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
                {type === "LAND" && (
                  <div className="box-space-start width80p">
                    <FormControl>
                      <span className="font-inter-b16 font-yellow">
                        {" "}
                        Σχέδιο Πόλης
                      </span>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={cityPlan}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="INSIDE"
                          onClick={() => setCityPlan("INSIDE")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Εντός σχεδίου"
                        />
                        <FormControlLabel
                          value="OUTSIDE"
                          onClick={() => setCityPlan("OUTSIDE")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Εκτός Σχεδίου"
                        />
                        <FormControlLabel
                          value="PENDING"
                          onClick={() => setCityPlan("PENDING")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Υπό ένταξη"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <span className="font-inter-b16 font-yellow"> Ζώνη</span>

                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={zoning}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="RESIDENCIAL"
                          onClick={() => setZoning("RESIDENCIAL")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Οικιστική"
                        />
                        <FormControlLabel
                          value="COMMERCIAL"
                          onClick={() => setZoning("COMMERCIAL")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Εμπορική"
                        />
                        <FormControlLabel
                          value="INDUSTRIAL"
                          onClick={() => setZoning("INDUSTRIAL")}
                          className="font-inter15 bluish"
                          control={<Radio />}
                          label="Βιομηχανική"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
              </>
            )}
            {step === 4 && (
              <div className="popup-container-radio">
                <span className="font-inter-b16">Μια ερώτητηση ακόμα!</span>
                <span className="font-inter15 margin-t2p bluish">
                  Τι σχέση έχεις με το ακίνητο που ψάχνεις;
                </span>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={contactType}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="OWNER"
                      onClick={() => setContactType("OWNER")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Είμαι ιδιοκτήτης"
                    />
                    <FormControlLabel
                      value="RENTER"
                      onClick={() => setContactType("RENTER")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Είμαι ενοικιαστής"
                    />
                    <FormControlLabel
                      value="CLIENT_SALE"
                      onClick={() => setContactType("CLIENT_SALE")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Ενδιαφέρομαι να αγοράσω"
                    />
                    <FormControlLabel
                      value="CLIENT_RENT"
                      onClick={() => setContactType("CLIENT_RENT")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Εδιαφέρομαι να νοικιάσω"
                    />
                    <FormControlLabel
                      value="REALTOR"
                      onClick={() => setContactType("REALTOR")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Είμαι σύμβουλος ακινήτων (μεσίτης)"
                    />
                    <FormControlLabel
                      value="CURIOUS"
                      onClick={() => setContactType("CURIOUS")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Από απλή περιέργεια"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {step === 5 && (
              <div className="popup-container">
                <span className="font-inter-b16">Έμεινε κάτι ακόμα!</span>
                <span className="font-inter15 margin-t1p margin-b1p bluish">
                  Παρακαλώ συμπληρώστε τα στοιχεία σας
                </span>
                <div className="box-row width80p ">
                  <Box className="add-property-half-width margin-r10">
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        label="Όνομα"
                        // placeholder="π.χ. 75"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                      />
                    </FormControl>
                  </Box>
                  <Box className="add-property-half-width ">
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        label="Επώνυμο"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                      />
                    </FormControl>
                  </Box>
                </div>
                <div className="box-row width80p">
                  <Box className="add-property-half-width margin-r10">
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        label="Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </FormControl>
                  </Box>
                  <Box className="add-property-half-width ">
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        label="Τηλέφωνο"
                        value={phoneNumber}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                      />
                    </FormControl>
                  </Box>
                </div>
                <div className="box-row width80p ">
                  <Checkbox
                    checked={accepted}
                    onChange={() => setAccepted(!accepted)}
                  />
                  <span className="font-inter15 margin-t2p bluish">
                    {" "}
                    Διάβασα και συμφωνώ με την{" "}
                    <span className="font-inter15 red">
                      Πολιτική Απορρήτου
                    </span>{" "}
                    και τους{" "}
                    <span className="font-inter15 red">
                      όρους Προστασίας Προσσωπικών Δεδομένων
                    </span>{" "}
                  </span>
                </div>
              </div>
            )}

            <div className="row-center-center margin-b10">
              {step > 1 && (
                <button
                  className="footer-button-left"
                  onClick={() => setStep(step - 1)}
                >
                  {" "}
                  Πίσω
                </button>
              )}
              <button
                className="footer-button-right"
                onClick={() => {
                  if (!isCurrentStepValid()) {
                    toast.error("Please fill all required fields");
                    return;
                  }
                  if (step < 5) {
                    if (step === 3 && !showForm) {
                      send();
                    } else {
                      setStep(step + 1);
                    }
                  } else {
                    send();
                  }
                }}
              >
                {" "}
                {step === 5 ? "Δες τα αποτελέσματα" : " Επόμενο Βήμα"}
              </button>
            </div>
          </div>
        ) : (
          <div className="property-scroll" style={{ backgroundColor: "white" }}>
            <span
              className="font-gothic-b22 font-blue margin-t2p "
              style={{ marginTop: 40, textAlign: "center" }}
            >
              {currentMessage}
            </span>
            <img
              style={{ height: 300, width: 300, alignSelf: "center" }}
              src={require("../../assets/N white.gif")}
              alt="loading gif"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SearchProperty;
