import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const AnalysisChart = ({ data, transactionType }) => {
  const [chartData, setChartData] = useState([]);
  const [percentageChange, setPercentageChange] = useState(null);

  useEffect(() => {
    if (data.length > 0) {
      const newChartData = [["Date", "Average Price"]];
      data.forEach(({ date, average_price }) => {
        newChartData.push([date, average_price]);
      });

      setChartData(newChartData);

      // Calculate percentage change if applicable
      if (data.length > 1) {
        const initialPrice = data[0].average_price;
        const finalPrice = data[data.length - 1].average_price;
        const percentageChangeValue =
          ((finalPrice - initialPrice) / initialPrice) * 100;
        setPercentageChange(percentageChangeValue);
      }
    }
  }, [data]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {data.length > 0 && chartData.length > 1 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Chart
            chartType="AreaChart"
            width="100%"
            height="400px"
            data={chartData}
            options={{
              hAxis: { format: "MMM 'yy" },
              vAxis: { viewWindow: { min: 0 } },
              legend: { position: "none" },
              series: {
                0: {
                  color: transactionType === "RENT" ? "#e8bb05" : "#4285F4",
                },
              },
            }}
          />
          {percentageChange !== null && (
            <span className="font-inter16 font-blue">
              {"The property value has" +
                (percentageChange > 0 ? " increased" : " decreased") +
                " by approximately "}
              <span className="font-inter-b16 font-blue">
                {Math.abs(percentageChange).toFixed(2)}%
              </span>
              {" during this period."}
            </span>
          )}
        </div>
      ) : (
        <span className="font-inter16 font-blue">
          Sorry, we are currently unable to display the chart for this search.
        </span>
      )}
    </div>
  );
};

export default AnalysisChart;
