import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
const BarChart = (props) => {
  const { min, slice, max, stat, color } = props;
  const bounds = [min, ...(slice || []), max];
  const trimmedStat = stat || []; // Assuming stat array length is correct
  let totalProperties = 0; // Initialize total properties count
  const labels = [];
  const akinitaData = [];
  const percentageData = [];

  for (let i = 0; i < trimmedStat.length; i++) {
    const lower = bounds[i];
    const upper = bounds[i + 1];

    // Calculate the integer average of the range
    const average = Math.round((lower + upper) / 2);
    const label = average; // Change labels to numeric values
    const propertiesCount = trimmedStat[i];

    labels.push(label);
    akinitaData.push(propertiesCount);
    totalProperties += propertiesCount; // Update total properties count
  }

  // Calculate percentages
  for (let i = 0; i < akinitaData.length; i++) {
    const percentage = ((akinitaData[i] / totalProperties) * 100).toFixed(2);
    percentageData.push(percentage);
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Ακίνητα",
        backgroundColor: color,
        data: akinitaData,
      },
      {
        label: "Percentage",
        hidden: true, // Add this line

        data: percentageData,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options = {
    scales: {
      xAxis: [
        {
          type: "linear", // Specify the X-axis as "linear"
          stacked: true,
        },
      ],
      yAxis: [
        {
          stacked: true,
          beginAtZero: true,
          suggestedMax: totalProperties,
        },
      ],
    },
    plugins: {
      legend: {
        display: false, // Remove the legend
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.7)", // This is a semi-transparent black.
        callbacks: {
          label: (tooltipItem) => {
            const akinitaValue = akinitaData[tooltipItem.dataIndex];
            const percentageValue = percentageData[tooltipItem.dataIndex];
            return [`Ακίνητα: ${akinitaValue}`, ` ${percentageValue}%`];
          },
        },
      },
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
