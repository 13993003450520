import logo from "./logo.svg";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Login from "./screens/login/Login";
import Main from "./screens/main/Main";
import NeighborhoodAnalysis from "./screens/neighborhood-analysis/NeighborhoodAnalysis";
import ResultsPage from "./screens/results-page/ResultsPage";
import ResultsNeighborhood from "./screens/results-neighborhood/ResultsNeighborhood";
import { LoadScript } from "@react-google-maps/api";
import Footer from "./components/footer/Footer";

export default function AppWrapper() {
  return (
    <Provider store={store}>
      <LoadScript
        className="width100p"
        language="el"
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={["places"]}
      >
        <App />
      </LoadScript>
    </Provider>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Main />} />
        <Route
          path="/neighborhoodanalysis"
          element={<NeighborhoodAnalysis />}
        />
        <Route path="/resultspage" element={<ResultsPage />} />
        <Route path="/resultsneighborhood" element={<ResultsNeighborhood />} />
      </Routes>
      <Footer />
    </Router>
  );
}
