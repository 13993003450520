import React from "react";
import "./DropDownServices.css";
import { Link } from "react-router-dom";

function DropDownServices() {
  return (
    <div className="drop-service-back">
      <div className="row-space-between-start width100p ">
        <div className="column-start-start width100p">
          <span className="font-inter-b20 red margin-b20" >ΑΓΟΡΑΣΤΕΣ-ΕΝΟΙΚΙΑΣΤΕΣ</span>
          <Link to="https://newdeal.gr/el/buy/residential">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15559.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Αγόρασε ακίνητο</span>
          </div>
          </Link>

          <Link to="https://newdeal.gr/el/rent/residential">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15558.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Ενοικίασε ακίνητο</span>
          </div>
          </Link>

        </div>
        <div className="column-start-start width100p">
          <span className="font-inter-b20 red margin-b20">ΙΔΙΟΚΤΗΤΕΣ</span>

          <Link to="https://newdeal.gr/el/add-listing">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15571.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Ανάθεση</span>
          </div>
          </Link>

          <Link to="htps://newdeal.gr/el/request">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15570.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Ζήτηση</span>
        </div>
        </Link>
        </div>
      </div>

      <span className="font-inter-b20 red margin-b20">ΓΙΑ ΕΠΑΓΓΕΛΜΑΤΙΕΣ</span>

      <div className="row-space-between-start width100p">
        <div className="column-start-start width100p">
        
        <Link to="https://realty.newdeal.gr/kariera/">
        <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15560.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Γίνε συνεργάτης μας</span>
          </div>
          </Link>


          <Link to="https://realty.newdeal.gr/franchise/">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15561.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Άνοιξε το δικό σου γραφείο</span>
          </div>
          </Link>


          <Link to="https://realty.newdeal.gr/virtualoffice">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15562.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Δούλεψε με το virtual office</span>
          </div>
          </Link>
        </div>

        <div className="column-start-start width100p">

        <Link to="https://realty.newdeal.gr/virtualoffice/ypostiriksi__ekpaideusi_real_estate/">
        <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15569.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Υποστήριξη & Εκπαίδευση</span>
          </div>
          </Link>

          <Link to="https://realty.newdeal.gr/virtualoffice/marketing/">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15568.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Marketing</span>
          </div>
          </Link>

          <Link to="https://realty.newdeal.gr/virtualoffice/systaseis">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15567.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Δίκτυο Συνεργατών</span>
          </div>
          </Link>
        </div>
      </div>

      <div className="row-space-between-start width100p">
        <div className="column-start-start width100p">
          <span className="font-inter-b20 red margin-b20">ΕΚΠΑΙΔΕΥΣΗ ΣΤΟ REAL ESTATE</span>

          <Link to="https://rean.gr">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15563.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Συνεργασία με ΕΚΠΑ</span>
          </div>
          </Link>
          <Link to="https://academy.newdeal.gr">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15564.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Ακαδημία REAL ESTATE</span>
          </div>
          </Link>
        </div>

        <div className="column-start-start width100p">
          <span className="font-inter-b20 red margin-b20">ΠΟΙΟΙ ΕΙΜΑΣΤΕ</span>

          <Link to="https://realty.newdeal.gr">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15566.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Νέα στο Real Estate</span>
          </div>
          </Link>
          <Link to="https://realty.newdeal.gr/news/one-stop-shop">
          <div className="row-start-center margin-b20">
          <img
            style={{marginRight:10}}
                src={require("../../assets/Group 15565.png")}
                alt="developing vector"
              />
          <span className="font-inter-m18 bright-blue ">Τι σου προσφέρουμε</span>
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DropDownServices;
