function percentageDifference(value1, value2) {
  if (value2 === 0) {
    throw new Error(
      "The denominator (value2) is zero, percentage is undefined."
    );
  }

  const percentage = (value1 / value2) * 100 - 100;

  // Check if value1 is greater than value2 and percentage is positive
  const sign = value1 > value2 && percentage > 0 ? "+" : "";

  return ` ${sign}${percentage.toFixed(2)}%`;
}

export default percentageDifference;
