import "./Main.css";
import React from "react";
import NavBar from "../../components/nav-bar/NavBar";
import Footer from "../../components/footer/Footer";
import PlaceComponent from "../../components/location-input/LocationInput";
import SearchProperty from "../../components/search-property/SearchProperty";
import SearchArea from "../../components/search-area/SearchArea";
import { useState, useEffect } from "react";
import FrequentQuestions from "../../components/frequent-questions/FrequentQuestions";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Neighborhoods from "../../components/neighborhoods/Neighborhoods";
import ContactPopup from "../../components/contact-popup/ContactPopup";

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;

  const atStart = currentSlide === 0;
  const atEnd = currentSlide === totalItems - slidesToShow;
  return (
    <div className="carousel-button-group arrows">
      <button
        style={{
          opacity: atStart ? 0.5 : 1,
          border: "none",
          padding: 0,
        }}
        className={currentSlide === 0 ? "disable" : ""}
        onClick={() => !atStart && previous()}
      >
        <img
          className="arrow-left pointer"
          src={require("../../assets/arrow-left.png")}
          alt="developing vector"
        />
      </button>
      <button
        style={{
          opacity: atEnd ? 0.5 : 1,
          border: "none",
          padding: 0,
        }}
        onClick={() => {
          !atEnd && next();
        }}
      >
        <img
          className="arrow-right pointer"
          src={require("../../assets/arrow-right.png")}
          alt="developing vector"
        />
      </button>
    </div>
  );
};

function Main() {
  const [searchProperty, setSearchProperty] = React.useState(true);
  const [searchArea, setSearchArea] = React.useState(false);
  const [showContact, setShowContact] = useState(false);
  const [address, setAddress] = React.useState("");
  const [marker, setMarker] = React.useState();
  const [showCalculate, setShowCalculate] = React.useState(false);
  const [showCalculateArea, setShowCalculateArea] = React.useState(false);
  const [axies, setAxies] = React.useState();
  const [isVisible, setIsVisible] = useState(false);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const checkScroll = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // Attach the event listener
    window.addEventListener("scroll", checkScroll);

    // Cleanup
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  const clickProperty = () => {
    setSearchArea(false);
    setSearchProperty(true);
  };

  const clickArea = () => {
    setSearchArea(true);
    setSearchProperty(false);
  };

  const calculate = (value) => {
    setAddress(value.formatted_address);
    setMarker(value);
    if (searchProperty) {
      setShowCalculate(true);
    } else {
      setShowCalculateArea(true);
    }
  };

  return (
    <div className="main">
      <NavBar />
      <ContactPopup show={showContact} onClose={() => setShowContact(false)} />
      <SearchProperty
        show={showCalculate}
        onClose={() => setShowCalculate(false)}
        marker={marker}
      />
      <SearchArea
        show={showCalculateArea}
        onClose={() => setShowCalculateArea(false)}
        marker={marker}
      />

      <div className="column-start-center">
        {window.innerWidth >= 1000 ? (
          <div className="main-blue-back">
            <span className="font-gothic-b55 white text-center margin-b1p">
              Μάθε την αξία του ακινήτου σου
            </span>
            <span className="font-inter20 white text-center ">
              Βρες την{" "}
              <span className="font-inter-b20 white underline margin-t2p">
                αντικειμενική αξία
              </span>{" "}
              του,{" "}
              <span className="font-inter-b20 white underline margin-t2p">
                τις τάσεις στο real estate
              </span>{" "}
              καθώς και την τιμή
              <br />
              <span className="font-inter-b20 white  margin-t2p">
                ενοικίασης & πώλησης
              </span>{" "}
              ενός ακινήτου σύμφωνα με την έρευνα αγοράς
            </span>
          </div>
        ) : (
          <div className="mob-main-blue-back">
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <span className="font-gothic-b55 white text-center margin-b1p">
                Μάθε την αξία του ακινήτου σου
              </span>
              <br />
              <span className="font-inter20 white text-center ">
                Βρες την{" "}
                <span className="font-inter-b20 white underline margin-t2p">
                  αντικειμενική αξία
                </span>{" "}
                του,{" "}
                <span className="font-inter-b20 white underline margin-t2p">
                  τις τάσεις στο real estate
                </span>{" "}
                καθώς και την τιμή{" "}
                <span className="font-inter-b20 white  margin-t2p">
                  ενοικίασης & πώλησης
                </span>{" "}
                ενός ακινήτου σύμφωνα με την έρευνα αγοράς
              </span>
            </div>
          </div>
        )}
        {window.innerWidth >= 1000 ? (
          <div className="main-background-img">
            <div className="column-start-start margin-t40">
              <div className="main-button-row">
                <button
                  className={
                    searchProperty
                      ? "button-main-left margin-r20 font-gothic-b14"
                      : "button-main-right margin-r20 font-gothic-b14"
                  }
                  onClick={clickProperty}
                >
                  Αναζήτηση Ακινήτου
                </button>
                <button
                  className={
                    searchArea
                      ? "button-main-left font-gothic-b14"
                      : "button-main-right font-gothic-b14"
                  }
                  onClick={clickArea}
                >
                  Αναζήτηση Περιοχής
                </button>
              </div>

              <PlaceComponent
                searchProperty={searchProperty}
                searchArea={searchArea}
                calculate={calculate}
              />
              <span className="font-inter-b20 white">Beta Version</span>
            </div>
          </div>
        ) : (
          <div className="mob-main-background-img">
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <div className="column-start-start margin-t40">
                <div className="main-button-row">
                  <button
                    className={
                      searchProperty
                        ? "button-main-left margin-r20 font-gothic-b14"
                        : "button-main-right margin-r20 font-gothic-b14"
                    }
                    onClick={clickProperty}
                  >
                    Αναζήτηση Ακινήτου
                  </button>
                  <button
                    className={
                      searchArea
                        ? "button-main-left font-gothic-b14"
                        : "button-main-right font-gothic-b14"
                    }
                    onClick={clickArea}
                  >
                    Αναζήτηση Περιοχής
                  </button>
                </div>

                <PlaceComponent calculate={calculate} />
                <span className="font-inter-b20 white">Beta Version</span>
              </div>
            </div>
          </div>
        )}
        {/* {window.innerWidth >= 1000 && ( */}
        <div className="video-back">
          <video
            className="video-background"
            src={require("../../assets/background.mp4")}
            autoPlay
            playsInline
            muted
          />
        </div>
        {/* )} */}

        {window.innerWidth >= 1000 ? (
          <div className="main-column-pad">
            <span className="font-gothic-b40 red margin-t2p">
              Σε ποιους απευθύνεται το Price my Property;
            </span>
            <span className="font-inter20 font-light-grey margin-t2p">
              Χρησιμοποίησε το εύχρηστο εργαλείο μας για να βρεις με ένα κλικ
              την αντικειμενική αξία του ακινήτου σου.
            </span>

            <span className="font-inter20 font-light-grey margin-t2p">
              Είτε είσαι ιδιώτης είτε επαγγελματίας, το 'Price my Property' σου
              προσφέρει τη δυνατότητα να παρακολουθείς τις τάσεις και τις τιμές
              της αγοράς όπως διαμορφώνονται τους τελευταίους μήνες και
              επικαιροποιείται διαρκώς.
            </span>

            <button
              className="button-red font-gothic-b16 white margin-t2p pointer center-self"
              onClick={scrollToTop}
            >
              Δοκίμασε το
            </button>
          </div>
        ) : (
          <div className="main-column-pad">
            {/* <img
              className="margin-t10"
              style={{ width: "90%" }}
              src={require("../../assets/logo.png")}
              alt="developing vector"
            /> */}
            <span className="font-gothic-b40 red margin-t2p text-center">
              Σε ποιους απευθύνεται το Price my Property;
            </span>
            <span className="font-inter16 font-light-grey margin-t5p">
              Χρησιμοποίησε το εύχρηστο εργαλείο μας για να βρεις με ένα κλικ
              την αντικειμενική αξία του ακινήτου σου.
            </span>

            <span className="font-inter16 font-light-grey margin-t5p">
              Είτε είσαι ιδιώτης είτε επαγγελματίας, το 'Price my Property' σου
              προσφέρει τη δυνατότητα να παρακολουθείς τις τάσεις και τις τιμές
              της αγοράς όπως διαμορφώνονται τους τελευταίους μήνες και
              επικαιροποιείται διαρκώς.
            </span>
            <div className="center width100p">
              <button
                className="button-red font-gothic-b16 white margin-t5p pointer "
                onClick={scrollToTop}
              >
                Δοκίμασε το
              </button>
            </div>
          </div>
        )}

        {window.innerWidth >= 1000 ? (
          <div className="main-pale-back margin-t5p">
            <span className="font-gothic-b40 red margin-t2p  margin-b2p">
              Ξεκίνα με 3 απλά βήματα
            </span>

            <div className="row-space-between-start width100p">
              <div className="step-left">
                <span className="font-inter-b24 font-yellow margin-b10">
                  BHMA 1
                </span>
                <span className="font-inter-b20 font-blue">
                  Εɩσαγάγετε τα στοɩχεία του ακɩνήτου σας ή τα κρɩτήρɩα
                  αναζήτησης
                </span>
                <span className="font-inter20 font-light-grey margin-t2p">
                  Με μόνο λίγες βασικές πληροφορίες για το ακίνητό σας—όπως
                  τύπος, τοποθεσία, μέγεθος, και κατάσταση—θα έχετε την ευκαιρία
                  να κατανοήσετε την αντικειμενική του αξία. Αν αναζητάτε κάτι
                  συγκεκριμένο, καθορίστε τα κριτήρια και την περιοχή που σας
                  ενδιαφέρει, και ανακαλύψτε τις τάσεις και τις τιμές της αγοράς
                  σε πραγματικό χρόνο.
                </span>
                <img
                  className="step2-img"
                  src={require("../../assets/step2.png")}
                  alt="developing vector"
                />

                <span className="font-inter-b24 font-yellow margin-b10">
                  BHMA 3
                </span>
                <span className="font-inter-b20 font-blue">
                  Λάβετε την εκτίμηση της αξίας του ακɩνήτου σας ή το εύρος
                  τɩμών της αναζήτησης σας
                </span>
                <span className="font-inter20 font-light-grey margin-t2p">
                  Με την ολοκλήρωση των προηγούμενων βημάτων, θα λάβετε αμέσως
                  μια λεπτομερή αναφορά που περιλαμβάνει την εκτιμώμενη αξία του
                  ακινήτου σας ή το εύρος τιμών που σας ενδιαφέρει. Το εργαλείο
                  μας καθιστά την κατανόηση της αγοραστικής αξίας γρήγορη και
                  εύκολη, αναδεικνύοντας τις τελευταίες τάσεις και συνθήκες της
                  αγοράς, ιδανικό τόσο για ιδιοκτήτες όσο και για επαγγελματίες
                  του real estate.
                </span>
              </div>
              <div className="line-column">
                <div className="line1"></div>
                <div className="ball"></div>
                <div className="line2"></div>
                <div className="ball"></div>
                <div className="line2"></div>
                <div className="ball"></div>
                <div className="line2"></div>
              </div>

              <div className="step-right">
                <img
                  className="step1-img"
                  src={require("../../assets/step1.png")}
                  alt="developing vector"
                />
                <span className="font-inter-b24 font-yellow margin-b10">
                  BHMA 2
                </span>
                <span className="font-inter-b20 font-blue">
                  Ανάλυση δεδομένων τοπɩκής αγοράς
                </span>
                <span className="font-inter20 font-light-grey margin-t2p">
                  Το εργαλείο μας αξιοποιεί τις πληροφορίες που παρέχετε, για να
                  κάνει μια άμεση ανάλυση της τοπικής αγοράς. Είτε είστε
                  ενδιαφερόμενοι για την αξία του δικού σας ακινήτου είτε για
                  τις τιμές στην περιοχή που σας ενδιαφέρει, θα λάβετε μια
                  εκτίμηση ή ένα εύρος τιμών, βασισμένο στις τελευταίες τάσεις
                  και συνθήκες της αγοράς
                </span>

                <img
                  className="step3-img"
                  src={require("../../assets/step3.png")}
                  alt="developing vector"
                />
              </div>
            </div>

            <button
              className="button-red font-gothic-b16 white margin-t2p pointer"
              onClick={scrollToTop}
            >
              Ξεκίνα ΔΩΡΕΑΝ
            </button>
          </div>
        ) : (
          <div className="mob-main-pale-back margin-t10p">
            <span className="font-gothic-b40 red margin-t2p  margin-b2p">
              Ξεκίνα με 3 απλά βήματα
            </span>

            <img
              style={{ width: 200, marginBottom: 40, marginTop: 40 }}
              src={require("../../assets/step1.png")}
              alt="developing vector"
            />

            <span className="font-inter-b24 font-yellow margin-b10 align-self-start">
              BHMA 1
            </span>

            <span className="font-inter-b20 font-blue text-left">
              Εɩσαγάγετε τα στοɩχεία του ακɩνήτου σας ή τα κρɩτήρɩα αναζήτησης
            </span>
            <span className="font-inter16 font-light-grey margin-t2p text-left">
              Με μόνο λίγες βασικές πληροφορίες για το ακίνητό σας—όπως τύπος,
              τοποθεσία, μέγεθος, και κατάσταση—θα έχετε την ευκαιρία να
              κατανοήσετε την αντικειμενική του αξία. Αν αναζητάτε κάτι
              συγκεκριμένο, καθορίστε τα κριτήρια και την περιοχή που σας
              ενδιαφέρει, και ανακαλύψτε τις τάσεις και τις τιμές της αγοράς σε
              πραγματικό χρόνο.
            </span>
            <img
              style={{ width: 200, marginBottom: 40, marginTop: 40 }}
              src={require("../../assets/step2.png")}
              alt="developing vector"
            />

            <span className="font-inter-b24 font-yellow margin-b10 align-self-start">
              BHMA 2
            </span>
            <span className="font-inter-b20 font-blue align-self-start">
              Ανάλυση δεδομένων τοπɩκής αγοράς
            </span>
            <span className="font-inter16 font-light-grey margin-t2p text-left">
              Το εργαλείο μας αξιοποιεί τις πληροφορίες που παρέχετε, για να
              κάνει μια άμεση ανάλυση της τοπικής αγοράς. Είτε είστε
              ενδιαφερόμενοι για την αξία του δικού σας ακινήτου είτε για τις
              τιμές στην περιοχή που σας ενδιαφέρει, θα λάβετε μια εκτίμηση ή
              ένα εύρος τιμών, βασισμένο στις τελευταίες τάσεις και συνθήκες της
              αγοράς
            </span>

            <img
              style={{ width: 300, marginBottom: 40, marginTop: 40 }}
              src={require("../../assets/step3.png")}
              alt="developing vector"
            />

            <span className="font-inter-b24 font-yellow margin-b10 align-self-start">
              BHMA 3
            </span>
            <span className="font-inter-b20 font-blue align-self-start">
              Λάβετε την εκτίμηση της αξίας του ακɩνήτου σας ή το εύρος τɩμών
              της αναζήτησης σας
            </span>
            <span className="font-inter16 font-light-grey margin-t2p text-left">
              Με την ολοκλήρωση των προηγούμενων βημάτων, θα λάβετε αμέσως μια
              λεπτομερή αναφορά που περιλαμβάνει την εκτιμώμενη αξία του
              ακινήτου σας ή το εύρος τιμών που σας ενδιαφέρει. Το εργαλείο μας
              καθιστά την κατανόηση της αγοραστικής αξίας γρήγορη και εύκολη,
              αναδεικνύοντας τις τελευταίες τάσεις και συνθήκες της αγοράς,
              ιδανικό τόσο για ιδιοκτήτες όσο και για επαγγελματίες του real
              estate.
            </span>

            <button
              className="button-red font-gothic-b16 white margin-t2p pointer button-red-position"
              onClick={scrollToTop}
            >
              Ξεκίνα ΔΩΡΕΑΝ
            </button>
          </div>
        )}

        {window.innerWidth >= 1000 ? (
          <div className="map-back">
            <div className="map-front">
              <span className="font-gothic-b40 red margin-t5p  margin-b2p">
                Αποκτήστε ολοκληρωμένες πληροφορίες σχετικά με το ακίνητό σας
                και την τοπική αγορά
              </span>

              <div className="row-start-start margin-t5p">
                <img
                  className="info-img"
                  src={require("../../assets/info-img1.png")}
                  alt="developing vector"
                />
                <div className="column-start-start width51p">
                  <span className="font-inter-b16 font-blue">
                    Αντικειμενική Αξία | Τιμή Ζώνης
                  </span>
                  <span className="font-inter16 font-blue ">
                    Το εργαλείο μας σας παρέχει μια ακριβή εκτίμηση της
                    αντικειμενικής αξίας του ακινήτου σας, βάσει της Τιμής
                    Ζώνης.
                  </span>
                </div>
              </div>

              <div className="row-start-start margin-t5p">
                <img
                  className="info-img"
                  src={require("../../assets/info-img2.png")}
                  alt="developing vector"
                />
                <div className="column-start-start width51p">
                  <span className="font-inter-b16 font-blue">
                    Συνθήκες τοπɩκής αγοράς
                  </span>
                  <span className="font-inter16 font-blue ">
                    Μάθετε γɩα τɩς τρέχουσες συνθήκες της αγοράς ακɩνήτων στην
                    περɩοχή σας, συμπερɩλαμβανομένου του αρɩθμού παρόμοɩων
                    ακɩνήτων καɩ των τɩμών τους ανά τετραγωνɩκό μέτρο.
                  </span>
                </div>
              </div>

              <div className="row-start-start margin-t5p">
                <img
                  className="info-img"
                  src={require("../../assets/info-img3.png")}
                  alt="developing vector"
                />
                <div className="column-start-start width51p">
                  <span className="font-inter-b16 font-blue">
                    Εύρος τɩμών (Price Range)
                  </span>
                  <span className="font-inter16 font-blue ">
                    Προσδɩορίστε τον τύπο καɩ το μέγεθος του ακɩνήτου που σας
                    ενδɩαφέρεɩ καɩ λάβετε ένα εύρος τɩμών με βάση τɩς πρόσφατες
                    πωλήσεɩς καɩ καταχωρίσεɩς στην περɩοχή που επɩλέξατε. Αυτή η
                    δυνατότητα είναɩ ɩδανɩκή γɩα όσους θέλουν να αγοράσουν ή να
                    επενδύσουν σε ακίνητα.
                  </span>
                </div>
              </div>
              <button
                className="button-red font-gothic-b16 white margin-t5p pointer"
                onClick={scrollToTop}
              >
                Δοκίμασε το
              </button>
            </div>
            <img
              className="stats-overlap"
              src={require("../../assets/stats-overlap.png")}
              alt="developing vector"
            />
          </div>
        ) : (
          <div>
            <div className="mob-map-back">
              <img
                className="mob-stats-overlap"
                src={require("../../assets/stats-overlap.png")}
                alt="developing vector"
              />
            </div>
            <div className="mob-map-around">
              <span className="font-gothic-b24 red margin-t5p  margin-b2p">
                Αποκτήστε ολοκληρωμένες πληροφορίες σχετικά με το ακίνητό σας
                και την τοπική αγορά
              </span>
              <div className="row-start-start margin-t10p">
                <img
                  className="info-img"
                  src={require("../../assets/info-img1.png")}
                  alt="developing vector"
                />
                <div className="column-start-start ">
                  <span className="font-inter-b16 font-blue">
                    Αντικειμενική Αξία | Τιμή Ζώνης
                  </span>
                  <span className="font-inter16 font-blue ">
                    Το εργαλείο μας σας παρέχει μια ακριβή εκτίμηση της
                    αντικειμενικής αξίας του ακινήτου σας, βάσει της Τιμής
                    Ζώνης.
                  </span>
                </div>
              </div>

              <div className="row-start-start margin-t5p">
                <img
                  className="info-img"
                  src={require("../../assets/info-img2.png")}
                  alt="developing vector"
                />
                <div className="column-start-start ">
                  <span className="font-inter-b16 font-blue">
                    Συνθήκες τοπɩκής αγοράς
                  </span>
                  <span className="font-inter16 font-blue ">
                    Μάθετε γɩα τɩς τρέχουσες συνθήκες της αγοράς ακɩνήτων στην
                    περɩοχή σας, συμπερɩλαμβανομένου του αρɩθμού παρόμοɩων
                    ακɩνήτων καɩ των τɩμών τους ανά τετραγωνɩκό μέτρο.
                  </span>
                </div>
              </div>

              <div className="row-start-start margin-t5p">
                <img
                  className="info-img"
                  src={require("../../assets/info-img3.png")}
                  alt="developing vector"
                />
                <div className="column-start-start ">
                  <span className="font-inter-b16 font-blue">
                    Εύρος τɩμών (Price Range)
                  </span>
                  <span className="font-inter16 font-blue ">
                    Προσδɩορίστε τον τύπο καɩ το μέγεθος του ακɩνήτου που σας
                    ενδɩαφέρεɩ καɩ λάβετε ένα εύρος τɩμών με βάση τɩς πρόσφατες
                    πωλήσεɩς καɩ καταχωρίσεɩς στην περɩοχή που επɩλέξατε. Αυτή η
                    δυνατότητα είναɩ ɩδανɩκή γɩα όσους θέλουν να αγοράσουν ή να
                    επενδύσουν σε ακίνητα.
                  </span>
                </div>
              </div>
              <button
                className="button-red font-gothic-b16 white margin-t10p pointer"
                onClick={scrollToTop}
              >
                Δοκίμασε το
              </button>
            </div>
          </div>
        )}

        <div className="activities-back">
          {window.innerWidth >= 1000 ? (
            <span className="font-gothic-b40 red margin-t5p  margin-b2p">
              Περισσότερες δραστηριότητες μαζί μας
            </span>
          ) : (
            <span className="font-gothic-b24 text-center red margin-t5p  margin-b2p">
              Περισσότερες δραστηριότητες μαζί μας
            </span>
          )}

          {window.innerWidth >= 1650 ? (
            <div className="row-space-between-center width100p">
              <div className="activities-card">
                <span className="font-inter-m24">Αγόρασε ακίνητο</span>
                <img
                  className="activities-img"
                  src={require("../../assets/activities-img1.png")}
                  alt="developing vector"
                />
              </div>
              <div className="activities-card">
                <span className="font-inter-m24">Πούλησε ακίνητο</span>
                <img
                  className="activities-img"
                  src={require("../../assets/activities-img2.png")}
                  alt="developing vector"
                />
              </div>

              <div className="activities-card">
                <span className="font-inter-m24">Ενοɩκίασε</span>
                <img
                  className="activities-img"
                  src={require("../../assets/activities-img3.png")}
                  alt="developing vector"
                />
              </div>

              <div className="activities-card">
                <span className="font-inter-m24 ">Γίνε συνεργάτης μας</span>
                <img
                  className="activities-img"
                  src={require("../../assets/activities-img4.png")}
                  alt="developing vector"
                />
              </div>
            </div>
          ) : (
            <div className="main-row-houses-icons margin-t5p">
              <div className="row-mob-to-column">
                <div className="activities-card">
                  <span className="font-inter-m24">Αγόρασε ακίνητο</span>
                  <img
                    className="activities-img"
                    src={require("../../assets/activities-img1.png")}
                    alt="developing vector"
                  />
                </div>
                <div className="activities-card">
                  <span className="font-inter-m24">Πούλησε ακίνητο</span>
                  <img
                    className="activities-img"
                    src={require("../../assets/activities-img2.png")}
                    alt="developing vector"
                  />
                </div>
              </div>

              <div className="row-mob-to-column">
                <div className="activities-card">
                  <span className="font-inter-m24">Ενοɩκίασε</span>
                  <img
                    className="activities-img"
                    src={require("../../assets/activities-img3.png")}
                    alt="developing vector"
                  />
                </div>

                <div className="activities-card">
                  <span className="font-inter-m24 text-center">
                    Γίνε συνεργάτης μας
                  </span>
                  <img
                    className="activities-img"
                    src={require("../../assets/activities-img4.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="consultats-back">
          <div className="consultats-front">
            {window.innerWidth >= 1200 ? (
              <img
                className="consultants-img-both"
                src={require("../../assets/consultants-img-both-w.png")}
                alt="developing vector"
              />
            ) : (
              <img
                className="consultants-img-one"
                src={require("../../assets/consultants-img-one.png")}
                alt="developing vector"
              />
            )}
            {window.innerWidth >= 1000 ? (
              <div className="consultants-right">
                <span className="font-gothic-b40 red margin-b2p">
                  Οι Σύμβουλοι μας λαμβάνουν εκπαίδευση Ανωτάτου Επιπέδου
                </span>

                <span className="font-inter20 font-light-grey margin-t2p">
                  <span className="font-inter-b20 font-light-grey">
                    Η εξέλɩξη της τεχνολογίας στο real estate {""}
                  </span>
                  είναι μια πρόκληση για την εξέλιξη της συμπεριφοράς, δράσης
                  και σκέψης.
                </span>
                <span className="font-inter20 font-light-grey margin-t2p">
                  <span className="font-inter-b20 font-light-grey">
                    Οɩ απαɩτήσεɩς {""}
                  </span>
                  σε μάρκετινγκ, στην ανάλυση κινδύνων, στον υπολογισμό
                  πιθανοτήτων στην λήψη αποφάσεων είναι υψηλές.
                </span>

                <span className="font-inter20 font-light-grey margin-t2p">
                  <span className="font-inter-b20 font-light-grey">
                    Η ίδρυση της Ακαδημίας real estate {""}
                  </span>
                  και η συνεργασία της
                  <span className="font-inter-b20 font-light-grey">
                    {""} newdeal {""}
                  </span>
                  με το
                  <span className="font-inter-b20 font-light-grey">
                    {""} Εθνɩκό καɩ Καποδɩστρɩκό Πανεπɩστήμɩο Αθηνών {""}
                  </span>
                  προσφέρει τη δυνατότητα στους επαγγελματίες Συμβούλους /
                  Μεσίτες, Επαγγελματίες του real estate να ανταποκριθούν τις
                  αυξανόµενες απαιτήσεις γνώσεων.
                </span>
                <Link to="https://realty.newdeal.gr/support/">
                  <button className="button-red-no-width font-inter-semi16 white margin-t5p">
                    Μάθε περɩσσότερα
                  </button>
                </Link>
              </div>
            ) : (
              <div className="consultants-right">
                <div style={{ marginTop: 50 }} />
                <span className="font-gothic-b40 red margin-b2p">
                  Οι Σύμβουλοι μας λαμβάνουν εκπαίδευση Ανωτάτου Επιπέδου
                </span>

                <span className="font-inter18 font-light-grey margin-t2p">
                  <span className="font-inter-b18 font-light-grey">
                    Η εξέλɩξη της τεχνολογίας στο real estate {""}
                  </span>
                  είναι μια πρόκληση για την εξέλιξη της συμπεριφοράς, δράσης
                  και σκέψης.
                </span>
                <span className="font-inter18 font-light-grey margin-t2p">
                  <span className="font-inter-b18 font-light-grey">
                    Οɩ απαɩτήσεɩς {""}
                  </span>
                  σε μάρκετινγκ, στην ανάλυση κινδύνων, στον υπολογισμό
                  πιθανοτήτων στην λήψη αποφάσεων είναι υψηλές.
                </span>

                <span className="font-inter18 font-light-grey margin-t2p">
                  <span className="font-inter-b18 font-light-grey">
                    Η ίδρυση της Ακαδημίας real estate {""}
                  </span>
                  και η συνεργασία της
                  <span className="font-inter-b18 font-light-grey">
                    {""} newdeal {""}
                  </span>
                  με το
                  <span className="font-inter-b18 font-light-grey">
                    {""} Εθνɩκό καɩ Καποδɩστρɩκό Πανεπɩστήμɩο Αθηνών {""}
                  </span>
                  προσφέρει τη δυνατότητα στους επαγγελματίες Συμβούλους /
                  Μεσίτες, Επαγγελματίες του real estate να ανταποκριθούν τις
                  αυξανόµενες απαιτήσεις γνώσεων.
                </span>
                <Link to="https://realty.newdeal.gr/support/">
                  <button className="button-red-no-width font-inter-semi16 white margin-t5p">
                    Μάθε περɩσσότερα
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
        {window.innerWidth >= 1000 ? (
          <div className="cooperate-back">
            <div className="cooperate-front text-center">
              <span className="font-gothic-b40 red margin-t5p  margin-b2p">
                Γιατί να συνεργαστείς μαζί μας;
              </span>
              <div className="line-yellow margin-b2p"></div>
              <span className="font-inter24 font-light-grey">
                Η εφαρμογή
                <span className="font-inter-b24 font-light-grey">
                  {""} "Price my Property" (PMP) {""}
                </span>
                καɩ η συνεργασία μας με το
                <span className="font-inter-b24 font-light-grey">
                  {""} ΕΚΠΑ {""}
                </span>
                είναι μόνο ένα από τα εργαλεία και τις υπηρεσίες που παρέχει η
                <span className="font-inter-b24 font-light-grey">
                  {""} newdeal real estate group, {""}
                </span>
                καɩ έχεɩς πολλούς λόγους να συνεργαστείς μαζί μας:
              </span>
            </div>
          </div>
        ) : (
          <div className="cooperate-back">
            <div className="cooperate-front text-center">
              <span className="font-gothic-b40 red margin-t5p  margin-b2p">
                Γιατί να συνεργαστείς μαζί μας;
              </span>
              <div className="line-yellow margin-b2p"></div>
              <span className="font-inter16 font-light-grey">
                Η εφαρμογή
                <span className="font-inter-b16 font-light-grey">
                  {""} "Price my Property" (PMP) {""}
                </span>
                καɩ η συνεργασία μας με το
                <span className="font-inter-b16 font-light-grey">
                  {""} ΕΚΠΑ {""}
                </span>
                είναι μόνο ένα από τα εργαλεία και τις υπηρεσίες που παρέχει η
                <span className="font-inter-b16 font-light-grey">
                  {""} newdeal real estate group, {""}
                </span>
                καɩ έχεɩς πολλούς λόγους να συνεργαστείς μαζί μας:
              </span>
            </div>
          </div>
        )}

        {window.innerWidth >= 1200 ? (
          <div className="six-icons-back">
            <div className="six-icons-front">
              <div className="row-space-between-center">
                <div className="one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/1of6.png")}
                    alt="developing vector"
                  />
                  <span className="font-inter-semi24 pale-blue">
                    Αποκλεɩστɩκή πρόσβαση σε επενδυτές εξωτερɩκού
                  </span>
                </div>
                <div className="one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/2of6.png")}
                    alt="developing vector"
                  />
                  <span className="font-inter-semi24 pale-blue">
                    Έχουμε τους καλύτερους Συμβούλους Ακɩνήτων
                  </span>
                </div>
                <div className="one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/3of6.png")}
                    alt="developing vector"
                  />
                  <span className="font-inter-semi24 pale-blue">
                    Τεχνολογία Αɩχμής
                  </span>
                </div>
              </div>

              <div className="row-space-between-center margin-t5p">
                <div className="one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/4of6.png")}
                    alt="developing vector"
                  />
                  <span className="font-inter-semi24 pale-blue">
                    Πρωτοπορɩακή προώθηση Ακɩνήτων
                  </span>
                </div>
                <div className="one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/5of6.png")}
                    alt="developing vector"
                  />
                  <span className="font-inter-semi24 pale-blue">
                    Premium Υπηρεσίες στο μέγɩστο επίπεδο
                  </span>
                </div>
                <div className="one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/6of6.png")}
                    alt="developing vector"
                  />
                  <span className="font-inter-semi24 pale-blue">
                    Εκτεταμένη Εμπεɩρία
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="six-icons-back">
            <div className="mob-six-icons-front">
              <div className="row-space-between-center margin-t40">
                <div className="mob-one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/1of6.png")}
                    alt="developing vector"
                  />
                </div>
                <div className="mob-one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/2of6.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
              <div className="row-space-between-start  ">
                <div className="mob-one-o6-column">
                  <span className="font-inter-semi24-media pale-blue">
                    Αποκλεɩστɩκή πρόσβαση σε επενδυτές εξωτερɩκού
                  </span>
                </div>
                <div className="mob-one-o6-column">
                  <span className="font-inter-semi24-media pale-blue">
                    Έχουμε τους καλύτερους Συμβούλους Ακɩνήτων
                  </span>
                </div>
              </div>

              <div className="row-space-between-center margin-t40">
                <div className="mob-one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/3of6.png")}
                    alt="developing vector"
                  />
                </div>
                <div className="mob-one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/4of6.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
              <div className="row-space-between-start  ">
                <div className="mob-one-o6-column">
                  <span className="font-inter-semi24-media pale-blue">
                    Τεχνολογία Αɩχμής
                  </span>
                </div>
                <div className="mob-one-o6-column">
                  <span className="font-inter-semi24-media pale-blue">
                    Πρωτοπορɩακή προώθηση Ακɩνήτων
                  </span>
                </div>
              </div>

              <div className="row-space-between-center margin-t40">
                <div className="mob-one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/5of6.png")}
                    alt="developing vector"
                  />
                </div>
                <div className="mob-one-o6-column">
                  <img
                    className="one-of6-icon"
                    src={require("../../assets/6of6.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
              <div className="row-space-between-start  ">
                <div className="mob-one-o6-column">
                  <span className="font-inter-semi24-media pale-blue">
                    Premium Υπηρεσίες στο μέγɩστο επίπεδο
                  </span>
                </div>
                <div className="mob-one-o6-column">
                  <span className="font-inter-semi24-media pale-blue">
                    Εκτεταμένη Εμπεɩρία
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {window.innerWidth >= 500 ? (
          <div className="blue-map-small">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="column-k">
                <span className="font-gothic-b79 white">250+</span>
                <span className="font-inter-semi24 white">Συνεργάτες</span>
              </div>
              <div className="column-k">
                <span className="font-gothic-b79 white">30k+</span>
                <span className="font-inter-semi24 white">
                  Ζητήσεɩς Ακɩνήτων
                </span>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="column-k">
                <span className="font-gothic-b79 white">10k+</span>
                <span className="font-inter-semi24 white">
                  Δɩαθέσɩμα Ακίνητα
                </span>
              </div>
              <div className="column-k">
                <span className="font-gothic-b79 white">18+</span>
                <span className="font-inter-semi24 white">
                  Χρόνɩα Εμπεɩρίας
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="blue-map-small">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="column-k">
                <span className="font-gothic-b60 white">250+</span>
                <span className="font-inter-semi20 white">Συνεργάτες</span>
              </div>
              <div className="column-k">
                <span className="font-gothic-b60 white">30k+</span>
                <span className="font-inter-semi20 white">
                  Ζητήσεɩς Ακɩνήτων
                </span>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="column-k">
                <span className="font-gothic-b60 white">10k+</span>
                <span className="font-inter-semi20 white">
                  Δɩαθέσɩμα Ακίνητα
                </span>
              </div>
              <div className="column-k">
                <span className="font-gothic-b60 white">18+</span>
                <span className="font-inter-semi20 white">
                  Χρόνɩα Εμπεɩρίας
                </span>
              </div>
            </div>
          </div>
        )}
        {window.innerWidth >= 1000 ? (
          <div className="we-give  ">
            <span className="font-inter24 font-light-grey">
              Δίνουμε
              <span className="font-inter-b24 font-light-grey">
                {""} μεγαλύτερη αξία {""}
              </span>
              στην ακίνητη περιουσία σας από έναν παραδοσιακό μεσίτη.
            </span>
            <Link to="https://newdeal.gr/el/add-listing">
              <button className="button-red-no-width font-inter-semi16 white margin-t5p pointer">
                Μάθε περɩσσότερα
              </button>
            </Link>
          </div>
        ) : (
          <div className="we-give margin-t10p ">
            <span className="font-inter20 font-light-grey">
              Δίνουμε
              <span className="font-inter-b20 font-light-grey">
                {""} μεγαλύτερη αξία {""}
              </span>
              στην ακίνητη περιουσία σας από έναν παραδοσιακό μεσίτη.
            </span>
            <Link to="https://newdeal.gr/el/add-listing">
              <button className="button-red-no-width font-inter-semi16 white margin-t10p pointer">
                Μάθε περɩσσότερα
              </button>
            </Link>
          </div>
        )}

        <div className="activities-back">
          <div className="main-heading-squeeze">
            {window.innerWidth >= 1000 ? (
              <span className="font-gothic-b40 red margin-t5p  margin-b2p">
                Αυξήστε την αξία του ακινήτου σας με τους άρθρα-οδηγούς μας
              </span>
            ) : (
              <span className="font-gothic-b25 red margin-t10p  margin-b5p">
                Αυξήστε την αξία του ακινήτου σας με τους άρθρα-οδηγούς μας
              </span>
            )}
          </div>
          {/* <Carousel
            // swipeable={true}
            // draggable={true}
            showDots={false}
            arrows={false}
            // autoPlay={true}
            responsive={responsive}
            // infinite={true}
            customButtonGroup={<ButtonGroup />}
            // autoPlaySpeed={1000}
            // keyBoardControl={true}
            // customTransition="all .5"
            // transitionDuration={500}
            containerClass="carousel-container carousel"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            // dotListClass="custom-dot-list-style"
            // itemClass="padding10"
          > */}

          {/* <div className="property-card">
              <img
                style={{ width: "inherit" }}
                src={require("../../assets/property-img1.png")}
                alt="developing vector"
              />
              <div className="property-line"></div>
              <span className="font-gothic-b18 property-padding">
                Πότε πρέπει να ανεβάσω το ακίνητο μου στην αγορά;
              </span>
            </div>
            <div className="property-card">
              <img
                style={{ width: "inherit" }}
                src={require("../../assets/property-img2.png")}
                alt="developing vector"
              />
              <div className="property-line"></div>
              <span className="font-gothic-b18 property-padding">
                Είναι οι εκτιμήσεις των Συμβούλων εντελώς δωρεάν;
              </span>
            </div>
            <div className="property-card">
              <img
                style={{ width: "inherit" }}
                src={require("../../assets/property-img3.png")}
                alt="developing vector"
              />
              <div className="property-line"></div>
              <span className="font-gothic-b18 property-padding">
                Πόσο πρέπει να πουλήσω το ακίνητο μου τελικά;
              </span>
            </div>
            <div className="property-card">
              <img
                style={{ width: "inherit" }}
                src={require("../../assets/property-img1.png")}
                alt="developing vector"
              />
              <div className="property-line"></div>
              <span className="font-gothic-b18 property-padding">
                Πότε πρέπει να ανεβάσω το ακίνητο μου στην αγορά;
              </span>
            </div>
            <div className="property-card">
              <img
                style={{ width: "inherit" }}
                src={require("../../assets/property-img2.png")}
                alt="developing vector"
              />
              <div className="property-line"></div>
              <span className="font-gothic-b18 property-padding">
                Είναι οι εκτιμήσεις των Συμβούλων εντελώς δωρεάν;
              </span>
            </div>
            <div className="property-card">
              <img
                style={{ width: "inherit" }}
                src={require("../../assets/property-img3.png")}
                alt="developing vector"
              />
              <div className="property-line"></div>
              <span className="font-gothic-b18 property-padding">
                Πόσο πρέπει να πουλήσω το ακίνητο μου τελικά;
              </span>
            </div>
          </Carousel> */}
          <iframe
            src="https://realty.newdeal.gr/pricemyproperty/"
            width="100%"
            height="400"
            frameborder="0"
            allowfullscreen
            title="Embedded Carousel"
          ></iframe>

          <div className="frequent-questions">
            {window.innerWidth >= 1000 ? (
              <div className="main-yellow-card-back">
                <div className="main-yellow-front">
                  <div className="column-space-around height100p yellow-pad">
                    <span className="font-gothic-b28 white">
                      Μάθε την αξία του ακινήτου σου από τοπικούς Συμβούλους
                      ακινήτων
                    </span>
                    <span className="font-inter18 white">
                      Μιλήστε με έναν πιστοποιημένο Σύμβουλο μας και ζητήστε μια
                      εκτίμηση ακινήτου δωρεάν.
                    </span>
                    <button
                      onClick={() => setShowContact(true)}
                      className="button-outline-white  font-gothic-b16 margin-t20"
                    >
                      Θέλω να μιλήσω με Σύμβουλο
                    </button>
                  </div>
                  <img
                    className="yellow-ppl-img"
                    src={require("../../assets/mob-img-ppl-yellow.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
            ) : (
              <div className="mob-main-yellow-card-back margin-t10p">
                <div className="mob-main-yellow-front">
                  <div className="column-space-around height100p mob-yellow-pad">
                    <span className="font-gothic-b25 white">
                      Μάθε την αξία του ακινήτου σου από τοπικούς Συμβούλους
                      ακινήτων
                    </span>
                    <span className="font-inter16 white">
                      Μιλήστε με έναν πιστοποιημένο Σύμβουλο μας και ζητήστε μια
                      εκτίμηση ακινήτου δωρεάν.
                    </span>
                    <button onClick={() => setShowContact(true)} className="button-outline-white  font-gothic-b16 margin-t20">
                      Θέλω να μιλήσω με Σύμβουλο
                    </button>
                  </div>
                  <img
                    className="mob-yellow-ppl-img"
                    src={require("../../assets/mob-img-ppl-yellow.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
            )}

            <FrequentQuestions />
          </div>
        </div>
        <Neighborhoods />
        <img
          style={{ width: "100%", backgroundColor: "#f7f8fd" }}
          src={require("../../assets/end-img-main.png")}
          alt="developing vector"
        />
      </div>
    </div>
  );
}

export default Main;
