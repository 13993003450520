import React from "react";
import "./FrequentQuestions.css";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import { useState } from "react";

function FrequentQuestions() {
  const [extendQuestion1, setExtendQuestion1] = useState(true);
  const [extendQuestion2, setExtendQuestion2] = useState(true);
  const [extendQuestion3, setExtendQuestion3] = useState(true);
  const [extendQuestion4, setExtendQuestion4] = useState(true);
  const [extendQuestion5, setExtendQuestion5] = useState(true);

  return (
    <div className="width100p margin-t40">
      {window.innerWidth >= 1000 ? (
        <span className="font-gothic-b40 red   margin-b2p">
          Συχνές ερωτήσεις σχετικά με την εκτίμηση ακινήτων
        </span>
      ) : (
        <span className="font-gothic-b25 red   margin-b40">
          Συχνές ερωτήσεις σχετικά με την εκτίμηση ακινήτων
        </span>
      )}

      <div
        className="questions margin-t40"
        onClick={() => setExtendQuestion1(!extendQuestion1)}
      >
        <div className="row-space-between-center width100p">
          <span className="font-inter18 font-light-grey">
            Είναɩ ακρɩβείς οɩ εκτɩμήσεɩς γɩα τɩς τɩμές των κατοɩκɩών του Price
            my Property;
          </span>
          <ExpandMoreTwoToneIcon
            style={{
              color: "#263573",
              cursor: "pointer",
              fontSize: 30,
              rotate: extendQuestion1 ? "none" : "180deg",
            }}
          />
        </div>
        <span
          className="font-inter16 dark-grey margin-t20"
          style={{ display: extendQuestion1 ? "none" : "flex" }}
        >
          Στην newdeal real estate group, είμαστε περήφανοι που παρέχουμε τις
          πιο ακριβείς εκτιμήσεις μέσω της πλατφόρμας "Price my Property". Οι
          εκτιμήσεις μας προέρχονται από έναν συνδυασμό αλγορίθμων τεχνητής
          νοημοσύνης, εκτενών δεδομένων αγοράς και πληροφοριών από την ομάδα των
          ειδικών μας. Αν και είναι εξαιρετικά ακριβείς για μεγάλο αριθμό
          ακινήτων, είναι σημαντικό να θυμάστε ότι είναι εκτιμήσεις κατά
          προσέγγιση. Συνιστούμε πάντα να συμβουλευτείτε έναν από τους έμπειρους
          αντιπροσώπους μας για μια ολοκληρωμένη αποτίμηση.
        </span>
      </div>

      <div
        className="questions"
        onClick={() => setExtendQuestion2(!extendQuestion2)}
      >
        <div className="row-space-between-center width100p">
          <span className="font-inter18 font-light-grey">
            Πώς μπορώ να βρω την αξία του σπɩτɩού μου στο δɩαδίκτυο;
          </span>
          <ExpandMoreTwoToneIcon
            style={{
              color: "#263573",
              cursor: "pointer",
              fontSize: 30,
              rotate: extendQuestion2 ? "none" : "180deg",
            }}
          />
        </div>
        <span
          className="font-inter16 dark-grey margin-t20"
          style={{ display: extendQuestion2 ? "none" : "flex" }}
        >
          Με το "Price my Property" της newDeal, ο προσδιορισμός της αξίας του
          σπιτιού σας στο διαδίκτυο δεν ήταν ποτέ ευκολότερος! Απλώς εισαγάγετε
          τη διεύθυνση του ακινήτου σας στην πλατφόρμα μας, δώστε μερικές
          βασικές λεπτομέρειες για το σπίτι σας και λάβετε μια άμεση εκτίμηση.
          Το εργαλείο μας λαμβάνει υπόψη παράγοντες όπως η τοποθεσία, το μέγεθος
          του ακινήτου, οι τάσεις της τοπικής αγοράς και πολλά άλλα για να σας
          παρέχει μια καλά ενημερωμένη εκτίμηση.
        </span>
      </div>

      <div
        className="questions"
        onClick={() => setExtendQuestion3(!extendQuestion3)}
      >
        <div className="row-space-between-center width100p">
          <span className="font-inter18 font-light-grey">
            Τɩ θα αξίζεɩ το σπίτɩ μου σε 5 χρόνɩα;
          </span>
          <ExpandMoreTwoToneIcon
            style={{
              color: "#263573",
              cursor: "pointer",
              fontSize: 30,
              rotate: extendQuestion3 ? "none" : "180deg",
            }}
          />
        </div>
        <span
          className="font-inter16 dark-grey margin-t20"
          style={{ display: extendQuestion3 ? "none" : "flex" }}
        >
          Η πρόβλεψη της ακριβούς αξίας ενός ακινήτου σε 5 χρόνια περιλαμβάνει
          την εξέταση πολλαπλών δυναμικών της αγοράς, οικονομικούς παράγοντες
          και πιθανές αλλαγές στην επιθυμία της γειτονιάς. Ενώ η πλατφόρμα μας
          "Price my Property" παρέχει μια τρέχουσα εκτιμώμενη αξία με βάση τα
          σημερινά δεδομένα, η πρόβλεψη μελλοντικής αξίας είναι πιο περίπλοκη.
          Ωστόσο, μιλώντας με τους ειδικούς συμβούλους ακινήτων μας, μπορείτε να
          αποκτήσετε πληροφορίες για πιθανές εκτιμήσεις της αγοράς και να λάβετε
          τεκμηριωμένες αποφάσεις σχετικά με το ακίνητό σας. Επικοινωνήστε
          σήμερα εδώ.
        </span>
      </div>

      <div
        className="questions"
        onClick={() => setExtendQuestion4(!extendQuestion4)}
      >
        <div className="row-space-between-center width100p">
          <span className="font-inter18 font-light-grey">
            Μπορείτε να λάβετε δωρεάν αποτίμηση του σπɩτɩού σας;
          </span>
          <ExpandMoreTwoToneIcon
            style={{
              color: "#263573",
              cursor: "pointer",
              fontSize: 30,
              rotate: extendQuestion4 ? "none" : "180deg",
            }}
          />
        </div>
        <span
          className="font-inter16 dark-grey margin-t20"
          style={{ display: extendQuestion4 ? "none" : "flex" }}
        >
          Απολύτως! Το "Price my Property" της newdeal έχει σχεδιαστεί για να
          προσφέρει στους χρήστες μια δωρεάν, άμεση ηλεκτρονική αξιολόγηση των
          σπιτιών τους. Απλώς εισάγετε τις απαιτούμενες λεπτομέρειες και το
          σύστημά μας θα δημιουργήσει μια εκτίμηση για εσάς. Για μια πιο
          λεπτομερή και εξατομικευμένη αξιολόγηση, εξετάστε το ενδεχόμενο να
          απευθυνθείτε στην ομάδα ειδικών μας στο NewDeal Real Estate Group.
          Επικοινωνήστε σήμερα εδώ.
        </span>
      </div>

      <div
        className="questions"
        onClick={() => setExtendQuestion5(!extendQuestion5)}
      >
        <div className="row-space-between-center width100p">
          <span className="font-inter18 font-light-grey">
            Πως μπορώ να αναβαθμίσω το σπίτɩ μου;
          </span>
          <ExpandMoreTwoToneIcon
            style={{
              color: "#263573",
              cursor: "pointer",
              fontSize: 30,
              rotate: extendQuestion5 ? "none" : "180deg",
            }}
          />
        </div>
        <span
          className="font-inter16 dark-grey margin-t20"
          style={{ display: extendQuestion5 ? "none" : "flex" }}
        >
          Η αναβάθμιση του σπιτιού σας μπορεί να γίνει με διάφορους τρόπους, από
          απλές αλλαγές διακόσμησης έως ανακαινίσεις πλήρους κλίμακας. Ξεκινήστε
          εντοπίζοντας σημεία στο σπίτι σας που θα μπορούσαν να ωφεληθούν από τη
          βελτίωση. Αυτό θα μπορούσε να είναι ο εκσυγχρονισμός της κουζίνας, η
          βελτίωση του κήπου ή η προσθήκη ενεργειακά αποδοτικών εγκαταστάσεων.
          Επιπλέον, χρησιμοποιώντας το "Price my Property", μπορείτε να
          κατανοήσετε την τρέχουσα αξία του σπιτιού σας, κάτι που μπορεί να σας
          βοηθήσει να καθορίσετε τον προϋπολογισμό σας για αναβαθμίσεις. Για
          συγκεκριμένες προτάσεις προσαρμοσμένες στο ακίνητό σας, συμβουλευτείτε
          τους αντιπροσώπους και ειδικούς της newdeal, οι οποίοι μπορούν να
          παρέχουν πληροφορίες για αναβαθμίσεις που μπορεί να προσθέσουν
          μεγαλύτερη αξία στην περιουσία σας.
        </span>
      </div>
    </div>
  );
}

export default FrequentQuestions;
