import { useEffect, useState } from "react";
import "./SearchArea.css";
import { ReactComponent as HouseIcon } from "../../assets/house.svg";
import { ReactComponent as ProfIcon } from "../../assets/prof.svg";
import { ReactComponent as LandIcon } from "../../assets/land.svg";
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import axios from "axios";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import priceSlice from "../../store/slices/prices";
import Slider from "@mui/material/Slider";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Button,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@mui/material";
// import Checkbox from "@mui/material/Checkbox";
// import moment from "moment";
// import { useSelector, useDispatch } from "react-redux";
// import { RootState } from "../../store";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import "moment/locale/en-gb";
import MapComponent from "../map/Map";

// import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SearchArea = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.price);
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const [type, setType] = useState("RESIDENCE");
  const [areaMin, setAreaMin] = useState(0);
  const [areaMax, setAreaMax] = useState(10000);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(10000000);
  const [floorMin, setFloorMin] = useState(-3);
  const [floorMax, setFloorMax] = useState(20);
  const [yearMin, setYearMin] = useState(1950);
  const [yearMax, setYearMax] = useState(2023);
  const [priceUnitMin, setPriceUnitMin] = useState(0);
  const [priceUnitMax, setPriceUnitMax] = useState(100000);
  const [accepted,setAccepted]= useState(false);
  const [bedroomsMin, setBedroomsMin] = useState(0);
  const [bedroomsMax, setBedroomsMax] = useState(10);
  const [floor, setFloor] = useState();
  const [hasNoElevator, setHasNoElevator] = useState(false);
  const [contactType, setContactType] = useState("");
  const [hasViewSea, setHasViewSea] = useState(false);
  const [hasViewScene, setHasViewScene] = useState(false);
  const [hasViewMountain, setHasViewMountain] = useState(false);
  const [hasStorage, setHasStorage] = useState(false);
  const [hasFirePlace, setHasFirePlace] = useState(false);
  const [hasPool, setHasPool] = useState(false);
  const [constructionYear, setConstructionYear] = useState();
  const [hasParking, setHasParking] = useState(false);
  const [hasAutonomousHeating, setHasAutonomousHeating] = useState(false);
  const [isBeingBuilt, setIsBeingBuilt] = useState(false);
  const [isCorner, setIsCorner] = useState(false);
  const [isKeep, setIsKeep] = useState(false);
  const [isInternal, setInternal] = useState(false);
  const [isDestroy, setIsDestroy] = useState(false);
  const [isDamaged, setIsDamaged] = useState(false);
  const [hasOwners, setHasOwners] = useState(false);
  const [showForm,setShowForm] = useState(true);

  const [isStreetSquare, setIsStreetSquare] = useState(false);
  const [changeColor, setChangeColor] = useState(false);


  // const [nameOfproperty, setNameOfproperty] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [predecessors, setPredecessors] = useState < Array < number >> [];
  // const [status, setStatus] = useState("");
  // const [completion, setCompletion] = useState(0);

  // const [description, setDescription] = useState("");
  // const [challenges, setChallenges] = useState("");

  // const [isFirst, setIsFirst] = useState(true);
  // const alert = useAlert();

  useEffect(() => {
    if (
      localStorage.getItem("formId") &&
      localStorage.getItem("formId") !== "null" &&
      localStorage.getItem("formId") !== "undefined"
    ) {
      console.log(localStorage.getItem("formId"));
      setShowForm(false);
    }
  }, []);

  const closeHandler = () => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    setShow(props.show);
    console.log(props.show);
  }, [props]);

  useEffect(() => {
    setAreaMin(0);
    setBedroomsMin(0);
    setAreaMax(10000);
    setBedroomsMax(10);
    setPriceMax(10000000);
    setPriceMin(0);
    setFloorMin(-3);
    setFloorMax(20);
    setYearMax(2023);
    setYearMin(1950);
    setPriceUnitMax(100000);
    setPriceUnitMin(0);
    setStep(1);
    setConstructionYear();
    setFloor();
    setIsCorner(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setHasAutonomousHeating(false);
    setHasNoElevator(false);
    setHasFirePlace(false);
    setHasParking(false);
    setHasPool(false);
    setHasStorage(false);
    setHasViewMountain(false);
    setHasViewScene(false);
    setHasViewSea(false);
    setIsBeingBuilt(false);
    setIsStreetSquare(false);
  }, []);
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  // useEffect(() => {
  //   if (isFirst) {
  //     setIsFirst(false);
  //   }
  //   if (props.property_id) {
  //     props.propertys.forEach((property) => {
  //       if (props.property_id === property.id) {
  //         setNameOfproperty(property.name);
  //         setStartDate(property.start_date);
  //         setEndDate(property.end_date);
  //         setDescription(property.description);
  //         setStatus(property.status);
  //         setCompletion(property.completion);
  //         setPredecessors(property.predecessors);
  //       }
  //     });
  //   } else {
  //     setNameOfproperty("");
  //     setStartDate("");
  //     setEndDate("");
  //     setDescription("");
  //     setStatus("");
  //     setCompletion(0);
  //     setPredecessors([]);
  //   }
  // }, []);

  const send = () => {
    if (!showForm) {
      let bodyFiller = {
        id: localStorage.getItem("formId"),
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/update_form_filler`, bodyFiller)
        .then((res) => {
          console.log(res);
          localStorage.setItem("formId", res.data.id);
          setShowForm(false);
          search();
        })

        .catch((err) => {
          
          setShowForm(true);
          localStorage.setItem("formId", null);
          console.log(err);
        });
    } else {
      let bodyFiller = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phoneNumber,
        type: contactType,
        search_type:type,
        address:props.marker.formatted_address
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/add_form_filler`, bodyFiller)
        .then((res) => {
          localStorage.setItem("formId", res.data.id);
          setShowForm(false);
          search();
        })

        .catch((err) => {
      
            console.log(err);
          
        });
    }
  }

  const search=()=>{
  
    let google_city = "";
    let google_neighborhood = "";
    console.log("MARKER", props.marker);
    props.marker.address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        google_city = component.long_name;
      } else if (component.types.includes("administrative_area_level_3")) {
        google_neighborhood = component.long_name;
      }
    });
    let body = {
      address: props.marker.formatted_address,
      geo_lat: props.marker.geometry.location.lat(),
      geo_lng: props.marker.geometry.location.lng(),
      google_city: google_city,
      google_neighborhood: google_neighborhood,
      type: type,
      area_min: areaMin,
      area_max: areaMax,
      bedrooms_min: bedroomsMin,
      bedrooms_max: bedroomsMax,
      price_min: priceMin,
      price_max: priceMax,
      price_per_unit_min: priceUnitMin,
      price_per_unit_max: priceUnitMax,
      floor_min: floorMin,
      floor_max:floorMax,
      construction_year_min: yearMin,
      construction_year_max: yearMax,
      corner: isCorner,
      square: isStreetSquare,
      internal: false,
      is_keep: isKeep,
      sea_view: hasViewSea,
      mountain_view: hasViewMountain,
      fireplace: hasFirePlace,
      pool: hasPool,
      parking: hasParking,
      storage: hasStorage,
      no_heating: !hasAutonomousHeating,
      no_elevator: hasNoElevator,
      owners: hasOwners,
      is_destroy: isDestroy,
      is_damaged: isDamaged,
      material: 1,

      
      finished_level: isBeingBuilt ? 1 : 5,
    };
    dispatch(priceSlice.actions.setRequest(body));

    dispatch(priceSlice.actions.setMarker(props.marker));

    navigate("/resultsneighborhood");
    props.onClose();
  };

  const handleSliderChangeBedrooms = (event, newValue) => {
    setBedroomsMin(newValue[0]);
    setBedroomsMax(newValue[1]);
  };

  const handleSliderChangeFloor = (event, newValue) => {
    setFloorMin(newValue[0]);
    setFloorMax(newValue[1]);
  }; const handleSliderChangeYear = (event, newValue) => {
    setYearMin(newValue[0]);
    setYearMax(newValue[1]);
  };
  const handleSliderChangeArea = (event, newValue) => {
    setAreaMin(newValue[0]);
    setAreaMax(newValue[1]);
  };
  const handleSliderChangePrice = (event, newValue) => {
    setPriceMin(newValue[0]);
    setPriceMax(newValue[1]);
  };
  const handleSliderChangePriceUnit = (event, newValue) => {
    setPriceUnitMin(newValue[0]);
    setPriceUnitMax(newValue[1]);
  };

  // else {
  //     let body = {
  //       name: nameOfproperty,
  //       description: description,
  //       status: status,
  //       predecessors: predecessors,
  //       completion: completion,
  //       start_date: moment(startDate).format("YYYY-MM-DD").toString(),
  //       end_date: moment(endDate).format("YYYY-MM-DD").toString(),
  //       challenges: challenges,
  //       project_id: props.project_id,
  //     };
  //     axiosService
  //       .post(`${process.env.REACT_APP_API_URL}/add_property`, body)
  //       .then((res) => {
  //         alert.success("The property was added");
  //         props.onClose(true);
  //       })

  //       .catch((err) => {
  //         if (err.response?.status !== 500) {
  //           if (err.response) {
  //             Object.keys(err.response.data).forEach(function (key, index) {
  //               alert.error(key + ":" + err.response.data[key]);
  //             });
  //           } else {
  //             alert.error(err.message);
  //           }
  //         }

  //         console.log(err);
  //       });
  //   }
  // };

  // useEffect(() => {
  //   setShow(props.show);
  // }, [props.show]);

  // const handleChangePredeccesors = (event) => {
  //   const {
  //     target: { value },
  //   } = event;

  //   let tmp = [];
  //   if (typeof value === "string") {
  //     value.split(",").forEach((val) => {
  //       tmp.push(parseInt(val));
  //     });
  //   } else {
  //     tmp = value;
  //   }
  //   setPredecessors(tmp);
  // };

  return (
    <Modal
      open={show}
      sx={{ zIndex: 1500 }}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-property"}>
      <IconButton className="close-button" onClick={closeHandler}>
  <Close />
</IconButton>
        <div className={"property-scroll"}>
          <div className="column-start-center width100p ">
            {/* <div className="row-end-center width100p">
              <span className={"close"} onClick={closeHandler}>
                &times;
              </span>
            </div> */}
            <div className="row-center-center width100p address ">
              {props.marker && (
                <span className="font-gothic-b20 ">
                  {props.marker.formatted_address}
                </span>
              )}
            </div>
            <div className="row-start-start width100p">
              <div
               style={{
                width:
                  step === 1
                    ? "20%"
                    : step === 2
                    ? "40%"
                    : step === 3
                    ? "60%"
                    : step === 4
                    ? "80%"
                    : "100%",
              }}
                className="progress-line"
              />
            </div>
            <div className="row-center-center width100p">
              <span className="font-inter15 red">Βήμα {step}/5</span>
            </div>
          </div>
          {step === 1 && (
            <MapComponent
              style={{ border: "1px solid black" }}
              marker={props.marker}
              height={"150px"}
            />
          )}

          {step === 1 && (
            <div className="popup-container">
              <span className="font-inter15 margin-t2p">
                Επιλέξτε το είδος του ακινήτου
              </span>
              <div className="box-row">
                <Box
                  className="box-select search-mob-margin-r20 pointer"
                  onClick={() => setType("RESIDENCE")}
                  sx={{
                    width: 100,
                    height: 100,
                    backgroundColor: type !=="RESIDENCE" ?  "white": "#263573",
                    color: type !=="RESIDENCE" ?  "#263573": "white",
                                        // opacity: type !== "RESIDENCE" ? [0.9, 0.8, 0.7] : 1,

                    // "&:hover": {
                    //   backgroundColor: "white",
                    //   opacity: 1,
                    // },
                  }}
                >
                  <HouseIcon
                  
                    fill="currentColor"
                    stroke="currentColor"
                    className="prop-icon"
                  />
                  <span className="font-inter15 margin-t2p">
                    Κατοικία
                  </span>
                </Box>
                <Box
                  className="box-select search-mob-margin-r20 pointer"
                  onClick={() => setType("PROFESSIONAL")}
                  sx={{
                    width: 100,
                    height: 100,
                    backgroundColor: type !=="PROFESSIONAL" ?  "white": "#263573",
                    color: type !=="PROFESSIONAL" ?  "#263573": "white",
                    // opacity: type !== "PROFESSIONAL" ? [0.9, 0.8, 0.7] : 1,
                   
                    // "&:hover": {
                    //   backgroundColor: "white",
                    //   opacity: 1,
                    // },
                  }}
                >
                  <ProfIcon
                    fill="currentColor"
                    stroke="currentColor"
                    className="prop-icon"
                  />
                  <span className="font-inter15 margin-t2p ">
                    Επαγγ. Στέγη
                  </span>
                </Box>
                <Box
                  className="box-select pointer"
                  onClick={() => setType("LAND")}
                  sx={{
                    width: 100,
                    height: 100,
                    backgroundColor: type !=="LAND" ?  "white": "#263573",
                    color: type !=="LAND" ?  "#263573": "white",
                    // opacity: type !== "LAND" ? [0.9, 0.8, 0.7] : 1,

                    // "&:hover": {
                    //   backgroundColor: "white",
                    //   opacity: 1,
                    // },
                  }}
                >
                  <LandIcon
                    fill="currentColor"
                    stroke="currentColor"
                    className="prop-icon"
                  />
                  <span className="font-inter15 margin-t2p ">Γη</span>
                </Box>
              </div>
              <Box className="add-property-full-width padding-up-down margin-t10 pointer">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    MenuProps={{
                      style: { zIndex: 3000 },
                    }}
                    onChange={(event) => setType(event.target.value)}
                  >
                    <MenuItem value={"RESIDENCE"}>Κατοικία</MenuItem>
                    <MenuItem value={"PROFESSIONAL"}>
                      Επαγγελματική Στέγη
                    </MenuItem>
                    <MenuItem value={"LAND"}>Γη</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          )}

          {step === 2 && (
            <div className="popup-container">
              <span className="font-inter15 margin-t2p ">
                Επιλέξτε τα χαρακτηριστικά του ακινήτου
              </span>
              <div className="box-row width80p ">
                <Slider
                  value={[priceMin, priceMax]}
                  onChange={handleSliderChangePrice}
                  valueLabelDisplay="auto"
                  min={0}
                  max={10000000}
                  step={1000}
                />
              </div>
              <div className="box-row width80p ">
                <Box className="add-property-half-width margin-r10">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Τιμή Από"
                      placeholder="π.χ. 100000"
                      value={priceMin}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setPriceMin(valueAsNumber);
                        } else {
                          setPriceMin('');
                        }
                      }}
                     
                    />
                  </FormControl>
                </Box>
                <Box className="add-property-half-width margin-r10">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Τιμή Έως"
                      placeholder="π.χ. 1000000"
                      value={priceMax}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setPriceMax(valueAsNumber);
                        } else {
                          setPriceMax('');
                        }
                      }}
                     
                    />
                  </FormControl>
                </Box>
              </div>
              <div className="box-row width80p ">
                <Slider
                  value={[priceUnitMin, priceUnitMax]}
                  onChange={handleSliderChangePriceUnit}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100000}
                  step={10}
                />
              </div>
              <div className="box-row width80p ">
                <Box className="add-property-half-width margin-r10">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Τιμή ανά τετραγωνικό από"
                      placeholder="π.χ. 100000"
                      value={priceUnitMin}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setPriceUnitMin(valueAsNumber);
                        } else {
                          setPriceUnitMin('');
                        }
                      }}
                     
                    />
                  </FormControl>
                </Box>
                <Box className="add-property-half-width margin-r10">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Τιμή ανά τετραγωνικό έως"
                      placeholder="π.χ. 1000000"
                      value={priceUnitMax}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setPriceUnitMax(valueAsNumber);
                        } else {
                          setPriceUnitMax('');
                        }
                      }}
                     
                    />
                  </FormControl>
                </Box>
              </div>

              <div className="box-row width80p ">
                <Slider
                  value={[bedroomsMin, bedroomsMax]}
                  onChange={handleSliderChangeBedrooms}
                  valueLabelDisplay="auto"
                  min={0}
                  max={10}
                  step={1}
                />
              </div>
              <div className="box-row width80p ">
                <Box className="add-property-half-width ">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Υπνοδωμάτια Από"
                      placeholder="π.χ. 1"
                      value={bedroomsMin}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setBedroomsMin(valueAsNumber);
                        } else {
                          setBedroomsMin('');
                        }
                      }}
                     
                    />
                  </FormControl>
                </Box>
                <Box className="add-property-half-width ">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Υπνοδωμάτια Έως"
                      placeholder="π.χ. 10+"
                      value={bedroomsMax}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setBedroomsMax(valueAsNumber);
                        } else {
                          setBedroomsMax('');
                        }
                      }}
                     
                    />
                  </FormControl>
                </Box>
              </div>
              <div className="box-row width80p ">
                <Slider
                  value={[areaMin, areaMax]}
                  onChange={handleSliderChangeArea}
                  valueLabelDisplay="auto"
                  min={0}
                  max={10000}
                  step={10}
                />
              </div>
              <div className="box-row width80p " style={{marginTop:10,marginBottom:20 }}>
                <Box className="add-property-half-width margin-r10">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Τετραγωνικά Από"
                      placeholder="π.χ. 20"
                      value={areaMin}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setAreaMin(valueAsNumber);
                        } else {
                          setAreaMin('');
                        }
                      }}
                      
                    />
                  </FormControl>
                </Box>
                <Box className="add-property-half-width margin-r10">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Τετραγωνικά Έως"
                      placeholder="π.χ. 500"
                      value={areaMax}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setAreaMax(valueAsNumber);
                        } else {
                          setAreaMax('');
                        }
                      }}
                      
                    />
                  </FormControl>
                </Box>
              </div>
              <div className="box-row width80p ">
                <Slider
                  value={[floorMin, floorMax]}
                  onChange={handleSliderChangeFloor}
                  valueLabelDisplay="auto"
                  min={-3}
                  max={20}
                  step={1}
                />
              </div>
              <div className="box-row width80p " style={{marginTop:10,marginBottom:20 }}>
                <Box className="add-property-half-width margin-r10">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Όροφος Από"
                      placeholder="π.χ. -1"
                      value={floorMin}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setFloorMin(valueAsNumber);
                        } else {
                          setFloorMin('');
                        }
                      }}
                      
                    />
                  </FormControl>
                </Box>
                <Box className="add-property-half-width margin-r10">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Όροφος Έως"
                      placeholder="π.χ. 5"
                      value={floorMax}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setFloorMax(valueAsNumber);
                        } else {
                          setFloorMax('');
                        }
                      }}
                      
                    />
                  </FormControl>
                </Box>
              </div>
              <div className="box-row width80p ">
                <Slider
                  value={[yearMin, yearMax]}
                  onChange={handleSliderChangeYear}
                  valueLabelDisplay="auto"
                  min={1950}
                  max={2023}
                  marks={[
                    {
                      value: 1950,
                      label: 'Πριν το 1950',
                    },
                    {
                      value: 2023,
                      label: 'Σήμερα',
                    },
                  ]}
                  step={5}
                />
              </div>
              <div className="box-row width80p " style={{marginTop:10,marginBottom:20 }}>
                <Box className="add-property-half-width margin-r10">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Έτος Κατασκευής Από"
                      placeholder="π.χ. 1950"
                      value={yearMin}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setYearMin(valueAsNumber);
                        } else {
                          setYearMin('');
                        }
                      }}
                      
                    />
                  </FormControl>
                </Box>
                <Box className="add-property-half-width margin-r10">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      InputLabelProps={{ shrink: true }}
                      label="Έτος Κατασκευής Έως"
                      placeholder="π.χ. 2023"
                      value={yearMax}
                      onChange={(event) => {
                        const valueAsNumber = parseInt(event.target.value, 10);
                        if (!isNaN(valueAsNumber)) {
                          setYearMax(valueAsNumber);
                        } else {
                          setYearMax('');
                        }
                      }}
                      
                    />
                  </FormControl>
                </Box>
              </div>

              
            </div>
          )}
          {step === 3 && (
            <div className="popup-container">
              <span className="font-inter15 margin-t2p ">
                Επιλέξτε επιπλέον χαρακτηριστικά του ακινήτου
              </span>
              <div className="box-row width80p flex-wrap ">
                <Button
                  className="margin-r10"
                  variant={hasViewSea ? "contained" : "outlined"}
                  onClick={() => setHasViewSea(!hasViewSea)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Θέα σε Θάλασσα
                </Button>
                <Button
                  className="margin-r10"
                  variant={hasViewMountain ? "contained" : "outlined"}
                  onClick={() => setHasViewMountain(!hasViewMountain)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Θέα σε Βουνό
                </Button>
                <Button
                  className="margin-r10"
                  variant={hasViewScene ? "contained" : "outlined"}
                  onClick={() => setHasViewScene(!hasViewScene)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Θέα σε Μνημείο
                </Button>
                <Button
                  className="margin-r10"
                  variant={hasStorage ? "contained" : "outlined"}
                  onClick={() => setHasStorage(!hasStorage)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Αποθήκη
                </Button>
                <Button
                  className="margin-r10"
                  variant={hasFirePlace ? "contained" : "outlined"}
                  onClick={() => setHasFirePlace(!hasFirePlace)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Τζάκι
                </Button>
                <Button
                  className="margin-r10"
                  variant={hasPool ? "contained" : "outlined"}
                  onClick={() => setHasPool(!hasPool)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Πισίνα
                </Button>
                <Button
                  className="margin-r10"
                  variant={hasParking ? "contained" : "outlined"}
                  onClick={() => setHasParking(!hasParking)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Πάρκινγκ
                </Button>
                <Button
                  className="margin-r10"
                  variant={hasAutonomousHeating ? "contained" : "outlined"}
                  onClick={() => setHasAutonomousHeating(!hasAutonomousHeating)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Αυτόνομη Θέρμανση
                </Button>
                <Button
                  className="margin-r10"
                  variant={isCorner ? "contained" : "outlined"}
                  onClick={() => setIsCorner(!isCorner)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Γωνιακό
                </Button>
                <Button
                  className="margin-r10"
                  variant={isKeep ? "contained" : "outlined"}
                  onClick={() => setIsKeep(!isKeep)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Διατηρητέο
                </Button>
                <Button
                  className="margin-r10"
                  variant={isBeingBuilt ? "contained" : "outlined"}
                  onClick={() => setIsBeingBuilt(!isBeingBuilt)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Υπό Κατασκευή
                </Button>
                <Button
                  className="margin-r10"
                  variant={isStreetSquare ? "contained" : "outlined"}
                  onClick={() => setIsStreetSquare(!isStreetSquare)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Σε Δρόμο με πλατεία
                </Button>
                <Button
                  className="margin-r10"
                  variant={hasNoElevator ? "contained" : "outlined"}
                  onClick={() => setHasNoElevator(!hasNoElevator)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Χωρίς ασανσέρ
                </Button>
                <Button
                  className="margin-r10"
                  variant={isDamaged ? "contained" : "outlined"}
                  onClick={() => setIsDamaged(!isDamaged)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Έχει Ζημιά
                </Button>
                <Button
                  className="margin-r10"
                  variant={isDestroy ? "contained" : "outlined"}
                  onClick={() => setIsDestroy(!isDestroy)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Απαλλοτριωτέο
                </Button>
                <Button
                  className="margin-r10"
                  variant={isInternal ? "contained" : "outlined"}
                  onClick={() => setInternal(!isInternal)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Εσωτερικό
                </Button>
                <Button
                  className="margin-r10"
                  variant={hasOwners ? "contained" : "outlined"}
                  onClick={() => setHasOwners(!hasOwners)}
                  sx={{ textTransform: "none", margin: "0px 10px 10px 0px" }}
                >
                  <AddIcon fontSize="small" style={{ marginBottom: 2 }} />
                  Έχει πάνω από 1 ιδιοκτήτη
                </Button>
              </div>
            </div>
          )}
           {step === 4 && (
              <div className="popup-container-radio">
                <span className="font-inter-b16">Μια ερώτητηση ακόμα!</span>
                <span className="font-inter15 margin-t2p bluish">
                  Τι σχέση έχεις με το ακίνητο που ψάχνεις;
                </span>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={contactType}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="OWNER"
                      onClick={() => setContactType("OWNER")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Είμαι ιδιοκτήτης"
                    />
                    <FormControlLabel
                      value="RENTER"
                      onClick={() => setContactType("RENTER")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Είμαι ενοικιαστής"
                    />
                    <FormControlLabel
                      value="CLIENT_SALE"
                      onClick={() => setContactType("CLIENT_SALE")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Ενδιαφέρομαι να αγοράσω"
                    />
                    <FormControlLabel
                      value="CLIENT_RENT"
                      onClick={() => setContactType("CLIENT_RENT")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Εδιαφέρομαι να νοικιάσω"
                    />
                    <FormControlLabel
                      value="REALTOR"
                      onClick={() => setContactType("REALTOR")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Είμαι σύμβουλος ακινήτων (μεσίτης)"
                    />
                    <FormControlLabel
                      value="CURIOUS"
                      onClick={() => setContactType("CURIOUS")}
                      className="font-inter15 bluish"
                      control={<Radio />}
                      label="Από απλή περιέργεια"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {step === 5 && (
              <div className="popup-container">
                <span className="font-inter-b16">Έμεινε κάτι ακόμα!</span>
                <span className="font-inter15 margin-t1p margin-b1p bluish">
                  Παρακαλώ συμπληρώστε τα στοιχεία σας
                </span>
                <div className="box-row width80p ">
                  <Box className="add-property-half-width margin-r10">
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        label="Όνομα"
                        // placeholder="π.χ. 75"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                      />
                    </FormControl>
                  </Box>
                  <Box className="add-property-half-width ">
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        label="Επώνυμο"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                      />
                    </FormControl>
                  </Box>
                </div>
                <div className="box-row width80p">
                  <Box className="add-property-half-width margin-r10">
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        label="Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </FormControl>
                  </Box>
                  <Box className="add-property-half-width ">
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        label="Τηλέφωνο"
                        value={phoneNumber}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                      />
                    </FormControl>
                  </Box>
                </div>
                <div className="box-row width80p ">
                  <Checkbox
                    checked={accepted}
                    onChange={() => setAccepted(!accepted)}
                  />
                  <span className="font-inter15 margin-t2p bluish">
                    {" "}
                    Διάβασα και συμφωνώ με την{" "}
                    <span className="font-inter15 red">
                      Πολιτική Απορρήτου
                    </span>{" "}
                    και τους{" "}
                    <span className="font-inter15 red">
                      όρους Προστασίας Προσσωπικών Δεδομένων
                    </span>{" "}
                  </span>
                </div>
              </div>
            )}


          <div className="row-center-center margin-b10">
            {step > 1 && (
              <button
                className="footer-button-left"
                onClick={() => setStep(step - 1)}
              >
                {" "}
                Πίσω
              </button>
            )}
            <button
              className="footer-button-right"
              onClick={() => {
                if (step < 5) {
               
                    if (step === 3 && !showForm) {
                      send();
                    } else {
                      setStep(step + 1);
                    }
                
                } else {
                  send();
                }
              }}
            >
              {" "}
              {step === 5 ? "Δες τα αποτελέσματα" : " Επόμενο Βήμα"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SearchArea;
