import React from "react";
import "./RatingBar.css";

const calculateWidthAndColor = (drivingDistance) => {
  const maxWidth = 60; // Maximum driving distance in minutes
  const percentage = (drivingDistance / maxWidth) * 100;
  let colorClass = "";

  if (drivingDistance <= 20) {
    colorClass = "rating-green";
  } else if (drivingDistance > 20 && drivingDistance <= 40) {
    colorClass = "rating-orange";
  } else if (drivingDistance > 40 && drivingDistance <= 60) {
    colorClass = "rating-red";
  }

  return { width: `${percentage}%`, colorClass };
};

const RatingBar = ({ drivingDistance }) => {
  const { width, colorClass } = calculateWidthAndColor(drivingDistance);

  return (
    <div className="rating">
      <div className={`rating-color ${colorClass}`} style={{ width }}></div>
    </div>
  );
};

export default RatingBar;
