import React, { useRef, useState, useEffect } from "react";

function EllipsisText({ text, maxWidth }) {
  const [truncatedText, setTruncatedText] = useState("");

  useEffect(() => {
    const element = document.createElement("div");
    element.style.whiteSpace = "nowrap";
    element.style.position = "absolute";
    element.style.visibility = "hidden";
    element.style.width = "auto";
    document.body.appendChild(element);

    element.textContent = text;

    if (element.offsetWidth <= maxWidth) {
      // If the text fits within the maxWidth, we don't need to truncate.
      setTruncatedText(text);
      document.body.removeChild(element);
      return;
    }

    let words = text.split(" ");

    while (words.length > 0) {
      element.textContent = `${words.join(" ")}...`;

      if (element.offsetWidth <= maxWidth) {
        setTruncatedText(element.textContent);
        break;
      }

      words.pop();
    }

    document.body.removeChild(element);
  }, [text, maxWidth]);

  return (
    <div
      style={{
        maxWidth: `${maxWidth}px`,
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: `${maxWidth}px`,
      }}
    >
      {truncatedText}
    </div>
  );
}

export default EllipsisText;
