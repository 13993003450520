import "./ResultsPage.css";
import NavBar from "../../components/nav-bar/NavBar";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AboutEstimates from "../../components/about-estimates/AboutEstimates";
import axios from "axios";
import { useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import MapComponent from "../../components/map/Map";
import PriceChart from "../../components/charts/LineChart";
import BarChart from "../../components/charts/BarChart";
import { Link } from "react-router-dom";
import FrequentQuestions from "../../components/frequent-questions/FrequentQuestions";
import SearchProperty from "../../components/search-property/SearchProperty";
import RatingBar from "../../components/rating-bar/RatingBar";
import EllipsisText from "../../components/ellipsis-text/EllipsisText";
import percentageDifference from "../../utils/calculateDiff";
import PropertyRatingBar from "../../components/property-rating-bar/PropertyRatingBar";
import ContactUs from "../../components/contact-us/ContactUs";
import ReportPrice from "../../components/report-price/ReportPrice";

function ResultsPage() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const price = useSelector((state) => state.price);
  const [reportPrice, setReportPrice] = React.useState(false);
  const [neighborhood, setNeighborhood] = useState("");
  const [isFirst, setIsFirst] = useState(true);
  const [priceResponseBuy, setPriceResponseBuy] = useState({});
  const [priceResponseRent, setPriceResponseRent] = useState({});
  const [priceData, setPriceData] = useState({});
  const [priceDataRent, setPriceDataRent] = useState({});
  const [distances, setDistances] = useState([]);
  const [placesInterest, setPlacesInterest] = useState([]);
  const dataFetchedPlacesRef = React.useRef(false);
  const dataFetchedBuyRef = React.useRef(false);
  const dataFetchedRentRef = React.useRef(false);
  const [priceDataPoints, setPriceDataPoints] = React.useState([]);
  const [priceSqmDataPoints, setPriceSqmDataPoints] = React.useState([]);
  const [sqmDataPoints, setSqmDataPoints] = React.useState([]);
  const [googleCity, setGoogleCity] = useState("");
  const [googleNeighborhood, setGoogleNeighborhood] = useState("");
  const [showCalculate, setShowCalculate] = React.useState(false);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [request, setRequest] = useState({});
  const [marker, setMarker] = useState({});
  const [areaButton, setAreaButton] = React.useState(1000);
  const contactRef = useRef(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 510
      ) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [isFirst]);
  useEffect(() => {
    if (priceData["100"]) {
      setPriceResponseBuy(priceData[areaButton.toString()]);
      setPriceResponseRent(priceDataRent[areaButton.toString()]);
    }
  }, [areaButton]);
  useEffect(() => {
    setFormattedAddress(price.marker?.formatted_address);
    setMarker(price.marker);
    setRequest(price.request);
  }, [price]);

  // useEffect(() => {
  //   marker.address_components.forEach((component) => {
  //     if (component.types.includes("locality")) {
  //       setGoogleCity(component.long_name);
  //     } else if (component.types.includes("administrative_area_level_3")) {
  //       setGoogleNeighborhood(component.long_name);
  //     }
  //   });
  // }, [price]);

  const handlePrint = () => {
    window.print();
  };

  React.useEffect(() => {
    if (priceResponseBuy.price) {
      let tmp_price = [["Price", "Count", { role: "style" }]];
      let slice = parseInt(
        (priceResponseBuy.price?.max - priceResponseBuy.price?.min) / 10 / 2
      );
      priceResponseBuy.stat_price.forEach((stat, i) => {
        let tmp = [(slice * (i + 1)).toString() + " €", stat, "#bb7ab2"];

        tmp_price.push(tmp);
      });
      let tmp_price_per_sqm = [["Price Per Sqm", "Count", { role: "style" }]];
      let slice_price_per_sqm = parseInt(
        (priceResponseBuy.price_per_sqm?.max -
          priceResponseBuy.price_per_sqm?.min) /
          10 /
          2
      );
      priceResponseBuy.stat_price_sqm.forEach((stat, i) => {
        let tmp = [(slice_price_per_sqm * (i + 1)).toString(), stat, "#77bd86"];
        tmp_price_per_sqm.push(tmp);
      });
      let tmp_sqm = [["Sqm", "Count", { role: "style" }]];
      let slice_sqm = parseInt(
        (priceResponseBuy.sqm?.max - priceResponseBuy.sqm?.min) / 10 / 2
      );
      priceResponseBuy.stat_sqm.forEach((stat, i) => {
        let tmp = [(slice_sqm * (i + 1)).toString(), stat, "#e8bb05"];
        tmp_sqm.push(tmp);
      });
      setPriceDataPoints(tmp_price);
      setPriceSqmDataPoints(tmp_price_per_sqm);
      setSqmDataPoints(tmp_sqm);
    }
  }, [priceResponseBuy]);

  const getClosePlaces = () => {
    let bodyFiller = {
      lat: marker.geometry.location.lat,
      lng: marker.geometry.location.lng,
    };
    dataFetchedPlacesRef.current = true;
    axios
      .post(`${process.env.REACT_APP_API_URL}/get_close_places`, bodyFiller)
      .then((res) => {
        console.log(res.data);

        setDistances(res.data.distances);
        setPlacesInterest(res.data.places_of_interest);
      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });
  };
  const getEmporikiBuy = () => {
    let body = request;

    axios
      .post(`${process.env.REACT_APP_API_URL}/get_emporiki_buy_bounds`, body)

      .then((res) => {
        setPriceData(res.data);

        if (res.data.foundNeighborhood) {
          setNeighborhood(res.data.neighborhood.split(",")[0]);
        }
        setPriceResponseBuy(res.data["1000"]);
        if (isFirst) {
          setIsFirst(false);
        }
        dataFetchedBuyRef.current = true;
      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });
  };
  const scrollToElement = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const getEmporikiRent = () => {
    let body = request;

    axios
      .post(`${process.env.REACT_APP_API_URL}/get_emporiki_rent_bounds`, body)

      .then((res) => {
        setPriceDataRent(res.data);
        setPriceResponseRent(res.data["1000"]);
        dataFetchedRentRef.current = true;
      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });
  };
  React.useEffect(() => {
    if (
      dataFetchedPlacesRef.current ||
      !marker ||
      !marker.geometry ||
      !request ||
      !request.geo_lat
    )
      return;

    getClosePlaces();
  }, [request, marker]);
  React.useEffect(() => {
    if (dataFetchedBuyRef.current || !request || !request.geo_lat) return;

    getEmporikiBuy();
  }, [request]);
  React.useEffect(() => {
    if (dataFetchedRentRef.current || !request || !request.geo_lat) return;

    getEmporikiRent();
  }, [request]);

  React.useEffect(() => {
    if (
      !showCalculate &&
      dataFetchedPlacesRef.current &&
      dataFetchedBuyRef.current &&
      dataFetchedRentRef.current
    ) {
      getEmporikiRent();
      getEmporikiBuy();
      getClosePlaces();
    }
  }, [showCalculate]);

  return (
    <div className="na-main">
      <NavBar />
      {marker.geometry &&
      priceResponseBuy.count >= 0 &&
      priceResponseRent.count >= 0 ? (
        <div>
          <ReportPrice
            show={reportPrice}
            onClose={() => setReportPrice(false)}
          />
          <SearchProperty
            show={showCalculate}
            onClose={() => setShowCalculate(false)}
            marker={marker}
          />
          {!showCalculate && !reportPrice && isVisible && (
            <div className="sticky-card">
              <div className="row-space-between-center width100p">
                <span className="font-gothic-b16 font-blue">
                  {neighborhood !== ""
                    ? neighborhood + ", " + formattedAddress
                    : formattedAddress}
                </span>
                <div style={{ display: "flex" }}>
                  <img
                    style={{ marginRight: 10, width: 43 }}
                    className="pointer"
                    src={require("../../assets/edit-icon.png")}
                    alt="developing vector"
                    onClick={() => setShowCalculate(true)}
                  />
                  <img
                    className="pointer"
                    style={{ width: 43 }}
                    src={require("../../assets/print-icon.png")}
                    alt="developing vector"
                    onClick={handlePrint}
                  />
                </div>
              </div>
              {price.request.type === "RESIDENCE" && (
                <div className="row-start-center margin-t2p flex-wrap">
                  <div className="row-start-center">
                    <img
                      style={{
                        marginRight: 10,
                        height: 18,
                        marginLeft: 20,
                        marginBottom: 5,
                      }}
                      src={require("../../assets/size.png")}
                      alt="developing vector"
                    />

                    <span className="font-inter-semi14 dark-grey">
                      {request?.area} τ.μ.
                    </span>
                  </div>
                  <div className="row-start-center">
                    <img
                      style={{
                        marginRight: 10,
                        height: 18,
                        marginLeft: 20,
                        marginBottom: 5,
                      }}
                      src={require("../../assets/couch.png")}
                      alt="developing vector"
                    />

                    <span className="font-inter-semi14 dark-grey">
                      {request?.bedrooms}
                    </span>
                  </div>
                  <div className="row-start-center">
                    <img
                      style={{
                        marginRight: 10,
                        height: 18,
                        marginLeft: 20,
                        marginBottom: 5,
                      }}
                      src={require("../../assets/calendar.png")}
                      alt="developing vector"
                    />

                    <span className="font-inter-semi14 dark-grey">
                      Έτος Κατασκευής: {request?.construction_year}
                    </span>
                  </div>
                  <div className="row-start-center">
                    <img
                      style={{
                        marginRight: 10,
                        height: 18,
                        marginLeft: 20,
                        marginBottom: 5,
                      }}
                      src={require("../../assets/business.png")}
                      alt="developing vector"
                    />

                    <span className="font-inter-semi14 dark-grey">
                      {request?.floor}
                    </span>
                  </div>
                </div>
              )}

              <div className="row-start-center margin-t1p">
                {request?.corner && (
                  <button className="rp-filter-button font-inter-m14">
                    Γωνιακό
                  </button>
                )}
                {request?.square && (
                  <button className="rp-filter-button font-inter-m14">
                    Δρόμος με Πλατεία
                  </button>
                )}
                {request?.sea_view && (
                  <button className="rp-filter-button font-inter-m14">
                    Θέα στη Θάλασσα
                  </button>
                )}
                {request?.mountain_view && (
                  <button className="rp-filter-button font-inter-m14">
                    Θέα σε Βουνό
                  </button>
                )}
                {request?.pool && (
                  <button className="rp-filter-button font-inter-m14">
                    Πισίνα
                  </button>
                )}
                {request?.parking && (
                  <button className="rp-filter-button font-inter-m14">
                    Parking
                  </button>
                )}
                {request?.storage && (
                  <button className="rp-filter-button font-inter-m14">
                    Αποθήκη
                  </button>
                )}
              </div>
            </div>
          )}
          <div className="rp-fix-column-start-start">
            <div className="row-space-between-center width100p">
              <span className="font-gothic-b28 font-blue">
                {neighborhood !== ""
                  ? neighborhood + ", " + formattedAddress
                  : formattedAddress}
              </span>

              <div className="rp-edit-print">
                <img
                  style={{ marginRight: 10, width: 43 }}
                  src={require("../../assets/edit-icon.png")}
                  alt="developing vector"
                  onClick={() => setShowCalculate(true)}
                />
                <img
                  className="ointer"
                  style={{ width: 43 }}
                  src={require("../../assets/print-icon.png")}
                  alt="developing vector"
                  onClick={handlePrint}
                />
              </div>
            </div>

            {window.innerWidth >= 500 && price.request.type === "RESIDENCE" && (
              <div className="row-start-center margin-t20 margin-b10">
                <img
                  style={{ marginRight: 10, height: 18 }}
                  src={require("../../assets/size.png")}
                  alt="developing vector"
                />

                <span className="font-inter-semi14 dark-grey">
                  {request?.area} τ.μ.
                </span>

                <img
                  style={{ marginRight: 10, height: 18, marginLeft: 20 }}
                  src={require("../../assets/couch.png")}
                  alt="developing vector"
                />

                <span className="font-inter-semi14 dark-grey">
                  {request?.bedrooms}
                </span>

                <img
                  style={{ marginRight: 10, height: 18, marginLeft: 20 }}
                  src={require("../../assets/calendar.png")}
                  alt="developing vector"
                />

                <span className="font-inter-semi14 dark-grey">
                  Έτος Κατασκευής: {request?.construction_year}
                </span>

                <img
                  style={{ marginRight: 10, height: 18, marginLeft: 20 }}
                  src={require("../../assets/business.png")}
                  alt="developing vector"
                />

                <span className="font-inter-semi14 dark-grey">
                  {request?.floor}
                </span>
              </div>
            )}
            {window.innerWidth < 500 && price.request.type === "RESIDENCE" && (
              <div className="column-start-start margin-t20 margin-b10 width100p">
                <div className="row-start-center width100p margin-b10">
                  <img
                    style={{ marginRight: 10, height: 18 }}
                    src={require("../../assets/size.png")}
                    alt="developing vector"
                  />

                  <span className="font-inter-semi14 dark-grey">
                    {request?.area}
                  </span>

                  <img
                    style={{ marginRight: 10, height: 18, marginLeft: 20 }}
                    src={require("../../assets/couch.png")}
                    alt="developing vector"
                  />

                  <span className="font-inter-semi14 dark-grey">
                    {request?.bedrooms}
                  </span>
                </div>
                <div className="row-start-center width100p">
                  <img
                    style={{ marginRight: 10, height: 18 }}
                    src={require("../../assets/calendar.png")}
                    alt="developing vector"
                  />

                  <span className="font-inter-semi14 dark-grey">
                    Έτος Κατασκευής: {request?.construction_year}
                  </span>

                  <img
                    style={{ marginRight: 10, height: 18, marginLeft: 20 }}
                    src={require("../../assets/business.png")}
                    alt="developing vector"
                  />

                  <span className="font-inter-semi14 dark-grey">
                    {request?.floor}
                  </span>
                </div>
              </div>
            )}

            <div className="row-start-center margin-t1p">
              {request?.corner && (
                <button className="rp-filter-button font-inter-m14">
                  Γωνιακό
                </button>
              )}
              {request?.square && (
                <button className="rp-filter-button font-inter-m14">
                  Δρόμος με Πλατεία
                </button>
              )}
              {request?.sea_view && (
                <button className="rp-filter-button font-inter-m14">
                  Θέα στη Θάλασσα
                </button>
              )}
              {request?.mountain_view && (
                <button className="rp-filter-button font-inter-m14">
                  Θέα σε Βουνό
                </button>
              )}
              {request?.pool && (
                <button className="rp-filter-button font-inter-m14">
                  Πισίνα
                </button>
              )}
              {request?.parking && (
                <button className="rp-filter-button font-inter-m14">
                  Parking
                </button>
              )}
              {request?.storage && (
                <button className="rp-filter-button font-inter-m14">
                  Αποθήκη
                </button>
              )}
            </div>

            <div style={{ width: "100%" }}>
              {marker && (
                <MapComponent
                  style={{ border: "1px solid black", width: "100%" }}
                  marker={marker}
                  height={"233px"}
                />
              )}
            </div>
            {/*

        <div className="column-start-center margin-t2p">
          <span className="font-inter16 dark-grey">
          */}

            {/* <div className="column-start-center">
          <span>

            Αποθήκευσε την αναζήτηση σου ώστε να την έχεις διαθέσιμη ανά πάσα
            στιγμή και για να κρατάς ιστορικό της τιμής του ακινήτου.
          </span>
          <button className="rp-save-button font-inter-semi16 white margin-t2p">
            <FavoriteBorderRoundedIcon
              style={{ width: 25, color: "white", marginRight: 10 }}
            />
            Αποθήκευση
          </button>
        </div> */}

            <div className="rp-green-img">
              <div className="green-column">
                <span className="font-gothic-b32 white">Πολλοί Αγοραστές</span>
                <span className="font-inter18 white">
                  ζητούν ακίνητο σαν αυτό
                </span>
              </div>
              <button
                onClick={scrollToElement}
                className="button-outline-white rp-button font-gothic-b16"
              >
                Θέλω να το πουλήσω!
              </button>
            </div>

            <div className="objective-cards-row">
              <div className="objective-card margin-t5p">
                <div className="row-start-center margin-b10">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Αντικειμενική αξία ακινήτου
                  </span>
                </div>

                {price.request.type !== "LAND" && (
                  <span className="font-inter-semi30 font-blue margin-b20">
                    {parseFloat(price.antikeimeniki).toLocaleString("en-US")} €
                  </span>
                )}
                {priceResponseBuy.count > 0 &&
                  price.request.type !== "LAND" && (
                    <span className="font-inter-m20 red">
                      (
                      {percentageDifference(
                        price.antikeimeniki,
                        priceResponseBuy.price?.price
                      )}{" "}
                      της εμπορικής αξίας)
                    </span>
                  )}
                {price.request.type === "LAND" && (
                  <span className="font-inter16 font-blue margin-b20">
                    Για τον υπολογισμό της αντικειμενικής αξίας χρειάζονται
                    περισσότερα δεδομένα. Παρακαλώ μεταβείτε στη &nbsp;
                    <Link to="https://maps.gsis.gr/valuemaps/#">
                      σελίδα του gov.gr
                    </Link>{" "}
                  </span>
                )}
              </div>

              <div className="objective-card margin-t5p">
                <div className="row-start-center margin-b10">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">Τιμή Ζώνης</span>
                </div>

                <span className="font-inter-semi30 font-blue margin-b20">
                  {price.zonis.split("=")[1]}
                </span>
              </div>

              {/* <div className="objective-card margin-t5p">
                <div className="row-start-center margin-b10">
                  <img
                    style={{ width: 24, marginRight: 15 }}
                    src={require("../../assets/price-tag-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">ΕΝΦΙΑ</span>
                </div>

                <span className="font-inter-semi30 font-blue margin-b20">
                  placeholder €
                </span>

                <span className="font-inter-m20 red">placeholder</span>
              </div> */}
            </div>

            <div className="area-buttons-back">
              <span className="font-gothic-b28 font-blue">
                Αποτελέσματα εμπορικής αξίας ακινήτου
              </span>

              {window.innerWidth >= 850 ? (
                <div className="row-start-center ">
                  <button
                    className="area-meter-button font-gothic-b14 margin-t20"
                    onClick={() => setAreaButton(100)}
                    style={{
                      color: areaButton === 100 ? "white" : "#263573",
                      backgroundColor:
                        areaButton === 100 ? "#e52628" : "transparent",
                      border: areaButton === 100 ? "none" : "2px solid #263573",
                    }}
                  >
                    σε ακτίνα 100 μέτρων
                  </button>
                  <button
                    className="area-meter-button font-gothic-b14 margin-t20"
                    onClick={() => setAreaButton(500)}
                    style={{
                      color: areaButton === 500 ? "white" : "#263573",
                      backgroundColor:
                        areaButton === 500 ? "#e52628" : "transparent",
                      border: areaButton === 500 ? "none" : "2px solid #263573",
                    }}
                  >
                    σε ακτίνα 500 μέτρων
                  </button>
                  <button
                    className="area-meter-button font-gothic-b14 margin-t20"
                    onClick={() => setAreaButton(1000)}
                    style={{
                      color: areaButton === 1000 ? "white" : "#263573",
                      backgroundColor:
                        areaButton === 1000 ? "#e52628" : "transparent",
                      border:
                        areaButton === 1000 ? "none" : "2px solid #263573",
                    }}
                  >
                    σε ακτίνα 1000 μέτρων
                  </button>
                  <button
                    className="area-meter-button font-gothic-b14 margin-t20"
                    onClick={() => setAreaButton(2000)}
                    style={{
                      color: areaButton === 2000 ? "white" : "#263573",
                      backgroundColor:
                        areaButton === 2000 ? "#e52628" : "transparent",
                      border:
                        areaButton === 2000 ? "none" : "2px solid #263573",
                    }}
                  >
                    σε ακτίνα 2000 μέτρων
                  </button>
                </div>
              ) : (
                <Box
                  className="box-select search-mob-margin-r20"
                  style={{
                    color: "#263573",
                    backgroundColor: "transparent",
                    border: "2px solid #263573",
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      sx={{ border: 0 }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={areaButton}
                      onChange={(event) =>
                        setAreaButton(parseInt(event.target.value))
                      }
                    >
                      <MenuItem value={100}>σε ακτίνα 100 μέτρων</MenuItem>
                      <MenuItem value={500}>σε ακτίνα 500 μέτρων</MenuItem>
                      <MenuItem value={1000}>σε ακτίνα 1000 μέτρων</MenuItem>
                      <MenuItem value={2000}>σε ακτίνα 2000 μέτρων</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {priceResponseBuy.count > 0 && (
                <span className="font-inter18 font-deep-grey margin-t20">
                  Βλέπετε αποτελέσματα εμπορικής αξίας με δείγμα ακινήτων που
                  βρίσκεται σε απόσταση {areaButton} μέτρων από το ακίνητο που
                  αναζητήσατε
                </span>
              )}
            </div>

            {/* <div className="rp-temp-placeholder"></div> */}
            {priceResponseBuy.count === 0 && priceResponseRent.count === 0 && (
              <div className="objective-card-full margin-t2p">
                <div className="objective-full-row">
                  <img
                    style={{ marginTop: 40, marginRight: 40, width: 120 }}
                    src={require("../../assets/nores.PNG")}
                    alt="developing vector"
                  />
                  <div className="column-space-between-start ">
                    <span
                      className="font-gothic-b40 red "
                      style={{ marginTop: 40, textAlign: "center" }}
                    >
                      Δεν βρέθηκαν δεδομένα
                    </span>
                    <span className="font-inter20 margin-t2p font-blue">
                      Συγγνώμη, αυτή τη στιγμή δεν είμαστε σε θέση να
                      προσφέρουμε online εκτίμηση για την περιοχή σας, αλλά
                      είμαστε στη διαδικασία επέκτασης σε νέες τοποθεσίες.
                    </span>
                    <button
                      className="button-red font-gothic-b16 white margin-t2p pointer"
                      onClick={() => navigate("/")}
                      style={{ marginBottom: 40 }}
                    >
                      Νέα Αναζήτηση
                    </button>
                  </div>
                </div>
              </div>
            )}
            {priceResponseBuy.count > 0 && (
              <div className="rp-stats-card margin-t2p">
                <div className="rp-stats-left width50p">
                  <div className="column-start-start width100p">
                    <div className="rp-horizontal-stats-card-top">
                      <img
                        style={{ marginRight: 15, width: 37 }}
                        src={require("../../assets/stats-icon-buy.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Τιμή Πώλησης
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>

                    <div className="rp-stats-card-bottom">
                      <div className="row-space-between-center width100p margin-b5">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span className="font-inter-b14 red">Μέση τιμή</span>
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                      </div>
                      {priceResponseBuy.price && (
                        <div className="row-space-between-center width100p margin-b10">
                          <span className="font-inter-semi18 font-blue">
                            {parseFloat(
                              parseFloat(priceResponseBuy.price?.min).toFixed(0)
                            ).toLocaleString()}{" "}
                            €
                          </span>
                          <div className="stats-bottom-line1"></div>
                          <span className="na-font-gothic-b34-20 font-blue">
                            {parseFloat(
                              parseFloat(priceResponseBuy.price?.price).toFixed(
                                0
                              )
                            ).toLocaleString()}{" "}
                            €
                          </span>
                          <div className="stats-bottom-line1"></div>
                          <span className="font-inter-semi18 font-blue">
                            {parseFloat(
                              parseFloat(priceResponseBuy.price?.max).toFixed(0)
                            ).toLocaleString()}{" "}
                            €
                          </span>
                        </div>
                      )}
                      {priceResponseBuy.price_per_sqm && (
                        <div className="row-space-between-center width100p">
                          <span
                            style={{ opacity: 0.5 }}
                            className="font-inter-semi16 font-blue"
                          >
                            {parseFloat(
                              parseFloat(
                                priceResponseBuy.price_per_sqm?.min
                              ).toFixed(0)
                            ).toLocaleString()}{" "}
                            €/τμ
                          </span>
                          <div className="stats-bottom-line2"></div>
                          <span
                            style={{ opacity: 0.5 }}
                            className="font-inter-b18 font-blue"
                          >
                            {parseFloat(
                              parseFloat(
                                priceResponseBuy.price_per_sqm?.price
                              ).toFixed(0)
                            ).toLocaleString()}{" "}
                            €/τμ
                          </span>
                          <div className="stats-bottom-line2"></div>
                          <span
                            style={{ opacity: 0.5 }}
                            className="font-inter-semi16 font-blue"
                          >
                            {parseFloat(
                              parseFloat(
                                priceResponseBuy.price_per_sqm?.max
                              ).toFixed(0)
                            ).toLocaleString()}{" "}
                            €/τμ
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="rp-appraisal">
                      <span className="font-inter14 deep-grey">
                        Αξιοπιστία Εκτίμησης
                      </span>
                      <PropertyRatingBar count={priceResponseBuy.count} />
                    </div>
                  </div>

                  <div className="row-start-center">
                    <span
                      onClick={() => setReportPrice(true)}
                      className="font-inter16 deep-grey underline-small pointer"
                    >
                      {" "}
                      Υπάρχει μεγάλη απόκλιση τιμής;
                    </span>
                    <ArrowForwardIosRoundedIcon
                      style={{ color: "#7a83aa", fontSize: 20 }}
                    />
                  </div>
                </div>

                <div className="rp-stats-right">
                  {priceResponseBuy.data &&
                    priceResponseBuy.data.length > 0 && (
                      <PriceChart
                        data={priceResponseBuy.data}
                        transactionType={"BUY"}
                        style={{ width: "100%" }}
                      />
                    )}
                </div>
              </div>
            )}
            {priceResponseRent.count > 0 && (
              <div className="rp-stats-card">
                <div className="rp-stats-left">
                  <div className="column-start-start width100p">
                    <div className="stats-card-top">
                      <img
                        style={{ marginRight: 15, width: 37 }}
                        src={require("../../assets/stats-icon-buy.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b18 font-blue">
                        Τιμή Ενοικίασης
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>

                    <div className="rp-stats-card-bottom">
                      <div className="row-space-between-center width100p margin-b5">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                      </div>
                      {priceResponseRent.price && (
                        <div className="row-space-between-center width100p margin-b10">
                          <span className="font-inter-semi18 font-blue">
                            {parseFloat(
                              parseFloat(priceResponseRent.price?.min).toFixed(
                                0
                              )
                            ).toLocaleString()}{" "}
                            €
                          </span>
                          <div className="stats-bottom-line1"></div>
                          <span className="na-font-gothic-b34-20 font-blue">
                            {parseFloat(
                              parseFloat(
                                priceResponseRent.price?.price
                              ).toFixed(0)
                            ).toLocaleString()}{" "}
                            €
                          </span>
                          <div className="stats-bottom-line1"></div>
                          <span className="font-inter-semi18 font-blue">
                            {parseFloat(
                              parseFloat(priceResponseRent.price?.max).toFixed(
                                0
                              )
                            ).toLocaleString()}{" "}
                            €
                          </span>
                        </div>
                      )}
                      {priceResponseRent.price_per_sqm && (
                        <div className="row-space-between-center width100p">
                          <span
                            style={{ opacity: 0.5 }}
                            className="font-inter-semi16 font-blue"
                          >
                            {parseFloat(
                              parseFloat(
                                priceResponseRent.price_per_sqm?.min
                              ).toFixed(0)
                            ).toLocaleString()}{" "}
                            €/τμ
                          </span>
                          <div className="stats-bottom-line2"></div>
                          <span
                            style={{ opacity: 0.5 }}
                            className="font-inter-b18 font-blue"
                          >
                            {parseFloat(
                              parseFloat(
                                priceResponseRent.price_per_sqm?.price
                              ).toFixed(0)
                            ).toLocaleString()}{" "}
                            €/τμ
                          </span>
                          <div className="stats-bottom-line2"></div>
                          <span
                            style={{ opacity: 0.5 }}
                            className="font-inter-semi16 font-blue"
                          >
                            {parseFloat(
                              parseFloat(
                                priceResponseRent.price_per_sqm?.max
                              ).toFixed(0)
                            ).toLocaleString()}{" "}
                            €/τμ
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="rp-appraisal margin-t2p">
                      <span className="font-inter14 dark-grey">
                        Αξιοπιστία Εκτίμησης
                      </span>
                      <PropertyRatingBar count={priceResponseRent.count} />
                    </div>
                  </div>

                  <div className="row-start-center margin-t5p">
                    <span
                      onClick={() => setReportPrice(true)}
                      className="font-inter16 deep-grey underline-small pointer"
                    >
                      {" "}
                      Υπάρχει μεγάλη απόκλιση τιμής;
                    </span>
                    <ArrowForwardIosRoundedIcon
                      style={{
                        width: 15,
                        color: "rgba(38, 53, 115, 0.3)",
                        marginLeft: 10,
                      }}
                    />
                  </div>
                </div>

                <div className="rp-stats-right">
                  {priceResponseRent.data &&
                    priceResponseRent.data.length > 0 && (
                      <PriceChart
                        data={priceResponseRent.data}
                        transactionType={"RENT"}
                        style={{ width: "100%" }}
                      />
                    )}
                </div>
              </div>
            )}

            {/* <div className="rp-history-back margin-t5p">
          <div className="rp-hist-pad">
            <div className="row-start-center">
              <img
                style={{ height: 30 }}
                src={require("../../assets/exchange-icon.png")}
                alt="developing vector"
              />
              <span className="font-gothic-b22 font-blue">
                Ιστορικό Ακινήτου
              </span>
            </div>
            <span className="font-inter18 font-blue">
              Σύγκριση μέσης τιμής πώλησης με βάση την τελευταία σου αναζήτηση.
            </span>
          </div> */}
            {/* <div className="rp-history-middle">
            <div className="history-middle">
              <button className="month-button font-inter-m14 white margin-b10">
                Μάϊος 2023
              </button>
              <span className="font-inter-b14 red margin-b10">
                Εκτίμηση αξίας ακινήτου
              </span>
              <span className="font-inter-m40 font-blue margin-b10">
                550.000 €
              </span>
              <div className="load margin-b20">
                <span className="font-inter14 font-light-grey">
                  Αξιοπιστία Εκτίμησης
                </span>
              </div>
              <span className="font-inter16 dark-grey">
                Εκτίμηση εύρους αξίας
              </span>
              <span className="font-gothic-b22 font-blue">
                από 100.000 € εώς 500.000 €
              </span>
            </div>

            <div className="history-middle">
              <button className="month-button font-inter-m14 white margin-b10">
                Ιούλɩος 2023
              </button>
              <span className="font-inter-b14 red margin-b10">
                Εκτίμηση αξίας ακινήτου
              </span>
              <span className="font-inter-m40 font-blue margin-b10">
                370.000 €
              </span>

              <div className="load margin-b20">
                <span className="font-inter14 font-light-grey">
                  Αξιοπιστία Εκτίμησης
                </span>
              </div>
              <span className="font-inter16 dark-grey">
                Εκτίμηση εύρους αξίας
              </span>
              <span className="font-gothic-b22 font-blue">
                από 333.000 € εώς 407.000 €
              </span>
            </div>
          </div>
        </div> */}

            <div className="row-space-between-center-rp distance-rp  margin-t5p">
              <div className="column-start-start width100p">
                <div className="rp-row-wrap-left margin-b20">
                  <img
                    style={{ width: 35, marginRight: 10 }}
                    src={require("../../assets/pillars-icon.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Απόσταση από σημεία ενδιαφέροντος
                  </span>
                </div>

                <div className="points-interests">
                  <div className="points-interests-row-titles border-bottom">
                    <span className="font-gothic-b18 font-blue">Περιοχή</span>
                    <span className="font-gothic-b18 font-blue">Απόσταση</span>
                  </div>

                  {placesInterest.map((point, i) => (
                    <div key={i} className="points-interests-row border-bottom">
                      <div className="row-start-center noWrapText ">
                        <img
                          style={{ width: 26, marginRight: 10 }}
                          src={require("../../assets/location-icon.png")}
                          alt="developing vector"
                        />
                        {window.innerWidth >= 410 ? (
                          <EllipsisText
                            text={point.name}
                            maxWidth={200}
                            className="font-inter16 dark-grey"
                          />
                        ) : (
                          <EllipsisText
                            text={point.name}
                            maxWidth={150}
                            className="font-inter16 dark-grey"
                          />
                        )}
                      </div>

                      <div className="row-start-center ">
                        <img
                          style={{ width: 26, marginRight: 10 }}
                          src={require("../../assets/car-icon.png")}
                          alt="developing vector"
                        />
                        <span className="font-gothic-b16 dark-grey">
                          {parseInt(point.car_duration)}'
                        </span>
                        <RatingBar
                          drivingDistance={parseInt(point.car_duration)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="column-start-end width100p">
                <div className="rp-row-wrap-right margin-b20">
                  <img
                    style={{ width: 35, marginRight: 10 }}
                    src={require("../../assets/pillars-icon2.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b22 font-blue">
                    Αποστάσεις από κοντινότερες συγκοινωνίες
                  </span>
                </div>

                <div className="points-interests">
                  <div className="points-interests-row-titles border-bottom">
                    <span className="font-gothic-b18 font-blue">Σταθμός</span>
                    <span className="font-gothic-b18 font-blue">Απόσταση</span>
                  </div>
                  {distances.map((point, i) => (
                    <div key={i} className="points-interests-row border-bottom">
                      <div className="row-start-center">
                        {point.type === "bus_station" ? (
                          <img
                            style={{ width: 22, marginRight: 10 }}
                            src={require("../../assets/bus-icon.png")}
                            alt="developing vector"
                          />
                        ) : (
                          <img
                            style={{ width: 22, marginRight: 10 }}
                            src={require("../../assets/train-icon.png")}
                            alt="developing vector"
                          />
                        )}
                        {window.innerWidth >= 431 ? (
                          <EllipsisText
                            text={point.name}
                            maxWidth={200}
                            className="font-inter16 dark-grey"
                          />
                        ) : (
                          <EllipsisText
                            text={point.name}
                            maxWidth={140}
                            className="font-inter16 dark-grey"
                          />
                        )}
                      </div>

                      <div className="row-end-center">
                        <img
                          style={{ width: 26, marginRight: 10 }}
                          src={require("../../assets/car-icon.png")}
                          alt="developing vector"
                        />
                        <span className="font-gothic-b16 dark-grey">
                          {parseInt(point.walk_duration)}'
                        </span>
                        <RatingBar
                          drivingDistance={parseInt(point.walk_duration)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="rp-yellow-img">
              <span className="font-gothic-b28 white">
                Θες μια ακόμα πιο αξιόπιστη εκτίμηση;
              </span>
              <span style={{ marginLeft: "7%" }} className="font-inter18 white">
                Μίλησε με έναν{" "}
                <span className="font-inter-b18 white">newdeal expert</span>
              </span>
              <button
                onClick={scrollToElement}
                className="button-outline-white rp-button  font-gothic-b16"
              >
                Κλείσε ραντεβού
              </button>
            </div>

            <span className="font-gothic-b22 font-blue margin-t5p">
              Έρευνα αγοράς για περιοχή {request.google_city}
              {request.google_meighborhood !== "" && ","}{" "}
              {neighborhood !== "" ? neighborhood : request.google_neighborhood}
            </span>

            <span className="font-inter16 font-blue">
              Τα παρακάτω αποτελέσματα αφορούν ακίνητα με τα ίδια χαρακτηριστικά
              με ακίνητο της αναζήτησης σας.
            </span>

            <div className="rn-properties margin-b20">
              <div className="rn-card-top">
                <div className="mob-rn-row-start-center">
                  <img
                    className="rn-card-icon1"
                    src={require("../../assets/house-simple.png")}
                    alt="developing vector"
                  />

                  <span className="font-gothic-b22 font-blue">
                    Αριθμός ομοειδών ακινήτων στην περιοχή
                  </span>
                </div>
                <span className="font-inter-m40 font-blue">
                  {priceResponseBuy.count + priceResponseRent.count}
                  &nbsp;ακίνητα
                </span>
              </div>
            </div>
            {priceResponseBuy.count > 0 && priceResponseRent.count > 0 && (
              <div className="rn-properties margin-b20">
                {window.innerWidth >= 550 ? (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Τιμές Πώλησης
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseBuy.price?.min}
                        stat={priceResponseBuy.stat_price}
                        slice={priceResponseBuy.price?.slice}
                        max={priceResponseBuy.price?.max}
                        color={"#bb7ab2"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή πώλησης σε αυτήν την
                        περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.price?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </span>
                    </div>

                    <div className="rn-card-b width100p">
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.price?.min).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>

                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-gothic-b34 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.price?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>

                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.price?.max).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseBuy.count} ακίνητα
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Τιμές Πώλησης
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseBuy.price?.min}
                        stat={priceResponseBuy.stat_price}
                        slice={priceResponseBuy.price?.slice}
                        max={priceResponseBuy.price?.max}
                        color={"#bb7ab2"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή πώλησης σε αυτήν την
                        περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.price?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </span>
                    </div>

                    <div className="mob-rn-card-b width100p">
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.price?.min).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-gothic-b20 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.price?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.price?.max).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseBuy.count} ακίνητα
                      </span>
                    </div>
                  </div>
                )}

                {window.innerWidth >= 550 ? (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Τιμές Ενοικίασης
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseRent.price?.min}
                        stat={priceResponseRent.stat_price}
                        slice={priceResponseRent.price?.slice}
                        max={priceResponseRent.price?.max}
                        color={"#bb7ab2"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή ενοικίασης σε αυτήν την
                        περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.price?.price).toFixed(
                              0
                            )
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </span>
                    </div>
                    <div className="rn-card-b width100p">
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.price?.min).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>

                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-gothic-b34 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.price?.price).toFixed(
                              0
                            )
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>

                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.price?.max).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseRent.count} ακίνητα
                      </span>
                    </div>{" "}
                  </div>
                ) : (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Τιμές Ενοικίασης
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseRent.price?.min}
                        stat={priceResponseRent.stat_price}
                        slice={priceResponseRent.price?.slice}
                        max={priceResponseRent.price?.max}
                        color={"#bb7ab2"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή ενοικίασης σε αυτήν την
                        περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.price?.price).toFixed(
                              0
                            )
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </span>
                    </div>
                    <div className="mob-rn-card-b width100p">
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.price?.min).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-gothic-b20 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.price?.price).toFixed(
                              0
                            )
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.price?.max).toFixed(0)
                          ).toLocaleString()}{" "}
                          €
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseRent.count} ακίνητα
                      </span>
                    </div>{" "}
                  </div>
                )}
              </div>
            )}
            {priceResponseBuy.count > 0 && priceResponseRent.count > 0 && (
              <div className="rn-properties margin-b20">
                {window.innerWidth >= 550 ? (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Τιμές ανά τ.μ. πώλησης
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseBuy.price_per_sqm?.min}
                        stat={priceResponseBuy.stat_price_sqm}
                        slice={priceResponseBuy.price_per_sqm?.slice}
                        max={priceResponseBuy.price_per_sqm?.max}
                        color={"#77bd86"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή πώλησης ανά τετραγωνικό
                        μέτρο σε αυτήν την περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseBuy.price_per_sqm?.price
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </span>
                    </div>

                    <div className="rn-card-b width100p">
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseBuy.price_per_sqm?.min
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>
                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-gothic-b34 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseBuy.price_per_sqm?.price
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>
                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">Μέγιστa</span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseBuy.price_per_sqm?.max
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseBuy.count} ακίνητα
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Τιμές ανά τ.μ. πώλησης
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseBuy.price_per_sqm?.min}
                        stat={priceResponseBuy.stat_price_sqm}
                        slice={priceResponseBuy.price_per_sqm?.slice}
                        max={priceResponseBuy.price_per_sqm?.max}
                        color={"#77bd86"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή πώλησης ανά τετραγωνικό
                        μέτρο σε αυτήν την περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseBuy.price_per_sqm?.price
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </span>
                    </div>

                    <div className="mob-rn-card-b width100p">
                      <div className="rn-column-sp-around-sqmeter">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span
                          className="font-inter-b18 font-blue"
                          style={{
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                          }}
                        >
                          {parseFloat(
                            parseFloat(
                              priceResponseBuy.price_per_sqm?.min
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>

                      <div className="rn-column-sp-around-sqmeter">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span
                          className="font-gothic-b20 font-blue"
                          style={{
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                          }}
                        >
                          {parseFloat(
                            parseFloat(
                              priceResponseBuy.price_per_sqm?.price
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>
                      <div className="rn-column-sp-around-sqmeter">
                        <span className="font-inter12 font-blue">Μέγιστa</span>
                        <span
                          className="font-inter-b18 font-blue"
                          style={{
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                          }}
                        >
                          {parseFloat(
                            parseFloat(
                              priceResponseBuy.price_per_sqm?.max
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseBuy.count} ακίνητα
                      </span>
                    </div>
                  </div>
                )}

                {window.innerWidth >= 550 ? (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Τιμές ανά τ.μ.ενοικίασης
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseRent.price_per_sqm?.min}
                        stat={priceResponseRent.stat_price_sqm}
                        slice={priceResponseRent.price_per_sqm?.slice}
                        max={priceResponseRent.price_per_sqm?.max}
                        color={"#77bd86"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή ενοικίασης ανά
                        τετραγωνικό μέτρο σε αυτήν την περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseRent.price_per_sqm?.price
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </span>
                    </div>
                    <div className="rn-card-b width100p">
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseRent.price_per_sqm?.min
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>
                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>
                      <div className="rn-column-sp-around">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-gothic-b34 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseRent.price_per_sqm?.price
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>
                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseRent.price_per_sqm?.max
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseRent.count} ακίνητα
                      </span>
                    </div>{" "}
                  </div>
                ) : (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Τιμές ανά τ.μ.ενοικίασης
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseRent.price_per_sqm?.min}
                        stat={priceResponseRent.stat_price_sqm}
                        slice={priceResponseRent.price_per_sqm?.slice}
                        max={priceResponseRent.price_per_sqm?.max}
                        color={"#77bd86"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή ενοικίασης ανά
                        τετραγωνικό μέτρο σε αυτήν την περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseRent.price_per_sqm?.price
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </span>
                    </div>
                    <div className="mob-rn-card-b width100p">
                      <div className="rn-column-sp-around-sqmeter">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseRent.price_per_sqm?.min
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>

                      <div className="rn-column-sp-around-sqmeter">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-gothic-b20 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseRent.price_per_sqm?.price
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>
                      <div className="rn-column-sp-around-sqmeter">
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(
                              priceResponseRent.price_per_sqm?.max
                            ).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseRent.count} ακίνητα
                      </span>
                    </div>{" "}
                  </div>
                )}
              </div>
            )}
            {priceResponseBuy.count > 0 && priceResponseRent.count > 0 && (
              <div className="rn-properties margin-b20">
                {window.innerWidth >= 550 ? (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Επιφάνεια τ.μ. ακινήτων
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseBuy.sqm?.min}
                        stat={priceResponseBuy.stat_sqm}
                        slice={priceResponseBuy.sqm?.slice}
                        max={priceResponseBuy.sqm?.max}
                        color={"#e8bb05"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή τετραγωνικών μέτρων για
                        ακίνητα προς πώληση σε αυτήν την περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.sqm?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </span>
                    </div>

                    <div className="rn-card-b width100p">
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.sqm?.min).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>

                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-gothic-b34 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.sqm?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>

                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.sqm?.max).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseBuy.count} ακίνητα
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Επιφάνεια τ.μ. ακινήτων
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseBuy.sqm?.min}
                        stat={priceResponseBuy.stat_sqm}
                        slice={priceResponseBuy.sqm?.slice}
                        max={priceResponseBuy.sqm?.max}
                        color={"#e8bb05"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή τετραγωνικών μέτρων για
                        ακίνητα προς πώληση σε αυτήν την περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.sqm?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </span>
                    </div>

                    <div className="rn-card-b width100p">
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.sqm?.min).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-gothic-b20 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.sqm?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseBuy.sqm?.max).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseBuy.count} ακίνητα
                      </span>
                    </div>
                  </div>
                )}

                {window.innerWidth >= 550 ? (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Επιφάνεια τ.μ. ακινήτων
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseRent.sqm?.min}
                        stat={priceResponseRent.stat_sqm}
                        slice={priceResponseRent.sqm?.slice}
                        max={priceResponseRent.sqm?.max}
                        color={"#e8bb05"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή τετραγωνικών μέτρων για
                        ακίνητα προς ενοικίαση σε αυτήν την περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.sqm?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </span>
                    </div>

                    <div className="rn-card-b width100p">
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>

                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.sqm?.min).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>
                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>
                      <div className="rn-column-sp-around">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-gothic-b34 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.sqm?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>
                      <div
                        className="rn-card-column-end"
                        style={{ width: "70%" }}
                      >
                        <div className="rn-stats-bottom-line1"></div>
                      </div>
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.sqm?.max).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseRent.count} ακίνητα
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="stats-card">
                    <div className="rn-stats-card-top">
                      <img
                        style={{ width: 24, marginRight: 15 }}
                        src={require("../../assets/price-tag-icon.png")}
                        alt="developing vector"
                      />
                      <span className="font-gothic-b22 font-blue">
                        Επιφάνεια τ.μ. ακινήτων
                      </span>
                      <HelpRoundedIcon
                        style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                      />
                    </div>
                    <div className="rn-stats-card-middle">
                      <BarChart
                        min={priceResponseRent.sqm?.min}
                        stat={priceResponseRent.stat_sqm}
                        slice={priceResponseRent.sqm?.slice}
                        max={priceResponseRent.sqm?.max}
                        color={"#e8bb05"}
                      />
                      <span className="font-inter16 font-blue">
                        Με βάση τα δεδομένα, η μέση τιμή τετραγωνικών μέτρων για
                        ακίνητα προς ενοικίαση σε αυτήν την περιοχή είναι{" "}
                        <span className="font-inter-b16 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.sqm?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          € / τ.μ.
                        </span>
                      </span>
                    </div>

                    <div className="rn-card-b width100p">
                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">
                          Ελάχιστη τιμή
                        </span>

                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.sqm?.min).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter-b12 red">Μέση τιμή</span>
                        <span className="font-gothic-b20 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.sqm?.price).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>

                      <div className="rn-column-sp-around">
                        <span className="font-inter12 font-blue">Μέγιστη</span>
                        <span className="font-inter-b18 font-blue">
                          {parseFloat(
                            parseFloat(priceResponseRent.sqm?.max).toFixed(0)
                          ).toLocaleString()}{" "}
                          τ.μ.
                        </span>
                      </div>
                    </div>
                    <div className="card-end-span">
                      <span className="font-inter-b14 deep-grey">
                        Αποτέλεσμα με δείγμα {priceResponseRent.count} ακίνητα
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="rn-img-blue margin-b40">
              <span className="font-gothic-b28 white">
                Δες γενικά στατιστικά για περιοχή {googleCity}{" "}
                {googleNeighborhood}
              </span>
              <Link to="/neighborhoodanalysis">
                {" "}
                <button className="pointer button-outline-white  font-gothic-b15">
                  Δες τα δεδομένα
                </button>
              </Link>
            </div>

            {/* <div className="nearby-back">
          <div className="row-start-center">
            <img
              style={{ marginRight: 15 }}
              src={require("../../assets/stats-icon-buy.png")}
              alt="developing vector"
            />
            <span className="font-gothic-b20 red">
              Ακίνητα που πωλούνται κοντά
            </span>
            <img
              style={{ marginRight: 25 }}
              src={require("../../assets/map-icon.png")}
              alt="developing vector"
            />
            <span className="font-gothic-b15 font-blue underline-thin">
              Δες στον χάρτη
            </span>
          </div>

          <div className="rp-exclusive-row">
            <div className="na-exclusive-card">
              <div className="na-exclusive-img">
                <div className="na-icon-row">
                  <div className="vip-icon">
                    <span className="white">VIP</span>
                  </div>
                  <div className="rental-icon">
                    <span className="white">Ενοɩκίαση</span>
                  </div>
                </div>
                <div className="na-moy-row">
                  <button className="moy-button">ΜΟΥ5674</button>

                  <button className="more-pictures-button">
                    <img
                      style={{ marginRight: 25 }}
                      src={require("../../assets/more-pictures.png")}
                      alt="developing vector"
                    />
                    <span className="font-blue">18</span>
                  </button>
                </div>
              </div>

              <div className="na-exclusive-bottom">
                <span>Μονοκατοɩκία, 850 τ.μ.</span>
                <span>
                  Μεγάλη Μαντίνεɩα, Αβία, ΜεσσηνίαΜεγάλη Μαντίνεɩα, Αβία,
                  ΜεσσηνίαΜεγάλη Μαντίνεɩα, Αβία, Μεσσηνία Με 2 αποθήκες | Με
                  θέσεɩς πάρκɩνγκ
                </span>
                <div className="row-start-center">
                  <img
                    style={{ marginRight: 25 }}
                    src={require("../../assets/business.png")}
                    alt="developing vector"
                  />
                  <span>2ος Όρoφος</span>
                  <img
                    style={{ marginRight: 25 }}
                    src={require("../../assets/couch.png")}
                    alt="developing vector"
                  />
                  <span>6</span>

                  <img
                    style={{ marginRight: 25 }}
                    src={require("../../assets/bathtub.png")}
                    alt="developing vector"
                  />
                  <span>8</span>
                </div>

                <div className="exclusive-card-border" />
                <div className="row-space-between-center width100p">
                  <div className="row-start-center">
                    <span>1.590.000 €</span>
                    <span>5.800 €/τμ</span>
                  </div>
                  <img
                    src={require("../../assets/heart-filled.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
            </div>

            <div className="na-exclusive-card">
              <div className="na-exclusive-img-land">
                <div className="na-icon-row">
                  <div className="vip-icon">
                    <span className="white">VIP</span>
                  </div>
                  <div className="rental-icon">
                    <span className="white">Πώληση</span>
                  </div>
                </div>
                <div className="na-moy-row">
                  <button className="moy-button">ΜΟΥ5674</button>

                  <button className="more-pictures-button">
                    <img
                      style={{ marginRight: 25 }}
                      src={require("../../assets/more-pictures.png")}
                      alt="developing vector"
                    />
                    <span className="font-blue">18</span>
                  </button>
                </div>
              </div>

              <div className="na-exclusive-bottom">
                <span>Οɩκόπεδο, 2500 τ.μ.</span>
                <span>Μεγάλη Μαντίνεɩα, Αβία, Μεσσηνία</span>
                <div className="row-start-center">
                  <img
                    style={{ marginRight: 25 }}
                    src={require("../../assets/land-icon1.png")}
                    alt="developing vector"
                  />
                  <span>Εντός Σχεδίου</span>
                  <img
                    style={{ marginRight: 25 }}
                    src={require("../../assets/land-icon2.png")}
                    alt="developing vector"
                  />
                  <span>Σ.Δ. 0,80</span>
                </div>

                <div className="exclusive-card-border" />
                <div className="row-space-between-center width100p">
                  <div className="row-start-center">
                    <span>1.590.000 €</span>
                    <span>5.800 €/τμ</span>
                  </div>
                  <img
                    src={require("../../assets/heart-filled.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
            </div>
          </div>
          <span>Δες όλα τα ακίνητα που πωλούνται κοντά στην περιοχή</span>
        </div> */}

            {/* <div className="nearby-back">
          <div className="row-start-center">
            <img
              style={{ marginRight: 15 }}
              src={require("../../assets/stats-icon-buy.png")}
              alt="developing vector"
            />
            <span className="font-gothic-b20 red">
              Ακίνητα που πωλούνται κοντά
            </span>
            <img
              style={{ marginRight: 25 }}
              src={require("../../assets/map-icon.png")}
              alt="developing vector"
            />
            <span className="font-gothic-b15 font-blue underline-thin">
              Δες στον χάρτη
            </span>
          </div>

          <div className="na-exclusive-row">
            <div className="na-exclusive-card">
              <div className="na-exclusive-img">
                <div className="na-icon-row">
                  <div className="vip-icon">
                    <span className="white">VIP</span>
                  </div>
                  <div className="rental-icon">
                    <span className="white">Ενοɩκίαση</span>
                  </div>
                </div>
                <div className="na-moy-row">
                  <button className="moy-button">ΜΟΥ5674</button>

                  <button className="more-pictures-button">
                    <img
                      style={{ marginRight: 25 }}
                      src={require("../../assets/more-pictures.png")}
                      alt="developing vector"
                    />
                    <span className="font-blue">18</span>
                  </button>
                </div>
              </div>

              <div className="na-exclusive-bottom">
                <span>Μονοκατοɩκία, 850 τ.μ.</span>
                <span>
                  Μεγάλη Μαντίνεɩα, Αβία, ΜεσσηνίαΜεγάλη Μαντίνεɩα, Αβία,
                  ΜεσσηνίαΜεγάλη Μαντίνεɩα, Αβία, Μεσσηνία Με 2 αποθήκες | Με
                  θέσεɩς πάρκɩνγκ
                </span>
                <div className="row-start-center">
                  <img
                    style={{ marginRight: 25 }}
                    src={require("../../assets/business.png")}
                    alt="developing vector"
                  />
                  <span>2ος Όρoφος</span>
                  <img
                    style={{ marginRight: 25 }}
                    src={require("../../assets/couch.png")}
                    alt="developing vector"
                  />
                  <span>6</span>

                  <img
                    style={{ marginRight: 25 }}
                    src={require("../../assets/bathtub.png")}
                    alt="developing vector"
                  />
                  <span>8</span>
                </div>

                <div className="exclusive-card-border" />
                <div className="row-space-between-center width100p">
                  <div className="row-start-center">
                    <span>1.590.000 €</span>
                    <span>5.800 €/τμ</span>
                  </div>
                  <img
                    src={require("../../assets/heart-filled.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
            </div>

            <div className="na-exclusive-card">
              <div className="na-exclusive-img-land">
                <div className="na-icon-row">
                  <div className="vip-icon">
                    <span className="white">VIP</span>
                  </div>
                  <div className="rental-icon">
                    <span className="white">Πώληση</span>
                  </div>
                </div>
                <div className="na-moy-row">
                  <button className="moy-button">ΜΟΥ5674</button>

                  <button className="more-pictures-button">
                    <img
                      style={{ marginRight: 25 }}
                      src={require("../../assets/more-pictures.png")}
                      alt="developing vector"
                    />
                    <span className="font-blue">18</span>
                  </button>
                </div>
              </div>

              <div className="na-exclusive-bottom">
                <span>Οɩκόπεδο, 2500 τ.μ.</span>
                <span>Μεγάλη Μαντίνεɩα, Αβία, Μεσσηνία</span>
                <div className="row-start-center">
                  <img
                    style={{ marginRight: 25 }}
                    src={require("../../assets/land-icon1.png")}
                    alt="developing vector"
                  />
                  <span>Εντός Σχεδίου</span>
                  <img
                    style={{ marginRight: 25 }}
                    src={require("../../assets/land-icon2.png")}
                    alt="developing vector"
                  />
                  <span>Σ.Δ. 0,80</span>
                </div>

                <div className="exclusive-card-border" />
                <div className="row-space-between-center width100p">
                  <div className="row-start-center">
                    <span>1.590.000 €</span>
                    <span>5.800 €/τμ</span>
                  </div>
                  <img
                    src={require("../../assets/heart-filled.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
            </div>
          </div>
          <span>Δες όλα τα ακίνητα που πωλούνται κοντά στην περιοχή</span>
        </div> */}

            <div className="na-row-icon-pad">
              <Link
                to="https://newdeal.gr/el/buy/residential"
                style={{ textDecoration: "none" }}
              >
                <div className="row-space-between-center pointer">
                  <img
                    style={{ width: 62, marginRight: 10, marginLeft: 20 }}
                    src={require("../../assets/house.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b18 font-blue">
                    Κατοικίες για πώληση
                  </span>
                </div>
              </Link>
              <Link
                to="https://newdeal.gr/el/buy/commercial"
                style={{ textDecoration: "none" }}
              >
                <div className="row-space-between-center pointer">
                  <img
                    style={{ width: 62, marginRight: 10, marginLeft: 20 }}
                    src={require("../../assets/skyscraper.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b18 font-blue">
                    Επαγγελματικές στέγες για πώληση
                  </span>
                </div>
              </Link>
              <Link
                to="https://newdeal.gr/el/buy/land"
                style={{ textDecoration: "none" }}
              >
                <div className="row-space-between-center pointer">
                  <img
                    style={{ width: 62, marginRight: 10, marginLeft: 20 }}
                    src={require("../../assets/blueprint.png")}
                    alt="developing vector"
                  />
                  <span className="font-gothic-b18 font-blue">
                    Γη για πώληση
                  </span>
                </div>
              </Link>
            </div>
          </div>

          <div className="na-frequent-questions">
            <FrequentQuestions />
          </div>
          {/* <div className="na-column-start-center">
        <span className="font-gothic-b60 red">Επικοινώνησε μαζί μας!</span>
        <div className="row-center-center width100p">
          <div className="column-start-center">
            <Link
              to="https://realty.newdeal.gr/contact/"
              className="pointer margin-t20"
            >
              <button className="send-button font-inter-b18 white ">
                Επικοινωνία
              </button>
            </Link>
          </div>
        </div>
      </div> */}
          <div ref={contactRef}>
            <ContactUs />
          </div>

          <AboutEstimates />
        </div>
      ) : (
        <div className="column-start-center width100p" style={{backgroundColor:"#f7f8fd"}}>
          <img
            style={{ height: 300, width: 300, padding: 150 }}
            src={require("../../assets/N white.gif")}
            alt="loading gif"
          />
        </div>
      )}
    </div>
  );
}

export default ResultsPage;
