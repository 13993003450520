import React, { useRef, useEffect,useState } from "react";
import "./LocationInput.css";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { IconButton } from "@mui/material";
import Close from '@mui/icons-material/Close';

const center = {
  lat: 37.9878642,

  lng: 23.7349754,
};

const PlaceComponent = (props) => {
  const [place, setPlace] = React.useState();
  const [searchArea, setSearchArea] = React.useState(false);
  const [searchProperty, setSeatchProperty] = React.useState(false);
  const [inputValue, setInputValue] = useState(''); 
  const inputRef = useRef();

  useEffect(() => {
    if (props.searchArea) {
      setSearchArea(true);
    }
    if (props.searchProperty) {
      setSeatchProperty(true);
    }
  }, [props]);

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    console.log(place);

    if (place) {
      console.log(place.formatted_address);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
    }
    setPlace(place);
    setInputValue(place.formatted_address);
  };

  const handleClick = () => {
    props.calculate(place);
  };

  return (
    <div className="width100p">
      <StandaloneSearchBox
        onLoad={(ref) => {
          inputRef.current = ref;
          ref.setBounds(new window.google.maps.LatLngBounds(center));
        }}
        onPlacesChanged={handlePlaceChanged}
      >
        <div className="search-row">
          <input
            className="search-address font-inter18"
            value={inputValue} 
        onChange={(e) => setInputValue(e.target.value)}
            placeholder={
              searchProperty
                ? "Πληκτρολόγησε μια διεύθυνση..."
                : "Πληκτρολόγησε μια περιοχή..."
            }
            // style={{width:"100%", boxSizing:"border-box"}}
          />
          <div className="row-start-center">
           <IconButton  onClick={()=>setInputValue("")}>
  <Close />
</IconButton>
          {window.innerWidth >= 700 ? (
            <button
              className="button-calculate font-gothic-b16"
              onClick={handleClick}
            >
              Υπολογισμός{" "}
              <SearchIcon
                fill="currentColor"
                stroke="currentColor"
                className="search-icon"
                style={{ textColor: "white" }}
              />
            </button>
          ) : (
            <button
              className="mob-button-calculate font-gothic-b16"
              onClick={handleClick}
            >
              <SearchIcon
                fill="currentColor"
                stroke="currentColor"
                className="search-icon"
                style={{ textColor: "white" }}
              />
            </button>
          )}
          </div>
        </div>
      </StandaloneSearchBox>
    </div>
  );
};

export default PlaceComponent;
