import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      {window.innerWidth >= 1200 ? (
        <div className="footer">
          <div className="footer-content">
            <div className="logo-social-container">
              <div className="logo">
                <img
                  src={require("../../assets/logo-newdeal.png")}
                  alt="developing vector"
                />
                <img
                  src={require("../../assets/logo-footer.png")}
                  alt="developing vector"
                />
                <div className="margin-t20">
                  <img
                    style={{ width: 19, marginRight: 10 }}
                    src={require("../../assets/location-footer.png")}
                    alt="developing vector"
                  />

                  <span className="white font-inter-m14">
                    Κανάρɩ 24, Κολωνάκɩ 10 674
                  </span>
                </div>
                <div className="margin-t20">
                  <img
                    style={{ width: 19, marginRight: 10 }}
                    src={require("../../assets/phone-footer.png")}
                    alt="developing vector"
                  />
                  <span className="white font-inter-m14">
                    (+30) 210 33 90 500
                  </span>
                </div>
                <div className="margin-t20">
                  <img
                    style={{ width: 19, marginRight: 10 }}
                    src={require("../../assets/email-footer.png")}
                    alt="developing vector"
                  />
                  <span className="white font-inter-m14">info@newdeal.gr</span>
                </div>
              </div>
              <div className="social-links margin-t40">
                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/fb-icon.png")}
                  alt="developing vector"
                />
                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/instagram-icon.png")}
                  alt="developing vector"
                />

                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/linkedin-icon.png")}
                  alt="developing vector"
                />

                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/twitter-icon.png")}
                  alt="developing vector"
                />

                <img
                  style={{ width: 40, cursor: "pointer" }}
                  src={require("../../assets/youtube-icon.png")}
                  alt="developing vector"
                />
              </div>
            </div>
            <div className="footer-column">
              <span className="footer-link margin-b20 font-inter-b14">
                newdeal network
              </span>

              <Link to="https://realty.newdeal.gr/news/gnwrise-ton-tropo-zvhw-maw/">
                <span className="footer-link margin-b20 font-inter-m14">
                  Ποιοι Είμαστε
                </span>
              </Link>
              <Link to="https://rean.gr/συχνές-ερωτήσεις/">
                <span className="footer-link margin-b20 font-inter-m14">
                  Συχνές Ερωτήσεις
                </span>
              </Link>
              <Link to="https://rean.gr/επικοινωνία/">
                <span className="footer-link margin-b20 font-inter-m14">
                  Επικοινωνία
                </span>
              </Link>
              <Link to="https://realty.newdeal.gr/kariera/">
                <span className="footer-link margin-b20 font-inter-m14">
                  Θέσεις Εργασίας
                </span>
              </Link>
            </div>

            <div className="footer-column">
              <span className="footer-link margin-b20 font-inter-b14">
                Ακίνητα
              </span>

              <Link to="https://newdeal.gr/el/buy/residential">
                <span className="footer-link margin-b20 font-inter-m14">
                  Αγορά σπιτιού
                </span>
              </Link>

              <Link to="https://newdeal.gr/el/rent/residential">
                <span className="footer-link margin-b20 font-inter-m14">
                  Ενοικίαση κατοικίας
                </span>
              </Link>

              <Link to="https://newdeal.gr/el/buy/commercial">
                <span className="footer-link margin-b20 font-inter-m14">
                  Πωλήσεις Επαγγελματικών
                </span>
              </Link>

              <Link to="https://newdeal.gr/el/rent/commercial">
                <span className="footer-link margin-b20 font-inter-m14">
                  Ενοɩκɩάσεɩς Επαγγελματɩκών
                </span>
              </Link>

              <Link to="https://newdeal.gr/el/buy/land">
                <span className="footer-link margin-b20 font-inter-m14">
                  Πωλήσεις Γης
                </span>
              </Link>

              <Link to="https://newdeal.gr/el/buy/residential?condition=new-built">
                <span className="footer-link margin-b20 font-inter-m14">
                  Νέες Κατασκευές
                </span>
              </Link>

              <Link to="">
                <span className="footer-link margin-b20 font-inter-m14">
                  Ακίνητα στην Κύπρο
                </span>
              </Link>

              <Link to="">
                <span className="footer-link margin-b20 font-inter-m14">
                  Φοιτητικά
                </span>
              </Link>
            </div>

            <div className="footer-column">
              <span className="footer-link margin-b20 font-inter-b14">
                Για Επαγγελματίες
              </span>

              <Link to="https://realty.newdeal.gr/kariera/">
                <span className="footer-link margin-b20 font-inter-m14">
                  Κάνε καριέρα
                </span>
              </Link>

              <Link to="https://realty.newdeal.gr/virtualoffice/marketing/">
                <span className="footer-link margin-b20 font-inter-m14">
                  Διαφήμιση/Προβολή
                </span>
              </Link>

              <Link to="">
                <span className="footer-link margin-b20 font-inter-m14">
                  Networking
                </span>
              </Link>

              <Link to="https://realty.newdeal.gr/virtualoffice/systaseis">
                <span className="footer-link margin-b20 font-inter-m14">
                  Εγγραφη Επαγγελματία
                </span>
              </Link>

              <Link to="https://realty.newdeal.gr/franchise">
                <span className="footer-link margin-b20 font-inter-m14">
                  Εκπαίδευση
                </span>
              </Link>

              <Link to="https://rean.gr">
                <span className="footer-link margin-b20 font-inter-m14">
                  Συνεργασία με ΕΚΠΑ
                </span>
              </Link>
            </div>

            <div className="footer-column">
              <span className="footer-link margin-b20 font-inter-b14">
                Για Ιδιώτες
              </span>

              <Link to="https://realty.newdeal.gr/news/one-stop-shop/">
                <span className="footer-link margin-b20 font-inter-m14">
                  Βρες Μεσίτη
                </span>
              </Link>

              <Link to="">
                <span className="footer-link margin-b20 font-inter-m14">
                  καταχώρηση ακινήτου
                </span>
              </Link>

              <Link to="">
                <span className="footer-link margin-b20 font-inter-m14">
                  Τιμές Ακινήτου
                </span>
              </Link>

              <Link to="https://realty.newdeal.gr">
                <span className="footer-link margin-b20 font-inter-m14">
                  Blog
                </span>
              </Link>

              <Link to="https://newdeal.gr/el/#">
                <span className="footer-link margin-b20 font-inter-m14">
                  Εγγραφή/Είσοδος
                </span>
              </Link>

              <Link to="">
                <span className="footer-link margin-b20 font-inter-m14">
                  Ανάθεση Ακινήτο
                </span>
              </Link>

              <Link to="">
                <span className="footer-link margin-b20 font-inter-m14">
                  Ζήτηση Ακινήτου
                </span>
              </Link>
            </div>
          </div>
          <hr />
          <div className="copyright  font-inter-m14 footer-font-color">
            <span>
              Copyright 2023 newdeal real estate group, All rights reserved
            </span>
            <span>Όροι Χρήσης</span>&nbsp;|&nbsp;<span>Προσωπικά Δεδομένα</span>
            &nbsp;|&nbsp;<span>Πολιτική Cookies</span>
            <span></span>
          </div>
        </div>
      ) : window.innerWidth >= 500 ? (
        <div className="footer">
          <div className="footer-content">
            <div className="logo-social-container">
              <div className="logo">
                <img
                  src={require("../../assets/logo-newdeal.png")}
                  alt="developing vector"
                />
                <img
                  src={require("../../assets/logo-footer.png")}
                  alt="developing vector"
                />
                <div className="margin-t20">
                  <img
                    style={{ width: 19, marginRight: 10 }}
                    src={require("../../assets/location-footer.png")}
                    alt="developing vector"
                  />

                  <span className="white font-inter-m14">
                    Κανάρɩ 24, Κολωνάκɩ 10 674
                  </span>
                </div>
                <div className="margin-t20">
                  <img
                    style={{ width: 19, marginRight: 10 }}
                    src={require("../../assets/phone-footer.png")}
                    alt="developing vector"
                  />
                  <span className="white font-inter-m14">
                    (+30) 210 33 90 500
                  </span>
                </div>
                <div className="margin-t20">
                  <img
                    style={{ width: 19, marginRight: 10 }}
                    src={require("../../assets/email-footer.png")}
                    alt="developing vector"
                  />
                  <span className="white font-inter-m14">info@newdeal.gr</span>
                </div>
              </div>
              <div className="social-links margin-t40">
                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/fb-icon.png")}
                  alt="developing vector"
                />
                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/instagram-icon.png")}
                  alt="developing vector"
                />

                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/linkedin-icon.png")}
                  alt="developing vector"
                />

                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/twitter-icon.png")}
                  alt="developing vector"
                />

                <img
                  style={{ width: 40, cursor: "pointer" }}
                  src={require("../../assets/youtube-icon.png")}
                  alt="developing vector"
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="mob-copyright font-inter-m14 footer-font-color">
            <div className="copyright font-inter-m14 footer-font-color">
              <span>Όροι Χρήσης</span>
              &nbsp;|&nbsp;
              <span>Προσωπικά Δεδομένα</span>
              &nbsp;|&nbsp;
              <span>Πολιτική Cookies</span>
              <span></span>
            </div>

            <span>
              Copyright 2023 newdeal real estate group, All rights reserved
            </span>
          </div>
        </div>
      ) : (
        <div className="mob-mob-footer">
          <div className="footer-content">
            <div className="logo-social-container">
              <div className="logo">
                <img
                  src={require("../../assets/logo-newdeal.png")}
                  alt="developing vector"
                />
                <img
                  src={require("../../assets/logo-footer.png")}
                  alt="developing vector"
                />
                <div className="margin-t20">
                  <img
                    style={{ width: 19, marginRight: 10 }}
                    src={require("../../assets/location-footer.png")}
                    alt="developing vector"
                  />

                  <span className="white font-inter-m14">
                    Κανάρɩ 24, Κολωνάκɩ 10 674
                  </span>
                </div>
                <div className="margin-t20">
                  <img
                    style={{ width: 19, marginRight: 10 }}
                    src={require("../../assets/phone-footer.png")}
                    alt="developing vector"
                  />
                  <span className="white font-inter-m14">
                    (+30) 210 33 90 500
                  </span>
                </div>
                <div className="margin-t20">
                  <img
                    style={{ width: 19, marginRight: 10 }}
                    src={require("../../assets/email-footer.png")}
                    alt="developing vector"
                  />
                  <span className="white font-inter-m14">info@newdeal.gr</span>
                </div>
              </div>
              <div className="social-links margin-t40">
                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/fb-icon.png")}
                  alt="developing vector"
                />
                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/instagram-icon.png")}
                  alt="developing vector"
                />

                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/linkedin-icon.png")}
                  alt="developing vector"
                />

                <img
                  style={{ width: 40, cursor: "pointer", marginRight: 10 }}
                  src={require("../../assets/twitter-icon.png")}
                  alt="developing vector"
                />

                <img
                  style={{ width: 40, cursor: "pointer" }}
                  src={require("../../assets/youtube-icon.png")}
                  alt="developing vector"
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="mob-mob-copyright font-inter-m14 footer-font-color">
            <span>Όροι Χρήσης</span>

            <span>Προσωπικά Δεδομένα</span>

            <span>Πολιτική Cookies</span>

            <span>Copyright 2023 newdeal real estate group</span>
            <span>All rights reserved</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
