import React, { useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import createLatLngWrapper from "../../utils/locationWrapper";

const MapComponent = (props) => {
  const markerIn = {
    position: {
      lat: 28.625485,
      lng: 79.821091,
    },
    label: { color: "white", text: "P1" },
    draggable: true,
  };
  const [activeInfoWindow, setActiveInfoWindow] = useState("");
  const [marker, setMarker] = useState(null);
  const [containerStyle, setContainerStyle] = useState(null);

  const center = {
    lat: 28.626137,
    lng: 79.821603,
  };
  React.useEffect(() => {
    if (props.marker) {
      const lat =
        typeof props.marker.geometry.location.lat === "function"
          ? props.marker.geometry.location.lat()
          : props.marker.geometry.location.lat;
      const lng =
        typeof props.marker.geometry.location.lng === "function"
          ? props.marker.geometry.location.lng()
          : props.marker.geometry.location.lng;

      // var marker_lat = props.marker.geometry.location.lat();
      // var marker_lng = props.marker.geometry.location.lng();

      const geometryLocation = new window.google.maps.LatLng(lat, lng);
      var marker_lat = geometryLocation.lat();
      var marker_lng = geometryLocation.lng();

      let tmp = {
        position: {
          lat: marker_lat,
          lng: marker_lng,
        },
        positionLabel: {
          lat: marker_lat,
          lng: marker_lng,
        },
        label: { color: "white", text: props.marker.formatted_address },
        draggable: false,
      };

      let style = {
        width: "100%",
        height: props.height,
      };
      setContainerStyle(style);
      setMarker(tmp);
    }
  }, [props]);

  return (
    <div className="rn-map-component">
      {marker && (
        <GoogleMap
          draggable={false}
          mapContainerStyle={containerStyle}
          center={marker.position}
          zoom={15}
        >
          <Marker position={marker.position} draggable={false}></Marker>
        </GoogleMap>
      )}
    </div>
  );
};

export default MapComponent;
