import React from "react";
import "./AboutEstimates.css";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useState } from "react";

function AboutEstimates() {
  const [extendQuest, setExtendQuest] = useState(true);
  

  return (
    <div className="ae-border-end">
    <div
      className="estimates-row pointer"
      onClick={() => setExtendQuest (!extendQuest)}
    >
      <span className="font-inter18 font-blue">
        Σχετικά με τις εκτιμήσεις της newdeal
      </span>
      <KeyboardArrowDownRoundedIcon 
      style={{rotate: extendQuest ? "none" : "180deg"}}/>
      </div>
      <span
        className="font-inter16 dark-grey margin-t20"
        style={{ display: extendQuest ? "none" : "flex" }}
      >
        Σχετικά με τις εκτιμήσεις της newdeal. Το κείμενο είναι αυτό: Η
        newdeal real estate group δεν αποτελεί πιστοποιημένη εταιρεία
        εκτίμησης ακινήτων. Ως εκ τούτου, τα συμπεράσματα/εκτιμήσεις με τη
        χρήση προσεγγιστικών μεθόδων, που εξάγονται από τα καταχωρημένα από
        εσάς στην παρούσα ιστοσελίδα στοιχεία και δεδομένα είναι μόνο για
        πληροφοριακούς σκοπούς και σε καμία περίπτωση δεν αποτελούν ούτε
        μπορούν να εκληφθούν, ευθέως ή εμμέσως, ως δεσμευτική
        πρόταση/συμβουλή, επενδυτική συμβουλή ή παρότρυνση για πώληση,
        εκμίσθωση ή με οποιονδήποτε τρόπο εκμετάλλευση του εκάστοτε
        ακινήτου. Κατά συνέπεια, η newdeal real estate group και οι
        συνεργάτες της, ουδεμία ευθύνη φέρουν έναντι υμών για τυχόν
        αποφάσεις ή ενέργειες στις οποίες προβείτε βασιζόμενοι στα εν λόγω
        συμπεράσματα/εκτιμήσεις της newdeal real estate group με τη χρήση
        προσεγγιστικών μεθόδων, ούτε για τις απορρέουσες ζημίες ή απώλειες
        που τυχόν υποστείτε.Η newdeal real estate group ουδεμία ευθύνη φέρει
        για την ορθότητα/ακρίβεια της περιγραφής των ακινήτων, για τυχόν
        ανακριβή εκτίμηση της αξίας τους ή για οποιαδήποτε άμεση, έμμεση,
        θετική ή αποθετική ζημία οποιουδήποτε είδους τυχόν προκληθεί στο
        χρήστη της ιστοσελίδας εξ αφορμής της χρήσης αυτής. Η newdeal real
        estate group δεν υποχρεούται να επαληθεύει την ταυτότητα των
        προσώπων που χρησιμοποιούν τις υπηρεσίες/εφαρμογές της, ούτε να
        παρακολουθεί τη χρήση των υπηρεσιών/εφαρμογών της ενώ δεν παρέχει
        εγγυήσεις σχετικά με τις υπηρεσίες/εφαρμογές της και αποποιείται
        κάθε σχετικής ευθύνης για πιθανά σφάλματα ή παραλείψεις κάθε τύπου.
        *Σε περίπτωση που ενδιαφέρεστε για αγορά ή ενοικίαση ακινήτου, ένας
        Σύμβουλος της newdeal θα επικοινωνήσει μαζί σας μόνο όταν βρούμε
        κάποιο ακίνητο που να πληροί τα κριτήριά σας.
      </span>
 
  </div>
  );
}

export default AboutEstimates;
