import React from "react";
import "./PropertyRatingBar.css";

const calculateWidthAndColor = (count) => {
  const maxWidth = 200; // Maximum driving distance in minutes
  const percentage = (count / maxWidth) * 100;
  let colorClass = "";

  if (count >= 100) {
    colorClass = "rating-green";
  } else if (count < 100 && count >= 50) {
    colorClass = "rating-orange";
  } else if (count < 50) {
    colorClass = "rating-red";
  }

  return { width: `${percentage}%`, colorClass };
};

const PropertyRatingBar = ({ count }) => {
  const { width, colorClass } = calculateWidthAndColor(count);

  return (
    <div className="rating">
      <div className={`rating-color ${colorClass}`} style={{ width }}></div>
    </div>
  );
};

export default PropertyRatingBar;
