import { createSlice } from "@reduxjs/toolkit";
import createLatLngWrapper from "../../utils/locationWrapper";

const initialState = {
  marker: null,
  antikeimeniki: null,
  request: null,
  zonis: null,
};

const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {
    setRequest(state, action) {
      state.request = action.payload;
    },
    setAntikeimeniki(state, action) {
      state.antikeimeniki = action.payload;
    },
    setZonis(state, action) {
      state.zonis = action.payload;
    },

    setMarker(state, action) {
      const marker = action.payload;

      let lat, lng;

      if (typeof marker.geometry.location.lng === "function") {
        lat = marker.geometry.location.lat();
        lng = marker.geometry.location.lng();
      } else {
        lat = marker.geometry.location.lat;
        lng = marker.geometry.location.lng;
      }

      const markerData = {
        lat,
        lng,
        locationWrapper: createLatLngWrapper(lat, lng), // Create the wrapper object
        // Other properties of your marker
      };
      console.log(markerData, "MARKERDATA");

      const newMarker = {
        formatted_address: marker.formatted_address,
        address_components: marker.address_components,
        geometry: { location: markerData },
      };

      state.marker = newMarker;
    },
  },
});

export default priceSlice;
